import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import { DELETE_SUCCESS } from "../../../utilities/labelConfigs";
import { checkPermission } from "../../../utilities/commonUtilities";
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";
import { permission_key_values_accountsetup, super_admin_privileges } from "../../../utilities/permissions";

import LoadingContext from "../../../container/loadingContext";
import { ListCustomFeeTableData } from "./listFeeTableData";


import Notify from "../../commons/notify";
import Table from "../../commons/Table/Table";
import CommonButton from "../../commons/Buttons";
import Pagination from "../../commons/pagination";
import TextInput from "../../commons/input/input";
import CalendarInput from "../../commons/input/CalendarInput";

import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";

import service from "./service";
import FeeFileEditForm from "./FeeFileEditForm";

const CustomFeeSchedule = () => {
  const fileRef = useRef();

  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  // ----state---------------
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

  const [fileName, setFileName] = useState();
  const [effectiveDate, setEffectiveDate] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [feeList, setFeeList] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingFeeSchedule, setEditingFeeSchedule] = useState();

  const [deleteFileName, setDeleteFileName] = useState('');
  const [deleteFileId, setDeleteFileId] = useState('');
  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
  useEffect(() => {
    
    getCustomSchedules(DEFAULT_PAGING_SIZE, activePage);
  }, []);
 
  function onDeleteHide() {
    setDeleteFileName('');
    setDeleteFileId('');
    setShowDeleteModalWindow(false);
  }
  function onDeleteFeeFile(fileID) {
    feeList.map((item) => {
      if (item.id === parseInt(fileID)) {
        setDeleteFileName(item.fee_schedule_name);
        setDeleteFileId(item.id);
        setShowDeleteModalWindow(true);
      }
    });
  }


  function onDeleteAction() {
    setShowLoadingBar(true);
    
    const data = service.deleteCustomSchedules(deleteFileId);
    data.then(() => {
      setShowLoadingBar(false);
      setDeleteFileName('');
      setDeleteFileId('');
      setShowDeleteModalWindow(false);
      showNotifyWindow('show', 'success', DELETE_SUCCESS);
   
    
      if (totalPage <= activePage) {
        
        getCustomSchedules(DEFAULT_PAGING_SIZE, 'last');
        window.location.reload();
      } else {
        getCustomSchedules(DEFAULT_PAGING_SIZE, activePage);
        window.location.reload();
       
      }
      
    });
  }
  function resetForm() {
    setSelectedFile("");
  }

  const getCustomSchedules = (pageSize, page) => {
    setShowLoadingBar(true);
    service
      .getFeeFiles(practicePK, pageSize, page)
      .then((response) => {
   
        setShowLoadingBar(false);
        if (response.status === 200) {
          setShowLoadingBar(false);
          if (response.data.results !== undefined) {
            let dataTotalPage = Math.ceil(
              response.data.count / response.data.page_size
            );
            setTotalPage(dataTotalPage);
            
            if (dataTotalPage < activePage && dataTotalPage >= 1) {
              setActivePage(dataTotalPage);
            }
          }
          let anArray = [];
          let rowArray = [];
          let newData = Array(response.data.results.length).fill(
            JSON.parse(JSON.stringify(ListCustomFeeTableData.tableBodyData[0]))
          );

          newData.map((row, rowIndex) => {
            row.map((col) => {
              let colObject = {};

              colObject = {
                ...col,
                value: response.data.results[rowIndex][col.name],
                id: response.data.results[rowIndex].id,
              };

              anArray.push(colObject);
            });
            rowArray.push(anArray);
            anArray = [];
          });
          if (rowArray.length > 0) {
            ListCustomFeeTableData.tableBodyData = rowArray;
          }
        
          setFeeList(response.data.results);
          
        } else {
          throw Error("Something went wrong");
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", err.message);
      });
  };

  const formatDate = useCallback((selectdDate) => {
    // YYYY-MM-DD
    var d = new Date(selectdDate),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  function createFormData(data) {
    const formData = new FormData();
    // appending key and key value to formData
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }


  useEffect(() => {
    if (
      selectedFile != undefined &&
      fileName != undefined &&
      effectiveDate != undefined
    ) {
      const data = {
        "effective_date": formatDate(effectiveDate),
        "file_name": fileName,
        "file": selectedFile,
        "practice_pk": practicePK
      }
      const formData = createFormData(data);

      setShowLoadingBar(true);
      service
        .postCSVFile(formData)
        .then((response) => {
          setShowLoadingBar(false);
         
          if (response.status === 200) {
            showNotifyWindow(
              "show",
              "success",
              "Custom Fee Schedule File Uploaded"
            );
            setEffectiveDate();
            setFileName('');
           getCustomSchedules(DEFAULT_PAGING_SIZE, activePage);
            
            
          }
          else if (response.status === 400 && response.data.record_already_exists !== undefined) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
            setEffectiveDate();
            setFileName('');
          }
          else {
            showNotifyWindow("show", "error", response.data.msg);
            
            setEffectiveDate();
            setFileName('');
          }
        })
        .catch((err) => {
          
          setShowLoadingBar(false);
          showNotifyWindow("show", "error", err.message);
          setEffectiveDate();
          setFileName('');

        });
    }
  }, [selectedFile]);

  // -----functions-----------
  const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }, []);
  function dropDownFunction(id, name) {
    const permissions = checkPermission(permission_key_values_accountsetup.account_setup_fee_schedule_management_modify, super_admin_privileges.super_admin_full_privilege);
    if (name.toLowerCase() === "edit" && permissions) {
      onEditCustomFeeSchedule(id);
    }
    else if (name.toLowerCase() === 'delete' && permissions) {
      onDeleteFeeFile(id);
    } else {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
    }
  }

  const onEditCustomFeeSchedule = (id) => {
    setEditingFeeSchedule(id);
    setShowEditModal(true);
  };

  const onPagePrevious = () => {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getCustomSchedules(DEFAULT_PAGING_SIZE, previousPage);
  };

  const onPageUp = (e) => {
    let page = Number(e.target.id);
    setActivePage(page);
    getCustomSchedules(DEFAULT_PAGING_SIZE, page);
  };

  const onPageNext = () => {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getCustomSchedules(DEFAULT_PAGING_SIZE, nextPage);
  };

  const onHandleEffectiveDate = (selected) => setEffectiveDate(selected);

  const onHandleCsvImport = (e) => {
    e.preventDefault();

    if (
      fileName === undefined ||
      effectiveDate === undefined
    ) {
      showNotifyWindow("show", "error", i18n.t("customerSetup.feeSchedule.customSchedule.EmptyFields"));
    } else 
    fileRef.current.click();
  };

 
  const onHandleCSVChange = (e) => {
    if (e.target.files[0] !== undefined) {
      setSelectedFile(e.target.files[0]);
    }
    e.target.value = null;
  };

  const onHandleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      if (name === "fileName") setFileName(value);
    }
  };

  // ------------------------
  return (
    <>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="box-content">
        <input
          ref={fileRef}
          name="claimfile"
          type="file"
          accept=".csv"
          id="claimfile"
          onChange={onHandleCSVChange}
          style={{ display: "none" }}
        />
        

        <div className="row">
          <div className="col-2">
            <TextInput
              required="required"
              type="text"
              name="fileName"
              label="File Name"
              onValueChange={onHandleChange}
              value={fileName}
            />
          </div>
          <div className="col-2">
            <CalendarInput
              required="required"
              label="Effective Date"
              name="effectiveDate"
              selected={effectiveDate}
              minDate={effectiveDate}
              onValueChange={(selected) => onHandleEffectiveDate(selected)}
            />
          </div>
          { checkPermission(permission_key_values_accountsetup.account_setup_fee_schedule_management_add) && 
          <div className="col-2 margin-top-25">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  id="download-tooltip"
                  style={{
                    opacity: 0.4,
                  }}
                >
                  {i18n.t("commons.importCSV")}
                </Tooltip>
              }
            >
              <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)} />
            </OverlayTrigger>
          </div> }

          {/* <div className="padding-right15 div-float-right mr-3 margin-export-icon padding-bottom10">
            { checkPermission(permission_key_values_accountsetup.account_setup_fee_schedule_management_add) &&
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    id="download-tooltip"
                    style={{
                      opacity: 0.4,
                    }}
                  >
                    {i18n.t("commons.importCSV")}
                  </Tooltip>
                }
              >
                <button
                  className="header-btn border-none bg-white p-0 mt-3"
                  type="button"
                  data-toggle="dropdown"
                  onClick={(e) => onHandleCsvImport(e)}
                >
                  <i className="las la-file-download navBarIcons"></i>
                </button> 
              </OverlayTrigger>
            }
          </div> */}
        </div>

        <div className="table-responsive">
          <Table
            tableObject={ListCustomFeeTableData}
            dropDownFunction={dropDownFunction}
          />
        </div>
        <div className='padding-top10'></div>
        <Pagination
          totalPage={totalPage}
          activePage={activePage}
          startIndex={startIndex}
          endIndex={endIndex}
          onPagePrevious={onPagePrevious}
          onPageUp={onPageUp}
          onPageNext={onPageNext}
        />
        <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
          {i18n.t("customerSetup.feeSchedule.customSchedule.deletConfirmation") + " '" + deleteFileName + "'?"}
        </CustomizedSmallDialogs>
        <CustomizedSmallDialogs
          showModal={showEditModal}
          header={"Edit Fee Schedule"}
          fullWidth={true}
          setShowModalWindow={setShowEditModal}
        >
          <FeeFileEditForm
            fileId={editingFeeSchedule}
            showNotification={showNotifyWindow}
          />
        </CustomizedSmallDialogs>
      </div>
    </>
  );
};

export default CustomFeeSchedule;
