
import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../Services/service";
import { getStorage } from "../../../utilities/browserStorage";
import { claimFormWaringMessage, createSaveData, setWarningFromServer } from "./claimDataGenerator";
import { saveClaimIDWaringPopUP, setFormWaringData, setFormWarningMessages, showModalWindow, showNotifications } from "./ClaimModuleSlice";
import { APIErrorHandler, claimValidation } from "./claimAlertValidations";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";
import { ADD_SUCCESS, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import { fetchIdentifiers } from "./DropDownOptionSlice";

/**
 * @description Get the list of tabs opened by the session user and if not any opened then direct to last10 tab by default
 * @param None
 */
export const getUserOpenedClaimModuleTabs = createAsyncThunk(
    'claims/getUserOpenedClaimModuleTabs',
    async (payload, { rejectWithValue }) => {
        try {
            return await service.GetSelectedTabs("claims", getStorage("practice"));
        } catch (error) {
            return rejectWithValue("Error while fetching opened parent claim opened tabs.");
        }
    }
);

/**
* @description get last 10 table data 
 * @param query
 */
export const getlast10TableData = createAsyncThunk(
    'claims/last10TableData',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await service.GetListPatientClaims(payload);
            return response.data
        } catch (error) {
            return rejectWithValue("Error while fetching last10 claim list.");
        }
    }
);

/**
 * @description get last 10 table data 
 * @param query
 */
export const getSearchClaimsTableData = createAsyncThunk(
    'claims/searchClaimsTableData',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await service.GetListPatientClaims(payload.query);
            if (response?.data?.error_message)
                return rejectWithValue({ error_message: response?.data?.error_message ?? "An un-expected error occurred while fetching the data." });
            return { data: response.data, isSearch: payload.search }
        } catch (error) {
            return rejectWithValue("Error while fetching claim list.");
        }
    }
)

/**
 * @description Function to open new claim parent tab like last10, search, claimPk
 * @param String enum pk= last10 || search || claimPk
 * @param String claimId
 * @param String enum action= "add" || "remove"
 */
export const addRemoveFromSelectedTab = createAsyncThunk(
    'claims/addRemoveFromSelectedTab',
    async (payload, { rejectWithValue }) => {
        try {
            let request = {
                "pk": payload.item.pk,
                "claim_id": payload.item.claimId ? payload.item.claimId : "",
                "type": payload.item.type,
                "action": payload.item.action,
                "practice_pk": getStorage('practice'),
            }

            if (payload.item.isClosed) {
                request = {
                    ...request,
                    isClosed:true
                }
            }

            if (payload.item.active_tab) {
                request = {
                    ...request,
                    active_tab: payload.item.active_tab
                } 
            }
            const response = await service.AddRemoveSelectedTab(request);
            if (response?.data?.status === "success") {
                return { payload: request, tab: payload.tab }
            }
        } catch (error) {
            return rejectWithValue("Error while requesting to  open parent claim tab.");
        }
    }
)

/**
 * @description Function to open new claim parent tab like last10, search, claimPk
 * @param String enum pk= last10 || search || claimPk
 * @param String claimId
 * @param String enum action= "add" || "remove"
 */
export const addActiveSubTab = createAsyncThunk(
    'claims/addActiveSubTab',
    async (payload, { rejectWithValue }) => {
        try {
            let request = {
                "pk": payload.payload.pk,
                "claim_id": payload.payload.claimId ? payload.payload.claimId : "",
                "type": payload.payload.type,
                "action": payload.payload.action,
                "practice_pk": getStorage('practice'),
                "active_tab": payload.payload.active_tab
            }
            const response = await service.AddRemoveSelectedTab(request);
            if (response?.data?.status === "success") {
                return { payload: request }
            }
        } catch (error) {
            return rejectWithValue("Error while requesting to  open parent claim tab.");
        }
    }
)

/**
 * @description api is used to call all details of existing claim such as service line, billing info,additional info
 * @params String claimPK 
 */
export const getEditDetails = createAsyncThunk(
    'claims/getEditDetails',
    async (payload, thunkAPI) => {
        try {
            let claimInfo = await service.GetGeneralClaim(payload).then(response => {
                if (response.data) {
                    return response.data;
                }
                else{
                    if (response.error_data) {
                        throw new Error(response.error_data.error_message)
                    }
                }
            });
            let billingInfoPk = claimInfo?.pks?.billing_info_pk;
            let additionalClaimInfoPk = claimInfo?.pks?.additional_claim_info_pk;
            let serviceLinesPk = claimInfo?.pks?.diagnosises_pk

            let [billingInfo, procedureInfo, additionalInfo, accidentList,responsibilityType] = await Promise.all([
                service.GetBillingInfoClaim(billingInfoPk).then(response => {
                    if (response.data) {
                        return response.data;
                    }else {
                        throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                    }
                }),
                service.GetServiceLineClaim(serviceLinesPk).then(response => {
                    if (response.data) {
                        return response.data;
                    }else {
                        throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                    }
                }),
                service.GetAdditionalClaim(additionalClaimInfoPk).then(response => {
                    if (response.data) {
                        return response.data;
                    }else {
                        throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                    }
                }),
                service.ListAccidentTypes(DEFAULT_PAGING_SIZE, 0, payload ? payload : "").then(response => {
                    if (response.data) {
                        return response.data;
                    }else {
                        throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                     }
                }),
                service.GetResponsibilityType(claimInfo?.patient ? claimInfo?.patient : "").then(response=>{
                    if(response.data){
                        return response.data.responsibility_list
                    }else {
                        throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                    }
                })
            ]);

            /**
             * calling identifier data if authorization_referral is present
             */
            if(claimInfo?.authorization_referral){
                let patient = claimInfo?.patient;
                let provider =
                claimInfo?.authorization_referral === 1
                        ? billingInfo?.billing_provider
                        : billingInfo?.referring_provider;
                setTimeout(() => {
                    thunkAPI.dispatch(
                        fetchIdentifiers({
                            patient: patient,
                            auth: claimInfo?.authorization_referral,
                            provider: provider,
                        })
                    );
                }, 250);
            }
            return {
                claimInfo,
                billingInfo,
                procedureInfo,
                additionalInfo,
                accidentList,
                responsibilityType,
                payload
            };
        } catch (error) {
            /**
             * if any api get an error response it will close the claim tab automatically
             */
            const state = thunkAPI.getState();
            let { claimManagement } = state;
            let openedCliamDetails = claimManagement?.claimParentTabs?.find((item) => item.pk == payload);
            if (openedCliamDetails) {
                let item = {
                    pk: openedCliamDetails?.pk, claimId: openedCliamDetails?.claim_id,
                    type: 'claims', action: 'remove'
                }
                thunkAPI.dispatch(addRemoveFromSelectedTab({ item: item, tab: 'editClaims' + openedCliamDetails?.pk }));
            }
            return thunkAPI.rejectWithValue('Error fetching claim information:' + error.message);
        }
    }
)



//getting patient details on patient pk of selected patients
export const getPatientDetails = createAsyncThunk(
    "claim-info/patient-pk-details", async (payload, thunkAPI) => {
        try {
        const promises = [
            await service.GetPatientDetails(payload.patient_pk).then(response => {
                if (response.data) {
                    return response.data
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            }),
            await service.GetPatientDXCPTDetails(payload.patient_pk).then(response => {
                if (response.data) {
                    return response.data
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            }),
            await service.GetPatientAccidentInfo(payload.patient_pk).then(response => {
                if (response.data) {
                    return response.data
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            }),
            await service.GetPracticeBasicData(getStorage('practice')).then(response => {
                if (response.data) {
                    return response.data
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            }),
            await service.ListAccidentTypes(DEFAULT_PAGING_SIZE, 0, payload.claim_pk ? payload.claim_pk : "").then(response => {
                if (response.data) {
                    return response.data
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            }),

            /**
             * getting responsibility type for the patient 
            */

            await service.GetResponsibilityType(payload.patient_pk ? payload.patient_pk : "").then(response=>{
                if(response.data){
                    return response.data.responsibility_list
                }else {
                    throw new Error(response?.error_data?.error_message ?? "An un-expected error occurred")
                }
            })
        ]
            const [
                billingData,
                serviceData,
                additionalData,
                practiceData,
                accidentList,
                responsibilityType
            ] = await Promise.all(promises);

            return {
                billingData,
                serviceData,
                additionalData,
                practiceData,
                accidentList,
                responsibilityType
            };

        } catch (error) {
            return thunkAPI.rejectWithValue('Error fetching claim information:' + error.message)
        }
    }
)


//api function to get payer insurance details of patient on save
export const getPayerInsuranceDetails = createAsyncThunk(
    "claim-info/payer-details", async (payload) => {
        let claimPK = !payload.claimPK ? "" : payload.claimPK
        let result = await service.GetListPayerInfo(payload.pageSize, payload.pageNum, payload.patientPK, payload.priority, claimPK);
        return {
            insuranceData: result.data,
            priority: payload.priority,
            claimPK: payload.claimPK
        }
    }
)

/**
 * if there is no pos against the cpt it will take service location list cpt
 */
export const GetServiceLocationPOSList = createAsyncThunk(
    "claim-info/servicelocationpos", async (payload) => {
        const result = await service.GetFacilityPos(payload);
        if (result) {
            return result.data
        }
    }
)

export const GetCPTCodeListWithDate = createAsyncThunk(
    "claim-info/getcptcodelistwithdate", async (payload) => {
        let practicePK = getStorage('practice');
        const result = await service.GetCPTCodeList(0, 0, practicePK, payload.type, payload.query, payload.claimPK, payload.dos);
        return { data: result.data, request: payload };
    }
)


/**
 * function to call save and update claim
 */

export const createAndUpdateClaim = createAsyncThunk(
    "claim-info/createAndUpdateClaim", async (payload, { dispatch, getState }) => {
        const state = getState();
        let { claimManagement, claimSelectInputManager } = state;
        let { claimPK, isClose } = payload;
        let { isError, errorMessage, status } = claimValidation(claimManagement, claimSelectInputManager)
        // checks if Transport Code equals 2 i.e, Return trip and if return trip reason is empty, immediately returns without saving.
        if (claimManagement.additionalClaimDetails?.transport_code == 2 && !claimManagement.additionalClaimDetails?.return_trip_reason){
            return;
        }
        if (isError) {
            dispatch(showNotifications({ isopen: isError, message: errorMessage, status: status }))
        }
        else {
            let {
                professionaldata,
                billingData,
                diagnosisdata,
                procedureArray,
                additionaldata,
            } = createSaveData(
                claimManagement.professionalClaimDetails,
                claimManagement.billingInfoDetails,
                claimManagement.serviceLineDetails,
                claimManagement.additionalClaimDetails,
                claimPK
            );

            let result = "";
            let billing_provider_id = billingData.billing_provider_id || null;
            let rendering_provider_id = billingData.rendering_provider_id || null;

            let data = {
                ...professionaldata,
                billing_info: {
                    ...billingData,
                    billing_provider_id: billing_provider_id,
                    rendering_provider_id: rendering_provider_id
                },
                diagnosises: diagnosisdata,
                additional_claim_info: additionaldata,
                procedures: procedureArray,
            };

            if (!claimPK) {
                result = await service.SaveNewClaimCopy(data);
            } else {
                result = await service.UpdateNewClaim(claimPK, data);
            }

            let isNewClaim = false;
            let claim_id = ""
            let claim_pk = ""
            if (result.status === 201 || result.status === 200) {
                if (result.status === 201) {
                    dispatch(showNotifications({ isopen: true, message: ADD_SUCCESS, status: "success" }))
                    isNewClaim = true;
                }
                else {
                    dispatch(showNotifications({ isopen: true, message: UPDATE_SUCCESS, status: "success" }))
                    /**
                     * calling all API to restore claim details 
                     */
                    if(claimPK && !isClose){
                    dispatch(getEditDetails(claimPK));
                    }

                    /**
                     * Claim in-active logics:
                     * When a claim is being in-activated, then immediately close that specific claim tab.
                     * Also remove the html focus element from the in-active button
                     * Finally return from this asyncThunk api function i.e createAndUpdateClaim
                     */
                    if (!professionaldata?.active) {
                        // Allowing 2 seconds of await before closing the tab, so user can read the success notify message before claim tab is being closed
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        dispatch(showModalWindow({ key: "inactiveClaimModal", value: false, claimPK: "" }));
                        let item = {
                            pk: claimPK, claim_id: result.data?.custom_claim_id,
                            type: 'claims', action: 'remove',
                        }
                        dispatch(addRemoveFromSelectedTab({ item: item, tab: item.pk !== "add_new" ? "editClaims" + item.pk : "editClaim" }))
                        document.activeElement?.blur();
                        return;
                    }
                }

                /**waring text handled here */
                // eslint-disable-next-line
                let waringText = claimFormWaringMessage(result.data);
                let serverWarings = setWarningFromServer(result.data);
                if (serverWarings) {
                    dispatch(setFormWaringData(serverWarings));
                }
                if (waringText) {
                    /*** show formwarning on save if warning Text contain error*/
                    dispatch(setFormWarningMessages(waringText));
                    if (isClose) {
                        claim_pk = result.data.id;
                        claim_id = result.data.custom_claim_id;
                        dispatch(showModalWindow({ key: "saveCloseConfirmation", value: true }));
                        dispatch(saveClaimIDWaringPopUP({claimPK:claim_pk,claimId:claim_id,isClose:isClose,isSave:isNewClaim}))
                    }
                    else {
                        claim_pk = result.data.id;
                        claim_id = result.data.custom_claim_id;
                        dispatch(showModalWindow({ key: "saveConfirmation", value: true }));
                        dispatch(saveClaimIDWaringPopUP({ claimPK: claim_pk, claimId: claim_id, isClose: isClose, isSave: isNewClaim }))
                    }
                } else if (isClose) {
                    if (result.data) {
                        claim_pk = result.data.id;
                        claim_id = result.data.custom_claim_id;
                    }

                    let item1 = {}
                    let item2 = {};
                    if (isNewClaim) {
                        item1 = {
                            pk: claim_pk, claimId: claim_id, type: 'claims',
                            action: 'add', isClosed: true,
                            practice_pk: getStorage("practice")
                        }
                        item2 = {
                            pk: 'add_new', claimId: 'add_new',
                            type: 'claims', action: 'remove', practice_pk: getStorage("practice")
                        }

                        await dispatch(addRemoveFromSelectedTab({ item: item1, tab: item1.pk !== "add_new" ? "editClaims" + item1.pk : "editClaim" })).then(async(response)=>{
                            if(response?.meta?.requestStatus==="fulfilled"){
                                await dispatch(addRemoveFromSelectedTab({ item: item2, tab: item2.pk !== "add_new" ? "editClaims" + item2.pk : "editClaim" }))  
                            }
                        })
                    }
                    else {
                        let item = {
                            pk: claim_pk, claim_id: claim_id,
                            type: 'claims', action: 'remove',
                        }
                        dispatch(addRemoveFromSelectedTab({ item: item, tab: item.pk !== "add_new" ? "editClaims" + item.pk : "editClaim" }))
                    }
                } else {
                    if (result.data) {
                        claim_pk = result.data.id;
                        claim_id = result.data.custom_claim_id;
                    }

                    if (isNewClaim) {
                        let item1 = {
                            pk: 'add_new', claimId: 'add_new',
                            type: 'claims', action: 'remove'
                        }
                        let item2 = {
                            pk: claim_pk, claimId: claim_id,
                            type: 'claims', action: 'add',
                            active_tab: claimManagement.activeSubTab
                        }
                        await dispatch(addRemoveFromSelectedTab({ item: item1, tab: item1.pk !== "add_new" ? "editClaims" + item1.pk : "editClaim" })).then(async(response)=>{
                            if(response?.meta?.requestStatus==="fulfilled"){
                                await dispatch(addRemoveFromSelectedTab({ item: item2, tab: item2.pk !== "add_new" ? "editClaims" + item2.pk : "editClaim" }))  
                            }
                        })
                    }
                }

            } else {
                let { errorMessage, inActiveModal } = APIErrorHandler(result);
                /**show inactiveModal if claim is already inactive */
                dispatch(showModalWindow({ key: "inactiveClaimModal", value: inActiveModal }));
                dispatch(showNotifications({ isopen: true, message: errorMessage, statusTag: "error" }))
            }
        }
    }
)

export const ActivateInactiveClaim = createAsyncThunk(
    "claim-info/ActivateInactiveClaim", async (payload, { dispatch, rejectWithValue }) => {
        let data = { 'active': true}
        const result = await service.UpdateClaimActive(payload, data);
        if (result.data) {
            dispatch(showModalWindow({ key: "activeClaimModal", value: false, claimPK: payload }))
            return {
                status: "success",
                claimPK: payload
            }
        } else {
            return rejectWithValue("An error occurred while activating the claim.");
        }
    }
)


export const checkForPatientAlert = createAsyncThunk(
    "claim-info/patientClaimAlert", async (payload) => {
        const result = await service.CheckForAlert(payload); 
            return result.data;
    }
)


export const getPatientHistory = createAsyncThunk(
    "claim-info/patientHistory", async (payload) => {
        const result = await service.GetPatientHistory(payload);
        return result.data;
    }
)

// api for add diagnosis code using paste
export const getDiagnosisCodeOnPaste = createAsyncThunk(
    "claim-info/getDiagnosisCodeOnPaste", async (payload) => {
        let { value, type, claimPK } = payload;
        const result = await service.GetICD10List(0, 0, getStorage('practice'), type, value, claimPK);
        return { result: result.data, request: payload };
  }
)



