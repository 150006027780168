import api from '../../../service/api'

const ListClaimSubStatusTypes = async(pageSize, page, isAdminModule, practicePK,searchValue) =>{
	let path = null;

	if (isAdminModule === 'false') 
		path = 'practice/claim-sub-status/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	else 
		path = 'super-admin/claim-sub-status/?page_size='+pageSize+'&page='+page;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }		
	return api.get(path);
}

const AddClaimSubStatusType = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/claim-sub-status/';
	else
		path = 'super-admin/claim-sub-status/';
		return api.post(path,data);
}
const GetClaimSubStatusType = async(ClaimSubStatusId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/claim-sub-status/' + ClaimSubStatusId
	else
		path = 'super-admin/claim-sub-status/' + ClaimSubStatusId
		return api.get(path);
}

const UpdateClaimSubStatusType = async(ClaimSubStatusId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/claim-sub-status/' + ClaimSubStatusId
	else
		path = 'super-admin/claim-sub-status/' + ClaimSubStatusId
		return api.put(path,data);
}

const DeleteClaimSubStatusType = async(ClaimSubStatusId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/claim-sub-status/' + ClaimSubStatusId
	else
		path = 'super-admin/claim-sub-status/' + ClaimSubStatusId
		return api.destroy(path);
}

export default {ListClaimSubStatusTypes, AddClaimSubStatusType, GetClaimSubStatusType, UpdateClaimSubStatusType, DeleteClaimSubStatusType}