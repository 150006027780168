import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import service from "./service";
import {
    ROUTE_SUPER_DICTIONARIES,
    PAGING_END_INDEX,
    DEFAULT_PAGING_SIZE,
} from "../../../utilities/staticConfigs";
import {
    ADD_SUCCESS,
    ADD_ERROR,
    UPDATE_SUCCESS,
    DELETE_SUCCESS,
} from "../../../utilities/labelConfigs";
import Notify from "../../commons/notify";
import Pagination from "../../commons/pagination";
import LoadingContext from "../../../container/loadingContext";
import i18n from "../../../utilities/i18n";
import TextInput from "../../commons/input/input";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import Table from "../../commons/Table/Table";
import BackArrowWithLabel from "../../commons/Back";
import { PolicyTableData } from "./PolicyTypeTable";
import {
    checkPermission,
    commonTableBody,
} from "../../../utilities/commonUtilities";
import CommonButton from "../../commons/Buttons";
import {
    super_admin_permission_key_value,
    super_admin_privileges,
} from "../../../utilities/permissions";

const PolicyTypesList = (props) => {
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [policyType, setPolicyType] = useState("");
    const [description, setDescription] = useState("");
    const [deletePolicyName, setDeletePolicyName] = useState("");
    const [deletePolicyId, setDeletePolicyId] = useState("");
    const [editPolicyId, setEditPolicyId] = useState("");
    const [policyTypeList, setPolicyTypeList] = useState([]);
    const [form_error, setFormError] = useState({
        policyType: "",
        description: "",
    });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState("");
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const setShowLoadingBar = useContext(LoadingContext);
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    // end

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPolicyData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id);
        setActivePage(page);
        getPolicyData(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getPolicyData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew() {
        if (!permission) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.permission_error")
            );
            return;
        }
        setHeader(i18n.t("dictionariesPages.policyTypesList.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }
    function onDeleteHide() {
        setDeletePolicyName("");
        setDeletePolicyId("");
        setShowDeleteModalWindow(false);
    }

    function getPolicyData(pageSize, page, searchString) {
        setShowLoadingBar(true);
        const result = service.GetListPolicyType(pageSize, page, searchString);
        result.then((response) => {
            if (response.data.results !== undefined) {
                setTotalPage(
                    Math.ceil(response.data.count / response.data.page_size)
                );
            }
            setShowLoadingBar(false);
            if (response.data?.results) {
                response.data.results = response.data.results?.map((item) => {
                    if (item.not_delete === true) {
                        item.isDefault = "Yes";
                    } else {
                        item.isDefault = "No";
                    }
                    return item;
                });
            }
            const rowArray = commonTableBody(
                response.data.results,
                PolicyTableData.tableBodyData[0]
            );
            PolicyTableData.tableBodyData = rowArray;
            setPolicyTypeList(response.data.results);
        });
    }

    useEffect(() => {
        getPolicyData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(
            checkPermission(
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add
            )
        );
    }, []);

    function onEditPolicyType(policyId) {
        setShowLoadingBar(true);
        const data = service.GetPolicyType(policyId);
        data.then((response) => {
            setShowLoadingBar(false);
            setHeader(i18n.t("dictionariesPages.policyTypesList.editHeader"));
            setShowModalWindow(true);
            setPolicyType(response.data.name);
            setDescription(response.data.description);
            setEditForm(true);
            setEditPolicyId(policyId);
        });
    }
    function onDeletePolicyType(policyId) {
        policyTypeList.map((item) => {
            if (item.id === parseInt(policyId)) {
                setDeletePolicyName(item.name);
                setDeletePolicyId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        const data = service.DeletePolicyType(deletePolicyId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeletePolicyName("");
            setDeletePolicyId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            getPolicyData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setPolicyType("");
        setDescription("");
        setEditForm(false);
        setFormError({
            ...form_error,
            ["policyType"]: "",
            ["description"]: "",
        });
    }
    function onSaveFormData(e) {
        e.preventDefault();
        //setting error type for mandatory field
        setFormError({
            ...form_error,
            ["policyType"]: !policyType.trim() ? "error" : "",
            ["description"]: !description ? "error" : "",
        });

        if (policyType && description) {
            setShowLoadingBar(true);
            let result = null;
            //add and edit of policy type if editForm is true update api is called else new policy type will created
            result = editForm
                ? service.UpdatePolicyType(editPolicyId, {
                      name: policyType,
                      description: description,
                  })
                : service.AddPolicyType({
                      name: policyType,
                      description: description,
                  });
            result.then((response) => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201)
                        showNotifyWindow("show", "success", ADD_SUCCESS);
                    else showNotifyWindow("show", "success", UPDATE_SUCCESS);

                    getPolicyData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (
                    response.status === 400 &&
                    response.data.record_already_exists !== undefined
                ) {
                    showNotifyWindow(
                        "show",
                        "error",
                        i18n.t("errorMessages.record_exists")
                    );
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
            });
        }
    }

    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = "";
        trimmedValue = value;
        // checking filed is policytype and value entered is empty and setting error
        if (!trimmedValue && name === "policyType") {
            setFormError({
                ...form_error,
                [name]: "error",
            });
        } else {
            setFormError({
                ...form_error,
                [name]: "",
            });
        }
        if (name === "policyType") {
            setPolicyType(trimmedValue);
        } else if (name === "searchValue") {
            setSearchValue(trimmedValue);
        } else {
            setDescription(trimmedValue);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && searchValue) {
            onSearch(e, searchValue);
        }
    };

    function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
        getPolicyData(DEFAULT_PAGING_SIZE, 1, searchString);
    }

    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
        getPolicyData(DEFAULT_PAGING_SIZE, activePage);
    };

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES);
    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.permission_error")
            );
            return;
        }
        // default policy are not allowed to edit or delete from the database;
        const isDefaultPolicy = policyTypeList.some((item) => {
            if (item.id === id && item.not_delete) {
                return true;
            } else {
                return false;
            }
        });
        if (isDefaultPolicy) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t(
                    "dictionariesPages.policyTypesList.editRestrictionMessage"
                )
            );
            return;
        }
        // if the policy is not self then proceed;
        if (name.toLowerCase() == "edit") {
            onEditPolicyType(Number(id));
        }
        if (name.toLowerCase() == "delete") {
            onDeletePolicyType(Number(id));
        }
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white  mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.policyTypesList.lblPolicyType"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>

                        <div className="table-responsive">
                            <Table
                                tableObject={PolicyTableData}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="policyType"
                                        name="policyType"
                                        label={i18n.t(
                                            "dictionariesPages.policyTypesList.lblPolicyType"
                                        )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.policyType
                                                ? "input-error"
                                                : ""
                                        }
                                        value={policyType}
                                        required={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="add-policy-type-descriptionBox">
                                        {i18n.t(
                                            "dictionariesPages.dictRevenueCode.lblDescription"
                                        )}
                                    </label>
                                    <div className={"text-input"}>
                                        <textarea
                                            id="add-policy-type-descriptionBox"
                                            placeholder={i18n.t(
                                                "dictionariesPages.dictRevenueCode.lblDescription"
                                            )}
                                            name="description"
                                            required={true}
                                            className={
                                                !description
                                                    ? "textarea-100 input-error"
                                                    : "textarea-100"
                                            }
                                            value={description}
                                            onChange={(e) =>
                                                onHandleChange(e)
                                            }></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.policyTypesList.deletConfirmation") +
                    "'" +
                    deletePolicyName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
};

export default PolicyTypesList;
