import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import claimService from './service';
import i18n from '../../../utilities/i18n';
import { YesOrNo, PRIORITIES } from '../../../utilities/dictionaryConstants';
import { DEFAULTCLAIMFREQUENCY, DEFAULTRELATION, DEFAULT_PAGING_SIZE , CLAIM_FREQUENCY_ORIGINAL_CLAIM_ID} from '../../../utilities/staticConfigs';
import TextInput from '../../commons/input/input'
import SelectInput from '../../commons/input/SelectInput';
import service from '../../patientManagement/patients/service';
import service1 from '../../dictionaries/relationShips/service';
import service2 from '../../dictionaries/claimFrequency/service';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import { getFormWarings, updateBillingInfoDatas, updatePayerDetails } from '../claimManagementSlice/claimManagementSlice';
import { getPatientPKDetails, updateBillingInfoSaveDatas, updatePayerInsurance, updatePayerSaveDetails } from '../claimManagementSlice/saveManagementSlice';
let billingInfoDataFromDb = {};
let serviceLocationTempValue = '';
function BillingInfo(props) {
    const dispatch = useDispatch();
    const billingInfoDetails = useSelector(state => state.claimData.BillingInfoData);
    const claimDetails = useSelector(state => state.claimData.commonData);
    const Mode_Of_Save = useSelector(state => state.claimSaveManagmentData.saveMode);
    const { infoLoaded, BillingInfoData, removedData } = useSelector(state => state.claimSaveManagmentData);
    const [payerPatientPK, setPayerPatientPK] = useState("");
    const activePage = 1;
    const [renderingProviderList, setRenderingProviderList] = useState([]);
    const [referringProviderList, setReferringProviderList] = useState([]);
    const [serviceLocationList, setServiceLocationList] = useState([]);
    const [claimFrequencyList, setClaimFrequencyList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const [payerInsuranceList, setPayerInsuranceList] = useState([]);
    const [primaryInsuranceList, setPrimaryInsuranceList] = useState([]);
    const [primaryInsurance, setPrimaryInsurance] = useState("");
    const [primaryPolicyID, setPrimaryPolicyID] = useState("");
    const [dbPrimaryInsurance, setDbPrimaryInsurance] = useState("");
    const [secondaryInsuranceList, setSecondaryInsuranceList] = useState([]);
    const [secondaryInsurance, setSecondaryInsurance] = useState("");
    const [secondaryPolicyID, setSecondaryPolicyID] = useState("");
    const [dbSecondaryInsurance, setDbSecondaryInsurance] = useState("");
    const [tertiaryInsuranceList, setTertiaryInsuranceList] = useState([]);
    const [tertiaryInsurance, setTertiaryInsurance] = useState("");
    const [tertiaryPolicyID, setTertiaryPolicyID] = useState("");
    const [dbTertiaryInsurance, setDbTertiaryInsurance] = useState("");
    const [dbPayerInfoList, setDbPayerInfoList] = useState([]);
    const [insuranceCompleted, setInsuranceCompleted] = useState(false);
    const [payerCalled, setPayerCalled] = useState(false);


    const [billingInfoVariable, setBillingInfoVariable] = useState({
        "pk": "", "accept_assignments": 1, "patient_signature": 1,
        "insured_signature": 1, "claim": "", "billing_provider": "",
        "rendering_provider": "", "supervising_provider": "", "referring_provider": "",
        "ordering_provider": "", "service_location": "", "service_location_value": "",
        "primary_insurance": "", "secondary_insurance": "", "tertiary_insurance": "",
        "insurer_relation": "", "claim_fequency": "", "original_claim_id": "",
        "claim_reference": "", "payer_info": [], "added_insurance_info": []
    });

    //Warning Message changes --- start  
    const checkFormWarning = () => {
        let formWarningData = props.formWarningData;
        props.setFormWarningData(formWarningData)
        props.setFormWarningStatus(!props.formWarningStatus)
    };//Warning Message changes --- end

    const onHandleChangeInsurance = (e, fieldType, priority) => {
        let value = e.target.value;
        let priorityInDigit = 0;
        if (priority === 'primary')
            priorityInDigit = 1;
        else if (priority === 'secondary')
            priorityInDigit = 2;
        else if (priority === 'tertiary')
            priorityInDigit = 3;
        let arryObj = {};
        let arrInsure = payerInsuranceList;
        let payerObj = payerInsuranceList.filter((pIObj) => pIObj.priority === priorityInDigit);
        if (payerObj && payerObj.length > 0) {
            arryObj = payerObj[0];
            delete arryObj.id;
        }
        if (priority === 'primary') {
            arryObj.priority = 1;
            if (fieldType === 'insurance') {
                if (value && value != '0') {
                    let primaryInsureObj = primaryInsuranceList.filter((pIObj) => pIObj.id === value);
                    if (primaryInsureObj) {
                        setPrimaryInsurance(value);
                        setPrimaryPolicyID(primaryInsureObj[0].policy_id);
                        arryObj.policy_id = primaryInsureObj[0].policy_id;
                        arryObj.practice_insurance_id = primaryInsureObj[0].id;
                    } else {
                        setPrimaryInsurance("");
                        setPrimaryPolicyID("");
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                } else {
                    setPrimaryInsurance("");
                    setPrimaryPolicyID("");
                    delete arryObj['priority'];
                    delete arryObj['practice_insurance_id'];
                    delete arryObj['policy_id'];
                }
            }
            if (fieldType === 'policyId') {
                setPrimaryPolicyID(value);
                arryObj.policy_id = value;
            }
        }

        if (priority === 'secondary') {
            arryObj.priority = 2;
            if (fieldType === 'insurance') {
                if (value && value != '0') {
                    let secondaryInsureObj = secondaryInsuranceList.filter((pIObj) => pIObj.id === value);
                    if (secondaryInsureObj) {
                        setSecondaryInsurance(value);
                        setSecondaryPolicyID(secondaryInsureObj[0].policy_id);
                        arryObj.policy_id = secondaryInsureObj[0].policy_id;
                        arryObj.practice_insurance_id = secondaryInsureObj[0].id;
                    } else {
                        setSecondaryInsurance("");
                        setSecondaryPolicyID("");
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                } else {
                    setSecondaryInsurance("");
                    setSecondaryPolicyID("");
                    delete arryObj['priority'];
                    delete arryObj['practice_insurance_id'];
                    delete arryObj['policy_id'];
                }
            }
            if (fieldType === 'policyId') {
                setSecondaryPolicyID(value);
                arryObj.policy_id = value;
            }
        }

        if (priority === 'tertiary') {
            arryObj.priority = 3;
            if (fieldType === 'insurance') {
                if (value && value != '0') {
                    let tertiaryInsureObj = tertiaryInsuranceList.filter((pIObj) => pIObj.id === value);
                    if (tertiaryInsureObj) {
                        setTertiaryInsurance(value);
                        setTertiaryPolicyID(tertiaryInsureObj[0].policy_id);
                        arryObj.policy_id = tertiaryInsureObj[0].policy_id;
                        arryObj.practice_insurance_id = tertiaryInsureObj[0].id;
                    } else {
                        setTertiaryInsurance("");
                        setTertiaryPolicyID("");
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                } else {
                    setTertiaryInsurance("");
                    setTertiaryPolicyID("");
                    delete arryObj['priority'];
                    delete arryObj['practice_insurance_id'];
                    delete arryObj['policy_id'];
                }
            }
            if (fieldType === 'policyId') {
                setTertiaryPolicyID(value);
                arryObj.policy_id = value;
            }
        }
        let position = -1;
        let pushItem = -1;
        arrInsure.filter((item, index) => {
            if (item.priority === arryObj.priority && (!item.practice_insurance_id)) {
                arrInsure.splice(index, 1);
                pushItem = index;
            }
            else if (item.priority === arryObj.priority && (item.practice_insurance_id)) {
                pushItem = index;
                position = index;
            }
        })
        if (position === -1 && pushItem === -1)
            arrInsure.push(arryObj);
        else
            if (position !== -1 && pushItem !== -1)
                arrInsure[pushItem] = arryObj;
        if (Object.keys(arrInsure).length > 0) {
            setPayerInsuranceList(arrInsure);
            setBillingInfoVariable({
                ...billingInfoVariable, ['payer_info']: arrInsure
            });
            if (Mode_Of_Save !== 'Save') {
                dispatch(updatePayerDetails({
                    arrInsure: arrInsure,
                    billingInfoPK: props.billing_info_pk
                }))
            }
            else {
                dispatch(updatePayerSaveDetails({ arrInsure: arrInsure }))
            }
        } else {
            setPayerInsuranceList([]);
            setBillingInfoVariable({
                ...billingInfoVariable, ['payer_info']: []
            });
            if (Mode_Of_Save !== 'Save') {
                dispatch(updatePayerDetails({
                    arrInsure: [],
                    billingInfoPK: props.billing_info_pk
                }))
            }
            else {
                dispatch(updatePayerSaveDetails({ arrInsure: [] }))
            }
        }

    }

    useEffect(() => {
        if(billingInfoDetails.service_location_value){
            props.setServiceLocationId(billingInfoDetails.service_location_value);
        } else {
            props.setServiceLocationId(BillingInfoData.service_location_value);
        }
    }, [billingInfoDetails.service_location_value,BillingInfoData.service_location_value]);


    useEffect(() => {
        setBillingInfoVariable({
            ...billingInfoVariable, ['payer_info']: payerInsuranceList
        });
    }, [JSON.stringify(payerInsuranceList)]);

    useEffect(() => {
        if (!props.billingInfoDataLoaded) {
            props.setBillingInfoDataLoaded(true);
        }
    }, [JSON.stringify(billingInfoVariable)]);

    //if saved calling api to remove formwarnings
    useEffect(() => {
        if (props.isSaved) {
            if (claimDetails.id) {
                dispatch(getFormWarings(claimDetails.id))
            }
            props.setIsSaved(false)
        }
    }, [props.isSaved])

    const onHandleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == "billing_provider") {
            props.setProviderChanged(true)
        }
        if (name === "service_location_value") {
            let service_location_type = value.split("||")[1];
            if (service_location_type == "practice") {
                if (Mode_Of_Save === 'Save') {
                    dispatch(updateBillingInfoSaveDatas({ name: "practice_location", value: value }))
                }
                else {
                    dispatch(updateBillingInfoDatas({ name: "practice_location", value: value, billingInfoPK: props.billing_info_pk }))
                }
            }
            else {
                if (Mode_Of_Save === 'Save') {
                    dispatch(updateBillingInfoSaveDatas({ name: "facility_location", value: value }))
                }
                else {
                    dispatch(updateBillingInfoDatas({ name: "facility_location", value: value, billingInfoPK: props.billing_info_pk }))
                }
            }
        } else {
            if (Mode_Of_Save === 'Save') {
                dispatch(updateBillingInfoSaveDatas({ name: name, value: value }))
            }
            else {
                dispatch(updateBillingInfoDatas({ name: name, value: value, billingInfoPK: props.billing_info_pk }))
            }
        }
        checkFormWarning(name, value);
    };

    const getRenderingProviders = (pageSize) => {
        const page = 0;
        const result = service.ListRenderingProviders(pageSize, page, props.practicePK, null, props.claimPK);
        result.then(response => {
            setRenderingProviderList(response.data);
            if (response.data.length > 1 && !billingInfoVariable.billing_provider) {
                // if more than one provider then only set default billing provider. Rendering and super vising not set
                const obj = response.data;
                const default_billing_provider = obj.filter((prObj) => prObj.default_provider === true);
                if (default_billing_provider) {
                    // if any default provider
                    billingInfoVariable['billing_provider'] = default_billing_provider[0]?.id;
                }
            }
            else if (response.data.length === 1 && !billingInfoVariable.billing_provider) {
                // if only one provider then initial set billing, rendering and supervising provider
                const obj = response.data;
                const default_billing_provider = obj.filter((prObj) => prObj.default_provider === true);
                if (default_billing_provider.length > 0) {
                    // if any default provider
                    billingInfoVariable['billing_provider'] = default_billing_provider[0]?.id;
                    billingInfoVariable['rendering_provider'] = obj[0].id;
                    billingInfoVariable['supervising_provider'] = obj[0].id;
                } else {
                    // if not default provider, set single item as provider
                    billingInfoVariable['billing_provider'] = obj[0].id;
                    billingInfoVariable['rendering_provider'] = obj[0].id;
                    billingInfoVariable['supervising_provider'] = obj[0].id;
                }
            }
        });
    };

    const getReferringProviders = (pageSize) => {
        const page = 0;
        const result = service.ListReferringProviders(pageSize, page, props.practicePK, null, props.claimPK);
        result.then(response => {
            setReferringProviderList(response.data);
            if (response?.data?.length === 1 && !billingInfoVariable.referring_provider) {
                let obj = response.data;
                billingInfoVariable["referring_provider"] = obj[0].id;
                billingInfoVariable["ordering_provider"] = obj[0].id;
                setBillingInfoVariable({
                    ...billingInfoVariable, "referring_provider": obj[0].id,
                    "rendering_provider": obj[0].id
                })
            }
        });
    };

    const getServiceLocations = async () => {
        try {
            const response = await claimService.ServiceLocationLists(props.practicePK, null, props.claimPK);
            if (response.data?.length) {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                    if (!props.dataFromDbExist && item.default_location === true && billingInfoVariable.service_location === '') {
                        if (!serviceLocationTempValue) {
                            let tmpItem = { id: item.Id, location_type: item.location_type };
                            setBillingInfoVariable(
                                { ...billingInfoVariable, 'service_location': tmpItem, 'service_location_value': item.Id + '||' + item.location_type });
                            checkFormWarning('serviceLocations', item.Id + '||' + item.location_type);
                        }
                    }
                })
                setServiceLocationList(locations);
            }  
        } catch (error) {
            console.error("Error fetching service location:", error)
        }
    };

    const getPayerInsurance = async (pageSize, page, priority) => {
        const pageNum = 0;
        const response = await claimService.GetListPayerInfo(pageSize, pageNum, props.patientPK, priority,props.claimPK);
        if (Array.isArray(response.data) && response.data.length > 0) {
            const arrInsurePriority = response.data.map(item => ({
                id: item.Id,
                name: item.name,
                policy_id: item.policy_id,
            }));

            if (arrInsurePriority.length > 0 && !props.claimPK) {
                const priorityObj = PRIORITIES.find(pIObj => pIObj.name.toLowerCase() === priority);
                const arryObj = {
                    practice_insurance_id: arrInsurePriority[0].id,
                    policy_id: arrInsurePriority[0].policy_id,
                };

                if (priorityObj) {
                    arryObj.priority = priorityObj.id;
                }

                const existingPayerInsuranceListList = [...payerInsuranceList, arryObj];
                setPayerInsuranceList(existingPayerInsuranceListList);
                setBillingInfoVariable({ ...billingInfoVariable, payer_info: existingPayerInsuranceListList });
                dispatch(updatePayerInsurance({ arrInsure: arryObj }))
            }
            if (priority === 'primary') {
                props.setPrimaryInsuranceList(response.data);
                setPrimaryInsuranceList(arrInsurePriority);

                if (arrInsurePriority.length > 0 && !props.claimPK) {
                    setPrimaryInsurance(arrInsurePriority[0].id);
                    setPrimaryPolicyID(arrInsurePriority[0].policy_id);
                }
            } else if (priority === 'secondary') {
                props.setSecondaryInsuranceList(response.data);
                setSecondaryInsuranceList(arrInsurePriority);

                if (arrInsurePriority.length > 0 && !props.claimPK) {
                    setSecondaryInsurance(arrInsurePriority[0].id);
                    setSecondaryPolicyID(arrInsurePriority[0].policy_id);
                }
            } else if (priority === 'tertiary') {
                props.setTertiaryInsuranceList(response.data);
                setTertiaryInsuranceList(arrInsurePriority);

                if (arrInsurePriority.length > 0 && !props.claimPK) {
                    setTertiaryInsurance(arrInsurePriority[0].id);
                    setTertiaryPolicyID(arrInsurePriority[0].policy_id);
                }

                setInsuranceCompleted(true);
            }
        }
    };

    const getClaimFrequencyData = async (pageSize) => {
        try {
            const response = await service2.GetListClaimFrequency(pageSize, 0, props.claimPK);
            if (response.data?.length) {
                const obj = response?.data?.find((item) => item.description.toLowerCase() === DEFAULTCLAIMFREQUENCY)
                setClaimFrequencyList(response.data);
                if (obj) {
                    billingInfoVariable["claim_fequency"] = obj.id;
                    billingInfoVariable["original_claim_id"] = obj.id;
                }
            }
        } catch (error) {
            console.error("Error fetching claim frequency:", error)
        }
    };

    const getRelationData = (pageSize) => {
        const page = 0;
        const result = service1.ListRelations(pageSize, page, props.claimPK);
        result.then(response => {
            setRelationList(response?.data || []);
            const obj = response?.data?.find((item) => item?.name?.toLowerCase() === DEFAULTRELATION);
            setBillingInfoVariable({
                ...billingInfoVariable, "insurer_relation": obj.id
            })
        }).catch(error => {
            console.error("Error while fetching relations:", error);
        })
    };

    useEffect(() => {
        getClaimFrequencyData(DEFAULT_PAGING_SIZE, activePage);
        getRenderingProviders(DEFAULT_PAGING_SIZE, activePage);
        getServiceLocations();
        getReferringProviders(DEFAULT_PAGING_SIZE, activePage);
        getRelationData(DEFAULT_PAGING_SIZE, activePage);
    }, []);

    useEffect(() => {
        if (props.claimPK && props.billing_info_pk) {
            setPrimaryInsuranceList([]);
            setSecondaryInsuranceList([]);
            setTertiaryInsuranceList([]);
        }
    }, [props.billing_info_pk]);

    useEffect(() => {
        if (props.patientPK !== payerPatientPK && !props.claimPK && !infoLoaded) {
            if (props.patientPK) {
                setBillingInfoVariable({ // setting added_insurance_info empty for each patient change in claim
                    ...billingInfoVariable, "added_insurance_info": []
                });
                setPayerInsuranceList([]); // should set empty in getPatientDetails, other wise duplicate payer will sent to server
                dispatch(getPatientPKDetails(props.patientPK))
                setPayerPatientPK(props.patientPK);
            }
        }
    }, [props.patientPK, payerPatientPK]);

    useEffect(() => {
        if (billingInfoVariable.added_insurance_info.length > 0 && !props.claimPK) {
            setPrimaryInsuranceList([]);
            setSecondaryInsuranceList([]);
            setTertiaryInsuranceList([]);
            setPayerInsuranceList([]); // should set empty in getPatientDetails, other wise duplicate payer will sent to server
            // change primary payer details if patient pk changed
            getPayerInsurance(DEFAULT_PAGING_SIZE, null, 'primary');
            // change secondary payer details if patient pk changed
            getPayerInsurance(DEFAULT_PAGING_SIZE, null, 'secondary');
            // change tertiary payer details if patient pk changed
            getPayerInsurance(DEFAULT_PAGING_SIZE, null, 'tertiary');
        }

    }, [JSON.stringify(billingInfoVariable.added_insurance_info)])

    useEffect(() => {
        if (props.claimPK && insuranceCompleted) {
            billingInfoDataFromDb.payer_info.map(() => {
                const inPrimary = primaryInsuranceList.filter(item => {
                    if (dbPrimaryInsurance && item.id === dbPrimaryInsurance)
                        return item
                });
                const inSecondary = secondaryInsuranceList.filter(item => {
                    if (dbSecondaryInsurance && item.id === dbSecondaryInsurance)
                        return item
                });
                const inTertiary = tertiaryInsuranceList.filter(item => {
                    if (dbTertiaryInsurance && item.id === dbTertiaryInsurance)
                        return item
                });
                dbPayerInfoList.map((item) => {
                    if (inPrimary.length === 0 && item.priority === 1) {
                        let temp_primaryInsuranceList = primaryInsuranceList

                        temp_primaryInsuranceList.push({ 'id': item.Id, 'name': item.name, 'policy_id': item.policy_id })
                        setPrimaryInsuranceList(temp_primaryInsuranceList)
                    }
                    if (inSecondary.length === 0 && item.priority === 2) {
                        let temp_secondaryInsuranceList = secondaryInsuranceList
                        temp_secondaryInsuranceList.push({ 'id': item.Id, 'name': item.name, 'policy_id': item.policy_id })
                        setSecondaryInsuranceList(temp_secondaryInsuranceList)
                    }
                    if (inTertiary.length === 0 && item.priority === 3) {
                        let temp_tertiaryInsuranceList = tertiaryInsuranceList
                        temp_tertiaryInsuranceList.push({ 'id': item.Id, 'name': item.name, 'policy_id': item.policy_id })
                        setTertiaryInsuranceList(temp_tertiaryInsuranceList)
                    }
                })

            });
        }

    }, [insuranceCompleted])

    useEffect(() => {
        if (billingInfoVariable.pk && !payerCalled) {
            Promise.all([
                getPayerInsurance(DEFAULT_PAGING_SIZE, null, "primary"),
                getPayerInsurance(DEFAULT_PAGING_SIZE, null, "secondary"),
                getPayerInsurance(DEFAULT_PAGING_SIZE, null, "tertiary"),
            ])
                .then(() => {
                    setPayerCalled(true);
                })
                .catch((error) => {
                    console.error("Error fetching payer info:", error);
                });
        }
    }, [JSON.stringify(billingInfoVariable)]);


    useEffect(() => {
        getBillingInfoData(billingInfoDetails)
    }, [billingInfoDetails])

    useEffect(() => {
        if (Mode_Of_Save === 'Save') {
            getBillingInfoData(BillingInfoData)
        }
    }, [BillingInfoData])

    const getBillingInfoData = async (billingInfoDetails) => {
        try {
            if (billingInfoDetails) {
                const payer_info_db = billingInfoDetails?.payer_info?.map(item => ({
                    practice_insurance_id: item.Id,
                    policy_id: item.policy_id,
                    priority: item.priority,
                }));

                setPayerInsuranceList(payer_info_db);
                setDbPayerInfoList(billingInfoDetails?.payer_info);

                setBillingInfoVariable({
                    ...billingInfoVariable,
                    accept_assignments: billingInfoDetails?.accept_assignments,
                    billing_provider: billingInfoDetails?.billing_provider,
                    claim_fequency: billingInfoDetails?.claim_fequency,
                    referring_provider: billingInfoDetails?.referring_provider,
                    rendering_provider: billingInfoDetails?.rendering_provider,
                    supervising_provider: billingInfoDetails?.supervising_provider,
                    claim_reference: billingInfoDetails?.claim_reference,
                    insured_signature: billingInfoDetails?.insured_signature,
                    insurer_relation: billingInfoDetails?.insurer_relation,
                    ordering_provider: billingInfoDetails?.ordering_provider,
                    patient_signature: billingInfoDetails?.patient_signature,
                    service_location_value: billingInfoDetails?.service_location_value,
                    service_location: billingInfoDetails?.practice_location != null
                        ? { id: billingInfoDetails?.practice_location, location_type: 'practice' }
                        : billingInfoDetails?.facility_location
                            ? { id: billingInfoDetails?.facility_location, location_type: 'facility' }
                            : '',
                    claim: billingInfoDetails?.claim,
                    pk: billingInfoDetails?.id,
                    payer_info: payer_info_db,
                    added_insurance_info: Mode_Of_Save == 'Save' ? [true] : []
                });

                checkFormWarning('serviceLocations', billingInfoDetails.service_location_value);

                const keysToUpdate = Object.keys(billingInfoDetails).filter(key => !!billingInfoDetails[key]);
                for (const key of keysToUpdate) {
                    billingInfoDataFromDb[key.endsWith('provider') || key.endsWith('fequency') || key.endsWith('relation') ? `${key}_id` : key] = billingInfoDetails[key];
                }

                billingInfoDetails?.payer_info?.forEach(item => {
                    if (item.priority === 1) {
                        setPrimaryInsurance(item.Id);
                        setPrimaryPolicyID(item.policy_id);
                        setDbPrimaryInsurance(item.Id);
                    } else if (item.priority === 2) {
                        setSecondaryInsurance(item.Id);
                        setSecondaryPolicyID(item.policy_id);
                        setDbSecondaryInsurance(item.Id);
                    } else if (item.priority === 3) {
                        setTertiaryInsurance(item.Id);
                        setTertiaryPolicyID(item.policy_id);
                        setDbTertiaryInsurance(item.Id);
                    }
                });

                if (removedData) {
                    setPrimaryInsuranceList([]);
                    setSecondaryInsuranceList([]);
                    setTertiaryInsuranceList([]);
                    setPayerPatientPK("")
                }
            }
        } catch (error) {
            throw Error(error)
        }
    }

    useEffect(() => {
        let filteredBillingInfoData = {};
        let data = {};
        const { pk,
            billing_provider, rendering_provider, supervising_provider, service_location,
            referring_provider, ordering_provider, accept_assignments, insurer_relation,
            patient_signature, claim_fequency, insured_signature, payer_info, claim_reference
        } = Mode_Of_Save === 'Save' ? BillingInfoData : billingInfoDetails;
        const billingInfoVariableData = {
            pk,
            billing_provider_id: billing_provider, rendering_provider_id: rendering_provider, supervising_provider_id: supervising_provider,
            referring_provider_id: referring_provider, ordering_provider_id: ordering_provider, accept_assignments: accept_assignments,
            insurer_relation_id: insurer_relation, patient_signature, claim_fequency_id: claim_fequency, insured_signature,
            service_location, claim_reference, payer_info
        }
        for (const [key, value] of Object.entries(billingInfoVariableData)) {
            if (value) {
                filteredBillingInfoData[key] = value;
            }
        }
        if (props.claimPK) {
            let filteredBillingInfoDataFromDB = {}
            Object.keys(billingInfoDataFromDb).forEach((item) => {
                filteredBillingInfoDataFromDB[item] = billingInfoVariableData[item];
            });
            data = {
                ...filteredBillingInfoDataFromDB, ...filteredBillingInfoData, "pk": props.billing_info_pk
            }
            props.setBillingInfoDbData(data);
        }
        else if (!props.claimPK) {
            props.setBillingInfo(filteredBillingInfoData);
        }
    }, [BillingInfoData, billingInfoDetails]);

    useEffect(() => {
        // Set payer details after loading patient and billing information.
        // If a claim has been selected with an expired insurance payer, it should be loaded in the claim.
        // Note: If not written, do payer API call with claim pk after loading billing information of claim.

        if (payerCalled && billingInfoVariable.pk) {
            dbPayerInfoList.forEach((item) => {
                const { id, name, policy_id, priority } = item;
                let dbValueExistInSecondaryList, dbValueExistInPrimaryList, dbValueExistInTertiaryList;
                switch (priority) {
                    case 1:
                        dbValueExistInPrimaryList = primaryInsuranceList.some((pIObj) => pIObj.id === id);

                        if (!dbValueExistInPrimaryList) {
                            const temp_primaryInsuranceList = [...primaryInsuranceList, { id, name, policy_id }];
                            setPrimaryInsuranceList(temp_primaryInsuranceList);
                        }
                        break;

                    case 2:
                        dbValueExistInSecondaryList = secondaryInsuranceList.some((pIObj) => pIObj.id === id);

                        if (!dbValueExistInSecondaryList) {
                            const temp_secondaryInsuranceList = [...secondaryInsuranceList, { id, name, policy_id }];
                            setSecondaryInsuranceList(temp_secondaryInsuranceList);
                        }
                        break;

                    case 3:
                        dbValueExistInTertiaryList = tertiaryInsuranceList.some((pIObj) => pIObj.id === id);

                        if (!dbValueExistInTertiaryList) {
                            const temp_tertiaryInsuranceList = [...tertiaryInsuranceList, { id, name, policy_id }];
                            setTertiaryInsuranceList(temp_tertiaryInsuranceList);
                        }
                        break;

                    default:
                        break;
                }
            });
        }
    }, [payerCalled, billingInfoVariable.pk, primaryInsuranceList, secondaryInsuranceList, tertiaryInsuranceList]);

    return (
        <div className="col-md-12 margin-top20">
            <Form autoComplete="off">
                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.providerInfo")}</label>
                        </div>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <SelectInput data={renderingProviderList} required={true} name="billing_provider" value={Mode_Of_Save === 'Save' ? BillingInfoData?.billing_provider : billingInfoDetails?.billing_provider} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.billingProvider")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={renderingProviderList} required={true} name="rendering_provider" value={Mode_Of_Save === 'Save' ? BillingInfoData?.rendering_provider : billingInfoDetails?.rendering_provider} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.renderingProvider")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={renderingProviderList} name="supervising_provider" value={Mode_Of_Save === 'Save' ? BillingInfoData?.supervising_provider : billingInfoDetails?.supervising_provider} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.supervisingProvider")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={referringProviderList} name="referring_provider" value={Mode_Of_Save === 'Save' ? BillingInfoData?.referring_provider : billingInfoDetails?.referring_provider} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.referringProvider")} />
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">
                        <SelectInput data={referringProviderList} name="ordering_provider" value={Mode_Of_Save === 'Save' ? BillingInfoData?.ordering_provider : billingInfoDetails?.ordering_provider} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.orderingProvider")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={serviceLocationList} name="service_location_value" value={Mode_Of_Save === 'Save' ? BillingInfoData?.service_location_value : billingInfoDetails.service_location_value} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.serviceLocations")} selectOptionRemove={true} />

                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.payerInfo")}</label>
                        </div>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                {primaryInsuranceList.length == 0 && secondaryInsuranceList.length === 0 && tertiaryInsuranceList.length === 0 ? <div className="justify-normal">&nbsp;</div> : ''}
                {primaryInsuranceList.length > 0 ?
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.primaryPriority")}</label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                            <SelectInput data={primaryInsuranceList} value={primaryInsurance} name={"primaryInsuranceList"} onValueChange={(e) => onHandleChangeInsurance(e, 'insurance', 'primary')} label={i18n.t("searchClaims.professionalClaims.billingInf.insurance")} needUniqueOptions={true} />
                        </div>
                        <div className="col-3 pl-0">
                            <div className="form-group ">
                                <TextInput type="text" name={"primaryPolicyId"} label={i18n.t("searchClaims.professionalClaims.billingInf.policyId")}
                                    onValueChange={(e) => onHandleChangeInsurance(e, 'policyId', 'primary')} value={primaryPolicyID}
                                />
                            </div>
                        </div>
                        </div>
                    </>
                    : ''}
                {secondaryInsuranceList.length > 0 ?
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.secondaryPriority")}</label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                            <SelectInput data={secondaryInsuranceList} value={secondaryInsurance} name={"secondaryInsuranceList"} onValueChange={(e) => onHandleChangeInsurance(e, 'insurance', 'secondary')} label={i18n.t("searchClaims.professionalClaims.billingInf.insurance")} needUniqueOptions={true} />
                        </div>
                        <div className="col-3 pl-0">
                            <div className="form-group ">
                                <TextInput type="text" name={"secondaryPolicyId"} label={i18n.t("searchClaims.professionalClaims.billingInf.policyId")}
                                    onValueChange={(e) => onHandleChangeInsurance(e, 'policyId', 'secondary')} value={secondaryPolicyID}
                                />
                            </div>
                        </div>
                        </div>
                    </>
                    : ''}
                {tertiaryInsuranceList.length > 0 ?
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.tertiaryPriority")}</label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                            <SelectInput data={tertiaryInsuranceList} value={tertiaryInsurance} name={"tertiaryInsuranceList"} onValueChange={(e) => onHandleChangeInsurance(e, 'insurance', 'tertiary')} label={i18n.t("searchClaims.professionalClaims.billingInf.insurance")} needUniqueOptions={true} />
                        </div>
                        <div className="col-3 pl-0">
                            <div className="form-group ">
                                <TextInput type="text" name={"tertiaryPolicyId"} label={i18n.t("searchClaims.professionalClaims.billingInf.policyId")}
                                    onValueChange={(e) => onHandleChangeInsurance(e, 'policyId', 'tertiary')} value={tertiaryPolicyID}
                                />
                            </div>
                        </div>
                        </div>
                    </>
                    : ''}
                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.billingInf.otherBillingInfo")}</label>
                        </div>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <SelectInput data={YesOrNo} name="accept_assignments" value={Mode_Of_Save === 'Save' ? BillingInfoData?.accept_assignments : billingInfoDetails?.accept_assignments} onValueChange={onHandleChange}
                            label={i18n.t("searchClaims.professionalClaims.billingInf.acceptAssignment")} selectOptionRemove={true} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={relationList} name="insurer_relation" value={Mode_Of_Save === 'Save' ? BillingInfoData?.insurer_relation : billingInfoDetails?.insurer_relation} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.patientInsurer")} selectOptionRemove={true} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={YesOrNo} name="patient_signature" value={Mode_Of_Save === 'Save' ? BillingInfoData?.patient_signature : billingInfoDetails?.patient_signature} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.patientSignature")} selectOptionRemove={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <SelectInput data={claimFrequencyList} name="claim_fequency" value={Mode_Of_Save === 'Save' ? BillingInfoData?.claim_fequency : billingInfoDetails?.claim_fequency} onValueChange={onHandleChange}
                            label={i18n.t("searchClaims.professionalClaims.billingInf.claimFrequency")} includeId={true} prefixName={true} />
                    </div>
                    {Number(Mode_Of_Save === 'Save' ? BillingInfoData?.claim_fequency : billingInfoDetails?.claim_fequency)!== CLAIM_FREQUENCY_ORIGINAL_CLAIM_ID ?
                        <div className="col-3 pl-0">
                            <div className="form-group">
                                <TextInput type="text" name="claim_reference"
                                    required={(Mode_Of_Save === 'Save' ? BillingInfoData?.claim_fequency : billingInfoDetails?.claim_fequency) ? true : false}
                                    label={i18n.t("searchClaims.professionalClaims.billingInf.claimRef")}
                                    onValueChange={onHandleChange} value={Mode_Of_Save === 'Save' ? BillingInfoData?.claim_reference : billingInfoDetails?.claim_reference}
                                />
                            </div>
                        </div>
                        :
                        " "
                    }
                    <div className="col-3 pl-0">
                        <SelectInput data={YesOrNo} name="insured_signature" value={Mode_Of_Save === 'Save' ? BillingInfoData?.insured_signature : billingInfoDetails?.insured_signature} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.billingInf.insureSignature")} selectOptionRemove={true} />
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default BillingInfo