import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../SearchClaims/service";
import service1 from '../../dictionaries/accidentTypes/service';
import { getStorage } from "../../../utilities/browserStorage";
import format from "date-fns/format";
import { DEFAULT_CLAIM_ACCIDENT_TYPE } from "../../../utilities/dictionaryConstants";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";
const initialState = {
    BillingInfoData: {
    },
    additionalclaimData: {
    },
    serviceLineData: {
        procedures: [{
            id: '',
            dos_from: "",
            dos_to: "",
            pos_id: "",
            pos_id_List: [],
            tos_id: "",
            tos_id_List: [],
            emg: 2,
            cpt_hcpcs: "",
            cpt_hcpcsList: [],
            m1_id: [],
            m2_id: "",
            m3_id: "",
            m4_id: "",
            m1List: [],
            m2List: [],
            m3List: [],
            m4List: [],
            dx_pointers: "",
            fee: "0.00",
            fee_units: "1",
            charges: "0.00",
            cpt_epsdt: 2,
            proced_description: "",
            line_note: "",
            drug_code: "",
            drug_unit_type: "",
            drug_unit_value: "",
            measurement_unit_type: "",
            measurement_unit_value: "",
            anesthesia: "",
            responsibility_type: 1,
            payment_doneList: []
        }]
    },
    commonData: {
    },
    loading: false,
    patientSelectedData: [],
    patientAdvSearch: [],
    selectPatientData: '',
    patientPK: '',
    saveMode: "Edit",
    removedData: false,
    infoLoaded: false,
    serviceLoaded: false,
    additionalLoaded: false,
    DXPointerFlagSave:false
}

//getting patient details on patient pk
export const getPatientPKDetails = createAsyncThunk(
    "claim/patient-pk-details", async (payload) => {
        let billingData = {};
        let result = await service.GetPatientDetails(payload);
        if (result.data.service_location.length > 0) {
            if (result.data.service_location[0].location_type === 'practice') {
                billingData = {
                    ...result.data,
                    service_location: {
                        id: result.data.service_location[0].Id, location_type: "practice"
                    },
                    service_location_value: `${result.data.service_location[0].Id}||practice`,
                    location_type: "practice",
                    payer_info: [],
                    id: "",
                    patient_signature: 1,
                    insured_signature: 1,
                    claim_fequency: 1,
                    billing_provider: result.data.rendering_provider,
                    accept_assignments: result.data.accept_assignemnt ? result.data.accept_assignemnt : ""
                }
            }
            else if (result.data.service_location[0].location_type === 'facility') {
                billingData = {
                    ...result.data,
                    service_location: {
                        id: result.data.service_location[0].Id, location_type: "facility"
                    },
                    service_location_value: `${result.data.service_location[0].Id}||facility`,
                    payer_info: [],
                    id: "",
                    patient_signature: 1,
                    insured_signature: 1,
                    claim_fequency: 1,
                    billing_provider: result.data.rendering_provider,
                    accept_assignments: result.data.accept_assignemnt ? result.data.accept_assignemnt : ""
                }
            }
        }
        else {
            billingData = {
                ...result.data,
                payer_info: [],
                id: "",
                patient_signature: 1,
                insured_signature: 1,
                claim_fequency: 1,
                billing_provider: result.data.rendering_provider,
                accept_assignments: result.data.accept_assignemnt ? result.data.accept_assignemnt : ""
            }
        }

        return {
            BillingInfoData: billingData,
        }
    }
)

export const getPatientDXPDetails = createAsyncThunk(
    "claim/patient-service-details", async (payload) => {
        const result = await service.GetPatientDXCPTDetails(payload);
        let dx_pointers = '';
        const dxMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
        for (let index = 0; index < 12; index++) {
            const dx = result.data['diagnosis' + (index + 1)];
            if (dx) {
                dx_pointers += dxMap[index];
            }
        }
        let newArray = [];
        const propertiesToAssign = ["id", "dos_from", "dos_to", "pos_id", "pos_id_List","tos_id","tos_id_List", "emg", "cpt_hcpcs", "cpt_hcpcsList", "m1_id", "m1List", "m2_id", "m2List", "m3_id", "m3List", "m4_id", "m4List", "dx_pointers", "fee", "fee_units", "charges", "cpt_epsdt", "responsibility_type", "proced_description", "line_note", "drug_code", "drug_unit_type", "drug_unit_value", "measurement_unit_type", "measurement_unit_value", "anesthesia", "responsibility_type", "payment_doneList","claim_status", "claim_sub_status"];
        // set the default cpts and other variable
        for (let index = 0; index < 12; index++) {
            if (!result.data['cpt' + (index + 1)]) continue;
            newArray[index] = {};
            propertiesToAssign.forEach((property) => {
                newArray[index][property] = "";
            });
            newArray[index]["cpt_hcpcs"] = result.data["cpt" + (index + 1)];
            newArray[index]["cpt_hcpcsList"] = [{
                id: result.data["cpt" + (index + 1)],
                name: result.data["cpt" + (index + 1) + "_name"],
            },];
            
            for (let j = 1; j <= 4; j++){
                if (result.data["cpt" + (index + 1) + "_m" + (j) + "_id"] &&
                    result.data["cpt" + (index + 1) + "_m" + (j) + "_value"].length > 0) {
                    newArray[index]["m" + (j) + "_id"] = result.data["cpt" + (index + 1) + "_m" + (j) + "_id"];
                    newArray[index]["m" + (j) + "List"] = result.data["cpt" + (index + 1) + "_m" + (j) + "_value"];
            }
            }

            if(result.data["pos" + (index + 1) + "_id"] == null){
                if(result.data["location_pos_id"]){
                    newArray[index]["pos_id"] = result.data["location_pos_id"]; 
                    newArray[index]["pos_id_List"] = result.data["location_pos_List"];
                } else {
                    newArray[index]["pos_id"] = []; 
                    newArray[index]["pos_id_List"] = []
                }
               
            } else {
                newArray[index]["pos_id"] = result.data["pos" + (index + 1) + "_id"];
                newArray[index]["pos_id_List"] = result.data["pos" + (index + 1) + "_id"] ? result.data["pos" + (index + 1) + "_List"] : [];
            }
            
            newArray[index]["tos_id"] = result.data["tos" + (index + 1) + "_id"];
            newArray[index]["tos_id_List"] = result.data["tos" + (index + 1) + "_id"] ? result.data["tos" + (index + 1) + "_List"] : [];
            newArray[index]["fee_units"] = result.data["default_unit" + (index + 1)]
            newArray[index]["dx_pointers"] = dx_pointers;
            newArray[index]["cpt_epsdt"] = 2;
            newArray[index]["fee"] = result.data["default_fee" + (index + 1)]
            newArray[index]["responsibility_type"] = result.data['responsibility_type'];
            newArray[index]["charges"] = (parseFloat(result.data["default_unit" + (index + 1)]) * parseFloat(result.data["default_fee" + (index + 1)]))
        }
        let serviceLineData = {
            ...result.data,
            "procedures": newArray
        }

        return serviceLineData;
    }
)



export const getPatientAdditionalDetails = createAsyncThunk(
    "claim/patient-additional-details", async (payload) => {
        const result1 = await service.GetPatientAccidentInfo(payload.patientPk);
        let result2 = await service.GetPracticeBasicData(getStorage('practice'));
        let accidentTypeList = await service1.ListAccidentTypes(DEFAULT_PAGING_SIZE, 0, payload.claimPk ?? "");
        const data = {
            ...result1.data,
            clia_number: result2.data.clia,
            accident: result1.data.accident_type ? result1.data.accident_type : (accidentTypeList?.data?.find((item) => item.name === DEFAULT_CLAIM_ACCIDENT_TYPE)?.id || null),
            state: result1.data.accident_location
        }
        return data;
    }
)


export const claimSaveManagementSlice = createSlice({
    name: 'claimSaveManagement',
    initialState,
    reducers: {
        addNewClaim: (state, actions) => {
            const { commonData, patientSelected, patientAdvSearchData, selectPatient } = actions.payload;
            let data = {
                ...commonData,
                patient_dob: format(new Date(patientSelected[0]?.dob), 'yyyy-MM-dd'),
                "claim_status": 54,
                "claim_sub_status": 156,
                claimPK: 'add-new',
                additionalInfoPK: 'add-new',
                billingInfoPK: 'add-new',
                proceduresPKS: 'add-new',
                diagnosis_pk: 'add-new',
            }
            state.patientAdvSearch = patientAdvSearchData;
            state.patientSelectedData = patientSelected;
            state.selectPatientData = selectPatient;
            state.commonData = data;
            state.claimInitiState = 'Save';
            state.saveMode = 'Save'
            state.infoLoaded = false;
            state.removedData = false;
        },
        /**
        * updating claim basic data 
        * @param {*} state 
        * @param {*} actions 
        */
        updateBasicSaveData: (state, actions) => {
            const { name, value } = actions.payload;
            //getting common data from claimInfo array and updating new value
            let values = {
                ...state.commonData,
                [name]: value
            }

            if (name === "authorization_referral") { 
                values = {
                    ...values,
                    "identifier": ""
                }
            }

            state.commonData = values;
        },
        /**
         * 
         * @param {*} state 
         * @param {*} actions 
         */
        /**
              * updating claim basic data 
              * @param {*} state 
              * @param {*} actions 
              */
        updatePayerInsurance: (state, actions) => {
            const newarrInsurance = state.BillingInfoData.payer_info;
            const { arrInsure } = actions.payload
            if (newarrInsurance.length == 0) {
                newarrInsurance.push({ ...arrInsure, Id: arrInsure.practice_insurance_id })
            }
            else {
                const idExists = newarrInsurance.some(item => item.practice_insurance_id === arrInsure.practice_insurance_id);
                if (!idExists) {
                    newarrInsurance.push({ ...arrInsure, Id: arrInsure.practice_insurance_id })
                }
            }
            const values = {
                ...state.BillingInfoData,
                payer_info: newarrInsurance
            }
            state.BillingInfoData = values;
        },
        /**
       * updating common details of additional details tab
       * @param {*} state 
       * @param {*} actions 
       */
        updateAdditionalSaveDetails: (state, actions) => {
            const { name, value } = actions.payload;
            let addionalData = {
                ...state.additionalclaimData,
                [name]: value
            }

            if (name === "ambulance_claim" && value === 2) {
                addionalData = {
                    ...addionalData,
                    "patient_admitted_hospital": false,
                    "patient_moved_by_stretcher": false,
                    "patient_unconscious_shock": false,
                    "patient_transported_emergency": false,
                    "patient_physically_restrained": false,
                    "patient_visible_haemorrhaging": false,
                    "ambulance_service_medical_necessary": false,
                    "patient_confined_bed_chair": false,
                    "weight": "",
                    "pickup_location_data": {},
                    "dropoff_location_data": {},
                    "distance": "",
                    "transport_reason": "",
                    "transport_code": "",
                    "certification": "",
                    "stretcher_reason": "",
                    "pickup_location": "",
                    "dropoff_location": ""
                }
            }
            state.additionalclaimData = addionalData;
        },

        /**
        * updating billing info basic details
        * @param {*} state 
        * @param {*} actions 
        */
        updateBillingInfoSaveDatas: (state, actions) => {
            let values = {};
            const { name, value } = actions.payload;
            //saving given detail to billing info data
            if (name === "practice_location" || name === "facility_location") {
                let service_location_id = value.split("||")[0];
                let service_location_type = value.split("||")[1];
                values = {
                    ...state.BillingInfoData,
                    [name]: parseInt(service_location_id),
                    service_location_value: value,
                    service_location: { id: service_location_id, location_type: service_location_type },
                    location_type: service_location_type
                }
            }
            else {
                values = {
                    ...state.BillingInfoData,
                    [name]: value
                }
            }

            state.BillingInfoData = values;
        },

        removeAllDetails: (state, actions) => {
            state.commonData = {}
            state.BillingInfoData = initialState.BillingInfoData;
            state.additionalclaimData = initialState.additionalclaimData;
            state.serviceLineData = initialState.serviceLineData;
            state.infoLoaded = false;
            state.additionalLoaded = false;
            state.loading = false;
            state.patientSelected = [];
            state.patientSelectedData = [];
            state.selectPatientData = "";
            state.selectPatient = '';
            state.patientPK = '';
            state.selectPatientData = "";
            state.saveMode = actions.payload === 1 ? 'Edit' : 'Save';
            state.removedData = actions.payload === 2 ? true : false;
            state.serviceLoaded = false;
        },

        /**
        *updating procedure data inside service line details 
        * @param {*} state 
        * @param {*} actions 
        */
        updateProcedureSaveData: (state, actions) => {
            const { procedureArray } = actions.payload
            const data = {
                ...state.serviceLineData,
                "procedures": procedureArray
            }
            state.serviceLineData = data;
            state.DXPointerFlagSave = false;
        },

        /**
        *update diaganosis variables 
        * @param {*} state 
        * @param {*} actions 
        */
        updateDignosisSaveVariables: (state, actions) => {
            let values = {};
            const { name, valueList } = actions.payload
            if (valueList.length > 0) {
                values = {
                    ...state.serviceLineData,
                    [name]: parseInt(valueList[0].label),
                    [name + "_" + "desc"]: valueList[0].description ? valueList[0].description : "",
                    [name + "_" + "value"]: valueList[0].name,
                }
                state.serviceLineData = values;
            }
            else {
                values = {
                    ...state.serviceLineData,
                    [name]: "",
                    [name + "_" + "desc"]: "",
                    [name + "_" + "value"]: "",
                }
                state.serviceLineData = values;
            }
        },

        /**
        * 
        * @param {*} state 
        * @param {*} actions 
        */
        udpateSaveICD: (state, actions) => {
            let values = {};
            const { name, value, newArray } = actions.payload
            values = {
                ...state.serviceLineData,
                procedures: newArray,
                [name]: value
            }
            state.serviceLineData = values;
        },

        /**
         * updating payer info details in billing info tabs
         * @param {*} state 
         * @param {*} actions 
         */
        updatePayerSaveDetails: (state, actions) => {
            let newarrInsurance = [];
            const { arrInsure } = actions.payload
            //updating array with Id of policy
            arrInsure.forEach(item => {
                newarrInsurance.push({ ...item, Id: item.practice_insurance_id })
            });
            const values = {
                ...state.BillingInfoData,
                payer_info: newarrInsurance
            }
            state.BillingInfoData = values;

        },

        updateDiagnosisDataOnRemoveSave: (state, actions) => {
            let values = {}
            const { name, value } = actions.payload
            let icdListForUpdate = { ...state.serviceLineData }
            values = {
                ...icdListForUpdate,
                [name]: value,
                [name + "_" + "desc"]: value,
                [name + "_" + "value"]: value,
            }
            icdListForUpdate = values;
            let keys = Object.keys(icdListForUpdate);
            let index = keys.indexOf(name);
            let result = [];
            for (let i = index; i < keys.length; i++) {
                if (icdListForUpdate[keys[i]] !== null && icdListForUpdate[keys[i]] !== '' && (Array.isArray(icdListForUpdate[keys[i]]) ? icdListForUpdate[keys[i]].length : true)) {
                    result.push(keys[i]);
                }
            }

            if (result.length > 0) {
                result.forEach((icdToSwitchSlot) => {
                    if (icdToSwitchSlot.startsWith('diagnosis') && icdToSwitchSlot.length <= 11) {
                        let availableSlotInICDList1 = null;
                        for (let i = 1; i <= 12; i++) {
                            const key = `diagnosis${i}`;
                            if (icdListForUpdate[key] === "") {
                                availableSlotInICDList1 = key;
                                break;
                            }
                        }
                        let tempStoreICDToShift = icdListForUpdate[icdToSwitchSlot]
                        let tempStoreICDDescription = icdListForUpdate[`${icdToSwitchSlot}_desc`];
                        let tempStoreICDValue = icdListForUpdate[`${icdToSwitchSlot}_value`];
                        //shift the values
                        icdListForUpdate = {
                            ...icdListForUpdate,
                            [availableSlotInICDList1]: tempStoreICDToShift,
                            [availableSlotInICDList1 + '_desc']: tempStoreICDDescription,
                            [availableSlotInICDList1 + '_value']: tempStoreICDValue
                        }

                        // remove the shifted value
                        icdListForUpdate = {
                            ...icdListForUpdate,
                            [icdToSwitchSlot]: '',
                            [`${icdToSwitchSlot}_desc`]: "",
                            [`${icdToSwitchSlot}_value`]: "",
                        }
                    }
                })
            }
            state.serviceLineData = icdListForUpdate;
        },

        updateAuthIdentfier: (state) => {
            //getting common data from claimInfo array and updating new value
            let values = {
                ...state.commonData,
                "identifier": "",
                "authorization_referral":""

            }
            state.commonData = values;
        },
        /**
         * changes DX pinter added or removed
         * @param {*} state 
         */
        updateDXpointerFlag: (state) => {
            state.DXPointerFlagSave = true;
        },
        /**
         * setting claim save data to initial state
         */
        setClaimSavetoInitialState: () => initialState,

    },

    extraReducers: (builder) => {
        //get patient details api pending state
        builder.addCase(getPatientPKDetails.pending, (state) => {
            state.loading = true;
        })
        //get patientdetails api success response handled here
        builder.addCase(getPatientPKDetails.fulfilled, (state, actions) => {
            state.loading = false;
            state.BillingInfoData = actions.payload.BillingInfoData;
            state.claimInitiState = 'Save';
            state.infoLoaded = true;
        })
        //get service line data details api rejected state
        builder.addCase(getPatientPKDetails.rejected, (state) => {
            state.loading = false;
        })
        //get service line data details api pending state
        builder.addCase(getPatientDXPDetails.pending, (state) => {
            state.loading = true;
        })
        //get service line data api success response handled here
        builder.addCase(getPatientDXPDetails.fulfilled, (state, actions) => {
            state.loading = false;
            state.serviceLineData = actions.payload;
            state.claimInitiState = 'Save';
            state.serviceLoaded = true;
        })
        //get patient details api rejected state
        builder.addCase(getPatientDXPDetails.rejected, (state) => {
            state.loading = false;
        })
        //get patient additional details pending state
        builder.addCase(getPatientAdditionalDetails.pending, (state) => {
            state.loading = true;
        })
        //get patient additional data api success response handled here
        builder.addCase(getPatientAdditionalDetails.fulfilled, (state, actions) => {
            state.loading = false;
            state.additionalclaimData = actions.payload;
            state.claimInitiState = 'Save';
            state.additionalLoaded = true;
        })
        //get patient additional details api rejected state
        builder.addCase(getPatientAdditionalDetails.rejected, (state) => {
            state.loading = false;
        })

    }
})

export const {
    addNewClaim,
    updateBasicSaveData,
    updateAdditionalSaveDetails,
    removeAllDetails,
    updateBillingInfoSaveDatas,
    updateProcedureSaveData,
    updateDignosisSaveVariables,
    udpateSaveICD,
    updateDiagnosisDataOnRemoveSave,
    updatePayerSaveDetails,
    updatePayerInsurance,
    setClaimSavetoInitialState,
    updateAuthIdentfier,
    updateDXpointerFlag
} = claimSaveManagementSlice.actions;
export default claimSaveManagementSlice.reducer;
