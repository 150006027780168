import api from '../../../service/api'

const GetListClaimFrequency = async(pageSize,page, claimPK) =>{
	let path = 'super-admin/claim-frequency/?page_size='+ pageSize +'&page='+ page;
	if (claimPK)
		path += '&opened_claim_pk='+ claimPK;
		return api.get(path);
}

const AddClaimFrequency = async(data) =>{
	let path = 'super-admin/claim-frequency/'
	return api.post(path,data);
}
const GetClaimFrequency= async(id) =>{
	let path = 'super-admin/claim-frequency/'+id
	return api.get(path);
}
const DeleteClaimFrequency= async(id) =>{
	let path = 'super-admin/claim-frequency/'+id
	return api.destroy(path);
}

const UpdateClaimFrequency= async(id,data) =>{
	let path = 'super-admin/claim-frequency/'+id
	return api.put(path,data);
}

export default {GetListClaimFrequency,AddClaimFrequency,GetClaimFrequency,DeleteClaimFrequency,UpdateClaimFrequency}