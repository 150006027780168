import api from '../../../service/api';

const ListReferrals = async(pageSize, page, isAdminModule, practicePK) =>{
	let path = null;
	if (isAdminModule === 'false')
		path = 'practice/referral-source/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	else
		path = 'super-admin/referral-source/?page_size='+pageSize+'&page='+page;
	return api.get(path);
}

const AddReferral = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/referral-source/';
	else
		path = 'super-admin/referral-source/';
	return api.post(path,data);
}
const GetReferral = async(referralId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/referral-source/' + referralId;
	else
		path = 'super-admin/referral-source/' + referralId;
	return api.get(path);
}

const UpdateReferral = async(referralId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/referral-source/' + referralId;
	else
		path = 'super-admin/referral-source/' + referralId;
	return api.put(path,data);
}

const DeleteReferral = async(referralId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/referral-source/' + referralId;
	else
		path = 'super-admin/referral-source/' + referralId
	return api.destroy(path);
}

export default {ListReferrals, AddReferral, GetReferral, UpdateReferral, DeleteReferral}