import i18n from "../../../utilities/i18n";

const TableHeadData=[
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.diagnosisCodes"),
        "type": "string",
        "width": "small",
    },
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.description"),
        "type": "string",
        "width": "medium",
    },
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.addICDfromPatientHistoryModalToClaim"),
        "type": "string",
        "width": "medium"
    }
]

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        }, 
        {
            "id": "",
            "name": "desc",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "plusButton",
            "align": "center",
            "width": "small",
        }   
    ]
]

export const PatientHistoryTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}


const TableHeadDataCPT = [
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.cpt"),
        "type": "string",
        "width": "small",
    },
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.description"),
        "type": "string",
        "width": "medium",
    },
    {
        "name": i18n.t("searchClaims.professionalClaims.serviceLine.addICDfromPatientHistoryModalToClaim"),
        "type": "string",
        "width": "medium"
    }
]

export const TableBodyDataCPT = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "desc",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "plusButton",
            "align": "center",
            "width": "small",
        }
    ]
]

export const PatientHistoryTableDataCPT = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadDataCPT,
    "tableBodyData": TableBodyDataCPT
}