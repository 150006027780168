import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form} from "react-bootstrap";
import i18n from "../../utilities/i18n";
import {
  ROUTE_PRACTICES,
  LOGIN_ROUTE,
  ROUTE_FORCE_RESET_PASSWORD,
  FEATURE_FLAG,
} from "../../utilities/staticConfigs";
import "./style.css";
import TextInput from "../commons/input/input";
import service from "./service";
import { getStorage, setStorage } from "../../utilities/browserStorage";
import Notify from "../commons/notify";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import CustomizedSmallDialogs from "../modalWindowComponent/CustomisedSmallDialog";
import { useDispatch } from "react-redux";
import { setInitialState } from "../../store/patient/patientInfoSlice";
import { setOtherBillingInitalState } from "../../store/patient/PatientBillingInfo/patientBillingSlice";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import packageJson from '../../../package.json'
import { extractPermissions, getPathToRouteOnLoginToAdminModule, getPathToRouteOnLoginToPractice } from "../../utilities/commonUtilities";
import Label from "../commons/Label";
import logo from '../../assets/images/AIMA_Logo.png'
import LoadingContext from "../../container/loadingContext";  

function Copyright() {
  return (
    <Typography color="textSecondary" align="center" style={{fontFamily: 'Lato', fontSize: 11, fontWeight: 600 }}>
      Copyright AIMA Business & Medical Support LLC © All Rights Reserved
      {'.'}
      {" "}Version {packageJson.version}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    flexDirection:"row"
  },
  image: {
    backgroundImage: `url(https://aimapms-staging-public.s3.amazonaws.com/Login-leftside.webp)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
  //margin: theme.spacing(20, 5),
    height:'60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
   
  },
  avatar: {
    margin: theme.spacing(1),
    '&.MuiAvatar-root': {
      width: 'auto',
      borderRadius: '0px',
      height: '150px'
    }
  },
  form: {
    width: '55%', // Fix IE 11 issue.
    marginTop: 20,
   
  },
  submit: {
    margin: theme.spacing(3, 0, 8),
    fontFamily: 'Lato',
    fontSize:'medium',
    backgroundColor: '#1479BB',
    color: '#fff',
    textTransform: 'none',
    height:55,
    '&:hover': {
      backgroundColor: '#1479BB',
      boxShadow: 'none',
      color: '#fff',
     
    },
  },
  login: { fontFamily: 'Lato', fontSize: 20, fontWeight: 600},
  center: { textAlign: 'center' },
 
  copyright:{
 
    justifyContent:'center',
    alignItems:'center',
     width:'100%',
     //paddingTop:'60px'
}

}));

const LoginComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loginInput, setLoginInput] = useState({
    username: '', password: ''
  })
  const [usernameClass, setUsernameClass] = useState("");
  const [passwordClass, setPasswordClass] = useState("");
  const [loginError, setLoginError] = useState("");
  const [botTrapText, setBotTrapText] = useState("");

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const [notifyPrimaryText, setNotifyPrimaryText] = useState("")

  const [showUserNameModalWindow, setshowUserNameModalWindow] = useState(false);
  const [PasswordResetHeader, setPasswordResetHeader] = useState("");
  const [resetUserName, setResetUserName] = useState("");
  const setShowLoadingBar = useContext(LoadingContext); 

  const onValueChange = (fieldItem) => {
    let inputVal = fieldItem.target.value;
    if (fieldItem.target.name === "txtUsername") {
      setLoginInput({ ...loginInput, username: inputVal });
      if (inputVal.trim() !== "") setUsernameClass("");
    } else if (fieldItem.target.name === "txtPassword") {
      setLoginInput({ ...loginInput, password: inputVal });
      if (inputVal.trim() !== "") setPasswordClass("");
    } else if (fieldItem.target.name === 'botTrapText') {
      setBotTrapText(inputVal);
    }
    setLoginError("");
  };

  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem("passwordExpired");
    localStorage.removeItem("userID");
    localStorage.removeItem("routeState");
    const cookies = document.cookie.split(';');
    const isUserSessionOut = cookies.find((cookie) => cookie.trim().startsWith('isSessionOut='));
    if (isUserSessionOut && isUserSessionOut.split('=')[1] === 'true') {
      showNotifyWindow(
        'show',
        'error',
        i18n.t("login.sessionExpiryText")
      );
      document.cookie = 'isSessionOut=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }, []);


  useEffect(() => {
    const userGroupData = JSON.parse(getStorage("userData") || "{}");
    if (userGroupData?.accessToken) {
      if (getStorage("isAdminModule")==='false') {
        history.push(getPathToRouteOnLoginToPractice(JSON.parse(getStorage("practiceModuleMenuSet")||"[]")))
      }
      else {
        history.push(getPathToRouteOnLoginToAdminModule(JSON.parse(getStorage("adminModuleMenuSet")||"[]")))
      }
     }
  },[])


  const handleLogin = (e) => {
    e.preventDefault();
    let retValue = true;
    if (botTrapText) return;
    if (loginInput.username.trim() === "") {
      setUsernameClass("input-error");
      retValue = false;
    }
    if (loginInput.password.trim() === "") {
      setPasswordClass("input-error");
      retValue = false;
    }

    if (!retValue) return retValue;
    
    setShowLoadingBar(true);

    service
      .UserLogin(loginInput)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 426) {
          setStorage("passwordExpired", true);
          setStorage("userID", response.data.user_id);
          history.push(ROUTE_FORCE_RESET_PASSWORD);
        }
        if (response.data.status === "success") {
          let loginResponse = response.data;
          
          // Route user to admin module when user has no practices and the user type is superAdmin or aimaStaffUser
          if (!loginResponse?.data?.default_practice && (loginResponse?.data?.user_type === 'staff_user' || loginResponse?.data?.user_type === 'superadmin')) {
            setStorage("isAdminModule", true);
          } else {
            setStorage("isAdminModule", false);
          }

          setStorage("lastActiveMenu", "");
          let resObject = {
            refreshToken: loginResponse.tokens.refresh,
            accessToken: loginResponse.tokens.access,
            user_id: loginResponse.data.user_id,
            username: loginResponse.data.username,
            group_id: loginResponse.data.group_id,
            group_name: loginResponse.data.group_name,
            profile_id: loginResponse.data.profile_id,
            profile_name: loginResponse.data.profile_name,
            profile_image: loginResponse.data.profile_image,
            avatar_name: loginResponse.data.avatar_name,
            company_name: loginResponse.data.company_name,
            practice: loginResponse.data.default_practice,
            custom_practice_id: loginResponse?.data?.custom_practice_id,
            unid: loginResponse.unid,
          };
          setStorage("super", JSON.stringify(resObject));
          setStorage("userData", JSON.stringify(resObject));
          setStorage("isAdmin", (loginResponse.data.is_superuser === true || loginResponse.data.user_type === "staff_user"));
          // Process the response to retrieve sidebar menu set and permission keys
          const practiceModuleMenuSet = [];
          const adminModuleMenuSet = [];

          loginResponse.data?.role_permissions?.practice_role_permissions?.forEach((item) => {
            // When the module item is of practice module
            if (item.is_super_admin_module === false) {
              // get the sub module lists for sidebar
              const subModules = item.sub_module?.map((subItem) => {
                if (subItem?.system_generated === false) {
                  return {
                    ...subItem.ui_integration_data
                  }
                } else {
                  return;
                }
              });

              // Pushing the module and its sub item to practice module menu along with a dummy sub item
              // that is needed for utility 
              practiceModuleMenuSet.push({
                ...item.ui_integration_data,
                sub_menu:
                  subModules?.length ? 
                  subModules : [] 
              });
            } else if (item.is_super_admin_module === true) {
              // get the sub module lists for sidebar
              const subModules = item.sub_module?.map((subItem) => {
                if (subItem?.system_generated === false) {
                  return {
                    ...subItem.ui_integration_data
                  }
                } else {
                  return;
                }
              })
              // Pushing the module and its sub item to admin module menu set along with a dummy sub item
              // that is needed for utility
              adminModuleMenuSet.push({
                ...item.ui_integration_data,
                sub_menu: subModules?.length ?
                  subModules : []
              });
            }
          });

          const practicePermissionCodes = extractPermissions(loginResponse.data?.role_permissions?.practice_role_permissions);
          const userPermissionCodes = (loginResponse.data?.role_permissions?.user_role_permissions || []).reduce((acc, item) => {
            if (item?.codename) {
              acc[item.codename] = true;
            }
            return acc;
          }, {});
          const permissionCodeSet = { ...practicePermissionCodes, ...userPermissionCodes };


         // This code sets, the menu's route to a sub-menu item's route if no matching route is found in sub-menu,
          //  offering a default route based on sub-menu content when conditions allow.
          practiceModuleMenuSet.forEach((menu, index) => {
            if (menu.sub_menu?.length && menu.route !== '#') {
              const defaultRoute = menu?.route;
              const defaultSubMenuAvailable = menu?.sub_menu?.findIndex((item) => item?.route === defaultRoute);
          
              if (defaultSubMenuAvailable === -1) {
                const defaultSubMenuToSet = menu?.sub_menu?.find((item) => item?.route !== '#')?.route ?? menu?.route;
                
                if (defaultSubMenuToSet) {
                  practiceModuleMenuSet[index].route = defaultSubMenuToSet;
                }
              }
            }
          });

          practiceModuleMenuSet.forEach((item, index) => {
            if (!item?.sub_menu[0]) {
              practiceModuleMenuSet[index].sub_menu = []
            }
          })

          adminModuleMenuSet.forEach((item, index) => {
            if (!item?.sub_menu[0]) {
              adminModuleMenuSet[index].sub_menu = []
            }
          })

          if (FEATURE_FLAG == 1) {
            // Find the index of the item with name 'Charges'
            const chargesIndex = practiceModuleMenuSet.findIndex((item) => item.name === 'Charges');

            // If the item is found, update its sub_menu
            if (chargesIndex !== -1) {
              practiceModuleMenuSet[chargesIndex] = {
                ...practiceModuleMenuSet[chargesIndex],
                sub_menu: [
                  {
                    "name": "Search Claims (Beta)",
                    "page": "",
                    "route": "/claims/search-claims-1",
                    "parentName": "Claims"
                  },
                  ...practiceModuleMenuSet[chargesIndex].sub_menu,
                ]
              };
            }
          }


          setStorage('practiceModuleMenuSet', JSON.stringify(practiceModuleMenuSet));
          setStorage('adminModuleMenuSet', JSON.stringify(adminModuleMenuSet));
          setStorage('permissionCodeSet', JSON.stringify(permissionCodeSet));

          if (!loginResponse.data.default_practice) {
            localStorage.removeItem("practice");
            setStorage("practiceName", "");
            if (loginResponse?.data?.user_type === 'staff_user' || loginResponse?.data?.user_type === 'superadmin' ) {
              setStorage("isAdminModule", true);
              setStorage("isAdmin", true);
              if (loginResponse.data.is_superuser) {
                setStorage("isSuperUser", true);
              }
              // Route the user dynamically to the first page they have access to in admin module
              history.push(getPathToRouteOnLoginToAdminModule(adminModuleMenuSet))
            } else if (loginResponse?.data?.user_type !== 'staff_user' && loginResponse?.data?.user_type !== 'superadmin') {
              setStorage("isSuperUser", false);
              history.push(ROUTE_PRACTICES);
            }
          } else {
            setStorage("practice", loginResponse.data.default_practice);
            setStorage("custom_practice_id", loginResponse.data.custom_practice_id);
            setStorage(
              "practiceName",
              loginResponse.data.default_practice_name
            );
            history.push(getPathToRouteOnLoginToPractice(practiceModuleMenuSet))
          }
          dispatch(setInitialState());
          dispatch(setOtherBillingInitalState());
        }
        if (response.data.error_message === "account_locked_due_three_failed_attempt") {
          setLoginError("Account has been locked out, contact administrator or retry after 30 mins.");
          showNotifyWindow("show", "error", 'Your account has been locked, please either wait for 30 mins to retry or contact admin to unlock the accout manually.', 6000, "Account Locked.");
        }
        if (response.data.error_message === "password_incorrect") {
          setLoginError(
            "Incorrect password. You have " + `${response.data.remaining_attempt}` + " remaining attempts.");
        }
        if (response.data.error_message === 'user_not_exists') {
          setLoginError("Not registered, Please contact administrator.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 408) {
          localStorage.removeItem("practice");
          localStorage.removeItem("practiceName");
          localStorage.removeItem("userData");
          localStorage.removeItem("isAdminModule");
          history.push(LOGIN_ROUTE);
        }
        showNotifyWindow(
          'show',
          'error',
          "Unexpected error occured please try again"
        );
        setShowLoadingBar(false)
      });
  };


  function showNotifyWindow(action, type, desc, age = 3000, primaryText) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
        setNotifyPrimaryText("");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
    setNotifyPrimaryText(primaryText)
  }

  function onShowUserName() {
    setshowUserNameModalWindow(true);
    setPasswordResetHeader(i18n.t("login.forgotPassword"));
  }

  function resetPasswordLinkSend() {
    if (!resetUserName) {
      showNotifyWindow("show", "danger", i18n.t("errorMessages.no_username"));
      return false;
    }
    let result = service.SendForgotPasswordLink({ username: resetUserName });
    result.then((response) => {
      if (response.status === 200) {
        showNotifyWindow(
          "show",
          "success",
          i18n.t("successMessages.email_success")
        );
      } else if (
        response.status === 400 &&
        response.data.username == "invalid_username"
      ) {
        showNotifyWindow(
          "show",
          "danger",
          i18n.t("errorMessages.invalid_username")
        );
      } else {
        showNotifyWindow("show", "danger", i18n.t("errorMessages.email_error"));
      }
    });
  }

  const onChangeResetUserName = (fieldItem) => {
    let inputVal = fieldItem.target.value;
    setResetUserName(inputVal);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Notify
        showNotify={showNotify}
        notifyPrimaryText={notifyPrimaryText}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={6} sx={{height:'100%',width:'100%'}}sm={8} md={5} component={Paper} elevation={6} square>
        <div style={{height:'20%'}}></div>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={logo} />
          <Typography component="h2" className={classes.login}>
            Sign in to your account
          </Typography>
          <form className={classes.form} onSubmit={handleLogin}>
            <div className="form-group padding-top10">
              <Label label="Username" />
              <TextField
                required={true}
                type="text"
                id="txtUsername"
                name="txtUsername"
                variant="outlined"
                onChange={onValueChange}
                className={usernameClass}
              />
            </div>
            <div className="form-group padding-top20">
              <Label label="Password" />
              <TextField
                variant="outlined"
                required={true}
                type="password"
                id="txtPassword"
                name="txtPassword"
                onChange={onValueChange}
                className={passwordClass}
              />
            </div>

            {/* The following element is used to detect bot activity. If a bot interacts with this element (e.g. typing), the login API call will not be dispatched. */}
            <div id="mslcns" style={{ display: 'none' }}>
              <Label label="Text" />
              <TextField
                variant="outlined"
                type="text"
                id="botTrapText"
                name="botTrapText"
                onChange={onValueChange}
                value={botTrapText}
              />
            </div>
            {/* *********** */}

            {loginError.length > 1 && (
              <div className="text-center text-red padding-bottom5">
                {loginError}{" "}
              </div>
            )}
            <Grid container style={{ margin: 10 }}>
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Grid item>
                <Link to="/" style={{ fontFamily: 'Lato' }} className="forgot-password"
                  onClick={onShowUserName}>
                  {i18n.t("login.forgotPassword")}
                </Link>
              </Grid>
            </Grid>
            <Button
              id="login-btn"
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              {i18n.t("login.form.login")}
            </Button>
          </form>   
      </div>
      <Grid item xs={12} style={{height:'20%',paddingTop:'100px'}}>
          <Box  className={classes.copyright}>
          <Copyright />
        </Box>
        </Grid>
      </Grid>
      
     
      <CustomizedSmallDialogs
        showModal={showUserNameModalWindow}
        onSaveFormData={resetPasswordLinkSend}
        header={PasswordResetHeader}
        type={"sendMail"}
        setShowModalWindow={setshowUserNameModalWindow}
      >
        <Form id="formSubmit" autoComplete="off">
          <div className="row">
            <div className="col">
              <div>
                Forgot your password? No worries. Enter the username
                registered with us and we will send you a link to the email
                associated with it.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-6  padding-top15">
              <TextInput
                type="text"
                id="resetUserName"
                name="resetUserName"
                onValueChange={onChangeResetUserName}
                label={i18n.t("userPages.associateUsers.labelUserID")}
                required={true}
                value={resetUserName}
              />
            </div>
          </div>
        </Form>
      </CustomizedSmallDialogs>
    </Grid>
  );
}

export default LoginComponent;