import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"; 
import FormControl from '@mui/material/FormControl';
import AutoSelect from '@mui/material/Autocomplete';  // DOCUMENTATION https://mui.com/components/autocomplete/
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@material-ui/core';
import "./style.css";
import { setCommonState } from '../store/CommonStateSlice';
import Label from '../components/commons/Label';

const icon = <Checkbox checked={false} />
const checkedIcon = <Checkbox checked={true} />

function MaterialMultiSelectSearch(props) {
    const tabSwitchFlag = useSelector((state) => state.commonStates.tabSwitchFlag);
    const dispatch = useDispatch();

    const [optionItems, setOptionItems] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [updateParent, setUpdateParent] = useState(0);

    useEffect(() => {
        let tmpOptions = [];
        if (props.options && Array.isArray(props.options)) {
            props.options.forEach(optItem => {
                tmpOptions.push({ id: optItem.id, name: String(optItem.name) })
            })
            setOptionItems(tmpOptions);
        }
        else {
            setOptionItems(tmpOptions);
        }
    }, [props.options]);

    useEffect(() => {
        setOptionValues(props.valuesArr ? props.valuesArr : []);
        let selArray = [];

        if (props.options) {
            props.options.forEach(element => {
                if (props.valuesArr.indexOf(element.id) > -1) {
                    selArray.push(element);
                }
            });
        }

        setSelectedValues(selArray);
    }, [JSON.stringify(props.valuesArr)]);


    useEffect(() => {
        // Check if props.onValueChange is provided and tabSwitchFlag is false.
        // If true, call props.onValueChange with optionValues; otherwise, set tabSwitchFlag to false.
        // This flag prevents invoking props.onValueChange with an empty array, which could lead to data loss when the user switches the tab.
        if (props.onValueChange && !tabSwitchFlag) {
            props.onValueChange(optionValues);
        } else {
            dispatch(setCommonState({ name: "tabSwitchFlag", value: false }));
        }
    }, [updateParent]);

    function handleChange(e, Id) {
        if (Id) {
            if (selectedValues && Array.isArray(selectedValues)) {
                if (selectedValues.some(item => Number(item.id) === Number(Id))) {
                    let newSelectedValues = selectedValues.filter(item => Number(item.id) !== Number(Id));
                    setSelectedValues(newSelectedValues);
                } else {
                    let newSelectedValues = [...selectedValues];
                    let newItem = optionItems.find(item => Number(item.id) === Number(Id));
                    newSelectedValues.push(newItem);
                    setSelectedValues(newSelectedValues);
                }
            } else {
                let newItem = optionItems.find(item => Number(item.id) === Number(Id));
                setSelectedValues([newItem]);
            }

            if (optionValues && Array.isArray(optionValues)) {
                if (optionValues.some(optItem => Number(optItem) === Number(Id))) {
                    let newOptArr = optionValues.filter(optItem22 => Number(optItem22) !== Number(Id));
                    setOptionValues(newOptArr);
                    setUpdateParent(updateParent + 1);
                } else {
                    let newOptArr = [...optionValues];
                    newOptArr.push(Id);
                    setOptionValues(newOptArr);
                    setUpdateParent(updateParent + 1);
                }
            } else {
                setOptionValues([Id]);
                setUpdateParent(updateParent + 1);
            }
        }
    }


    function handleOnChange(event, newValue) {
        setSelectedValues(newValue);
        let arrIds = newValue.map(item => { return item.id });
        setOptionValues(arrIds);
        setUpdateParent(updateParent + 1);
    }

    let labelString = props.label ? props.label : '';

    return (
        <div>
            <FormControl className="multi-select-search" >
                <Label label={labelString} />
                <AutoSelect
                    multiple
                    limitTags={1}
                    closeText=''
                    size="small"
                    id="demo-multiple-checkbox"
                    options={optionItems}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={''} variant="outlined" />
                    )}
                    renderOption={(props, option) =>
                        (
                            <div className={Array.isArray(optionValues) && optionValues.indexOf(option.id) > -1 ? 'multi-select-search-li-selected' : 'multi-select-search-li'}>
                            <li data-testid={`li-item-${option.id}`} className="max-height-35 " {...optionItems} onClick={e => handleChange(e, option.id)} style={{ cursor: 'pointer', hover: { backgroundColor: '#6c6c6c80 !important' } }}>
                                    <Checkbox
                                        id={"chk-box-" + option.id}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 1 }}
                                        checked={Array.isArray(optionValues) && optionValues.indexOf(option.id) > -1}
                                    
                                    />
                                    <div style={{ marginTop: '9px' }} >{option.name}</div>
                                <label htmlFor={"chk-box-" + option.id} aria-hidden={true} />
                                </li>
                            </div>
                        )
                    }
                    value={(selectedValues && Array.isArray(selectedValues)) ? selectedValues : []}
                    onChange={(event, newValue) => handleOnChange(event, newValue)}
                />

                {/* <div>{'selectedValues: '+JSON.stringify(selectedValues)}</div> */}

            </FormControl>
        </div>
    )
}

export default MaterialMultiSelectSearch; 
