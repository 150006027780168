import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import service from "../SearchClaims/service";
import service1 from '../../dictionaries/accidentTypes/service';
import { DEFAULT_CLAIM_ACCIDENT_TYPE } from "../../../utilities/dictionaryConstants";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";
import i18n from "../../../utilities/i18n";
import { decimalConverter } from "../../../utilities/commonUtilities";

const initialState = {
    claimInfos: [],
    serviceInfos: [],
    formWarningStatus: false,
    additionclaimInfos: [],
    formWarnData: [
        {
            "Patient": ""
        }
    ],
    BillingInfoData: {
    },
    additionalclaimData: {
    },
    serviceLineData: {
        procedures: []
    },
    commonData: {
    },
    loading: false,
    claimInitiState: "Save",
    infoLoaded: false,
    claimStatusFound: false,
    DXPointerFlagUpdate:false
}

//calling all tab api first on selection of claim id
export const getClaimData = createAsyncThunk(
    "claim/common-data",
    async (payload) => {
        let billingData = {};
        let claim_info = await service.GetGeneralClaim(payload);
        let billing_info = await service.GetBillingInfoClaim(claim_info.data?.pks?.billing_info_pk);
        const payer_info_db = billing_info.data.payer_info.map(item => ({
            practice_insurance_id: item.Id,
            policy_id: item.policy_id,
            priority: item.priority,
            Id: item.Id
        }));
        if (billing_info.data.facility_location === null) {
            billingData = {
                ...billing_info.data,
                payer_info: payer_info_db,
                service_location: { id: billing_info.data.practice_location, location_type: 'practice' },
                service_location_value: `${billing_info.data.practice_location}||practice`,
            }
        }
        else if (billing_info.data.practice_location === null) {
            billingData = {
                ...billing_info.data,
                payer_info: payer_info_db,
                service_location: { id: billing_info.data.facility_location, location_type: 'facility' },
                service_location_value: `${billing_info.data.facility_location}||facility`,
            }
        }
        return {
            commonData: { ...claim_info.data, claim_inactive: !claim_info.data.active },
            BillingInfoData: billingData,
            claimPK: payload,
            claimInitiState: "Edit",
            additionalInfoPK: claim_info.data?.pks?.additional_claim_info_pk,
            billingInfoPK: claim_info.data?.pks?.billing_info_pk,
            proceduresPKS: claim_info.data?.pks?.procedures_pks,
            diagnosis_pk: claim_info.data?.pks?.diagnosises_pk,
            locked: claim_info.data?.locked
        }
    }
)


//calling all tab api first on selection of claim id
export const getServiceLineData = createAsyncThunk(
    "claim/service_line",
    async (payload) => {
        let newArray = [];
        const result = await service.GetServiceLineClaim(payload.claimPK);
        if (result.data.procedures.length > 0) {
            result.data.procedures.forEach((item) => {
                let data = {
                    id: item.id,
                    dos_from: item.dos_from ? item.dos_from : "",
                    dos_to: item.dos_to ? item.dos_to : "",
                    patient_death_date: item.date_of_death ? item.date_of_death : "",
                    pos_id: item.pos,
                    pos_id_List: [item.pos ? { id: item.pos, name: item.pos_drop_down_name, label: item.pos_drop_down_name } : ""],
                    tos_id: item.tos,
                    tos_id_List: [item.tos ? { id: item.tos, name: item.tos_drop_down_name, label: item.tos_drop_down_name } : ""],
                    emg: item.emg,
                    cpt_hcpcs: item.cpt_hcpcs,
                    cpt_hcpcsList: item.cpt_hcpcs ? [{ "id": item.cpt_hcpcs, "name": item.cpt_value }] : [],
                    m1_id: item.m1 ? item.m1 : [],
                    m2_id: item.m2 ? item.m2 : [],
                    m3_id: item.m3 ? item.m3 : [],
                    m4_id: item.m4 ? item.m4 : [],
                    m1List: [item.m1 ? { "id": item.m1, "name": item.m1_value } : ""],
                    m2List: [item.m2 ? { "id": item.m2, "name": item.m2_value } : ""],
                    m3List: [item.m3 ? { "id": item.m3, "name": item.m3_value } : ""],
                    m4List: [item.m4 ? { "id": item.m4, "name": item.m4_value } : ""],
                    dx_pointers: item.dx_pointers,
                    fee: item.fee ? decimalConverter(item.fee,2) : 0.00,
                    fee_units: item.fee_units,
                    charges: item.charges,
                    cpt_epsdt: item.cpt_epsdt,
                    proced_description: item.proced_description,
                    line_note: item.line_note,
                    drug_code: item.drug_code ? item.drug_code.replace(/-/g, '') : "", // converted to number
                    drug_unit_type: item.drug_unit_type,
                    drug_unit_value: item.drug_unit_value,
                    measurement_unit_type: item.measurement_unit_type,
                    measurement_unit_value: item.measurement_unit_value,
                    anesthesia: item.anesthesia,
                    responsibility_type: item.responsibility_type,
                    payment_doneList: [item.payment_done ? item.payment_done : ""],
                    balance: item.balance ? Number(item.balance) : null,
                    claim_status : item.claim_status,
                    claim_sub_status: item.claim_sub_status,
                    insurance_message: item.insurance_message
                }
                newArray.push(data)
            })
        }

        const serviceLineData = {
            ...result.data,
            "procedures": newArray
        }
        return {
            serviceLineData: serviceLineData,
            claimPK: payload.claimPK,
            diganosisPK: payload.diagnosisesPK
        }
    }
)

//getting addition info data
export const getadditionalData = createAsyncThunk(
    "claim/additional_info",
    async (payload) => {
        const result = await service.GetAdditionalClaim(payload.additionalPK);
        let accidentTypeList = await service1.ListAccidentTypes(DEFAULT_PAGING_SIZE, 0, payload.claimPk ?? "");
        let data = {
            ...result.data,
            "accident": result.data.accident ? result.data.accident : (accidentTypeList?.data?.find((item) => item.name === DEFAULT_CLAIM_ACCIDENT_TYPE)?.id || null)
        }
        return {
            additionalclaimData: data,
            additionalPK: payload.additionalPK,
        }
    }
)

export const getFormWarings = createAsyncThunk(
    "claim/formwarings", async (payload) => {
        let claim_info = await service.GetGeneralClaim(payload);
        return claim_info.data
    }
)

export const claimManagementSlice = createSlice({
    name: 'claimManagement',
    initialState,
    reducers: {
        /**
         * updating claim basic data 
         * @param {*} state 
         * @param {*} actions 
         */
        updateBasicData: (state, actions) => {
            const { name, value, claimPK } = actions.payload;
            const { claimInfos } = current(state);
            const data = claimInfos.findIndex((item) => item.claimPK === claimPK);
            //getting common data from claimInfo array and updating new value
            if (data >= 0) {
                let values = {
                    ...state.claimInfos[data].commonData,
                    [name]: value
                }
                if (name == "claim_inactive" && value == true) {
                    values = {
                        ...values,
                        active: false
                    }
                }
                if (name == "authorization_referral") {
                    values = {
                        ...values,
                        "identifier": ""
                    }
                }
                state.claimInfos[data].commonData = values;
                state.commonData = state.claimInfos[data].commonData
            }
        },
        /**
         * updating billing info basic details
         * @param {*} state 
         * @param {*} actions 
         */
        updateBillingInfoDatas: (state, actions) => {
            let values = {};
            const { name, value, billingInfoPK } = actions.payload;
            const { claimInfos } = current(state);
            const data = claimInfos.findIndex((item) => item.billingInfoPK === billingInfoPK);
            //saving given detail to billing info data
            if (data >= 0) {
                if (name === "practice_location" || name === "facility_location") {
                    let service_location_id = value.split("||")[0];
                    let service_location_type = value.split("||")[1];
                    values = {
                        ...claimInfos[data].BillingInfoData,
                        [name]: parseInt(service_location_id),
                        service_location_value: value,
                        service_location: { "id": service_location_id, location_type: service_location_type }
                    }
                }
                else {
                    values = {
                        ...claimInfos[data].BillingInfoData,
                        [name]: value
                    }
                }

                state.claimInfos[data].BillingInfoData = values;
                state.BillingInfoData = state.claimInfos[data].BillingInfoData
            }
        },
        /**
         * getting single json data from the aaray
         * @param {*} state 
         * @param {*} actions 
         */
        getAllInformation: (state, actions) => {
            const { claimInfos, additionclaimInfos, serviceInfos } = current(state);
            let additionalData;
            const data = claimInfos.filter((item) => item.claimPK === actions.payload.pk);
            if (data >= 0) {
                switch (actions.payload.node) {
                    case 'common_data':
                        state.BillingInfoData = data[0].BillingInfoData;
                        state.commonData = data[0].commonData;
                        break;
                    case 'billing_info_data':
                        state.BillingInfoData = data[0].BillingInfoData;
                        break;
                    case "service_line_info":
                        additionalData = serviceInfos.filter(item => item.diganosisPK === actions.payload.pk);
                        state.serviceLineData = additionalData[0].serviceLineData;
                        break;
                    case "additional_info":
                        additionalData = additionclaimInfos.filter(item => item.additionalPK === actions.payload.pk);
                        state.additionalData = additionalData[0].additionalData;
                        break;

                }
            }
        },
        /**
         * remove perticular claim information
         * @param {*} state
         * @param {*} actions
         */
        removeClaimInformations: (state, actions) => {
            const { claimInfos, serviceInfos, additionclaimInfos } = current(state);
            const data = claimInfos.filter(item => item.claimPK === actions.payload);
            if (data?.length > 0) {
                const additionalPK = data[0].additionalInfoPK;
                const diagnosisPK = data[0].diagnosis_pk
                const claim_index = claimInfos.findIndex(item => item.claimPK === actions.payload);
                const service_index = serviceInfos.findIndex(item => item.diganosisPK === diagnosisPK);
                const additional_index = additionclaimInfos.findIndex(item => item.additionalPK === additionalPK);
                if (claim_index >= 0) {
                    state.claimInfos.splice(claim_index, 1);
                    state.commonData = initialState.commonData;
                    state.BillingInfoData = initialState.BillingInfoData;
                    state.claimInitiState = 'Save';
                    state.infoLoaded = false;
                    state.claimStatusFound = false;
                }
                if (service_index >= 0) {
                    state.serviceInfos.splice(service_index, 1);
                    state.serviceLineData = initialState.serviceInfos;
                    state.claimInitiState = 'Save';
                    state.infoLoaded = false;
                    state.claimStatusFound = false;
                }
                if (additional_index >= 0) {
                    state.additionclaimInfos.splice(additional_index, 1);
                    state.additionalclaimData = initialState.additionalclaimData;
                    state.claimInitiState = 'Save';
                    state.infoLoaded = false;
                    state.claimStatusFound = false;
                }
            }

        },
        /**
         * updating payer info details in billing info tabs
         * @param {*} state 
         * @param {*} actions 
         */
        updatePayerDetails: (state, actions) => {
            let newarrInsurance = [];
            const { claimInfos } = current(state)
            const { arrInsure, billingInfoPK } = actions.payload
            const data = claimInfos.findIndex((item) => item.billingInfoPK === billingInfoPK);
            if (data >= 0) {
                //updating array with Id of policy
                arrInsure.forEach(item => {
                    newarrInsurance.push({ ...item, Id: item.practice_insurance_id })
                });
                const values = {
                    ...claimInfos[data].BillingInfoData,
                    payer_info: newarrInsurance
                }
                state.claimInfos[data].BillingInfoData = values;
                state.BillingInfoData = state.claimInfos[data].BillingInfoData
            }

        },
        /**
         * 
         * @param {*} state 
         * @param {*} actions 
         */
        udpateICD: (state, actions) => {
            let values = {};
            const { serviceInfos } = current(state);
            const { name, value, newArray, pk } = actions.payload
            const exist = serviceInfos.findIndex(item => item.diganosisPK === pk);
            if (exist >= 0) {
                values = {
                    ...serviceInfos[exist].serviceLineData,
                    procedures: newArray,
                    [name]: value
                }
                state.serviceInfos[exist].serviceLineData = values;
                state.serviceLineData = state.serviceInfos[exist].serviceLineData
            }
        },

        /**
         *update diaganosis variables 
         * @param {*} state 
         * @param {*} actions 
         */
        updateDignosisVariables: (state, actions) => {
            let values = {};
            const { serviceInfos } = current(state);
            const { name, valueList, pk } = actions.payload
            const exist = serviceInfos.findIndex(item => item.diganosisPK === pk);
            if (exist >= 0) {
                if (valueList?.length > 0) {
                    values = {
                        ...serviceInfos[exist].serviceLineData,
                        [name]: parseInt(valueList[0].label),
                        [name + "_" + "desc"]: valueList[0].description ? valueList[0].description : "",
                        [name + "_" + "value"]: valueList[0].name,
                    }
                    state.serviceInfos[exist].serviceLineData = values;
                    state.serviceLineData = state.serviceInfos[exist].serviceLineData
                }
                else {
                    values = {
                        ...serviceInfos[exist].serviceLineData,
                        [name]: "",
                        [name + "_" + "desc"]: "",
                        [name + "_" + "value"]: "",
                    }
                    state.serviceInfos[exist].serviceLineData = values;
                    state.serviceLineData = state.serviceInfos[exist].serviceLineData
                }
            }
        },

        /**
         *updating procedure data inside service line details 
         * @param {*} state 
         * @param {*} actions 
         */
        updateProcedureData: (state, actions) => {
            const { serviceInfos } = current(state);
            const { procedureArray, pk } = actions.payload
            const exist = serviceInfos.findIndex(item => item.diganosisPK === pk);
            if (exist >= 0) {
                if (exist >= 0) {
                    const data = {
                        ...serviceInfos[exist].serviceLineData,
                        "procedures": procedureArray
                    }
                    state.serviceInfos[exist].serviceLineData = data;
                    state.serviceLineData = state.serviceInfos[exist].serviceLineData
                    state.DXPointerFlagUpdate = false;
                }
            }
        },

        updateDiagnosisDataOnRemove: (state, actions) => {
            let values = {}
            const { serviceInfos } = current(state);
            const { name, value, pk } = actions.payload
            const exist = serviceInfos.findIndex(item => item.diganosisPK === pk);
            if (exist >= 0) {
                let icdListForUpdate = { ...serviceInfos[exist].serviceLineData }
                values = {
                    ...icdListForUpdate,
                    [name]: value,
                    [name + "_" + "desc"]: value,
                    [name + "_" + "value"]: value,
                }
                icdListForUpdate = values;
                let keys = Object.keys(icdListForUpdate);
                let index = keys.indexOf(name);
                let result = [];
                for (let i = index; i < keys.length; i++) {
                    if (icdListForUpdate[keys[i]] !== null && icdListForUpdate[keys[i]] !== '' && (Array.isArray(icdListForUpdate[keys[i]]) ? icdListForUpdate[keys[i]].length : true)) {
                        result.push(keys[i]);
                    }
                }

                if (result.length > 0) {
                    result.forEach((icdToSwitchSlot) => {
                        if (icdToSwitchSlot.startsWith('diagnosis') && icdToSwitchSlot.length <= 11) {
                            let availableSlotInICDList1 = null;
                            for (let i = 1; i <= 12; i++) {
                                const key = `diagnosis${i}`;
                                if (icdListForUpdate[key] === "") {
                                    availableSlotInICDList1 = key;
                                    break;
                                }
                            }
                            let tempStoreICDToShift = icdListForUpdate[icdToSwitchSlot]
                            let tempStoreICDDescription = icdListForUpdate[`${icdToSwitchSlot}_desc`];
                            let tempStoreICDValue = icdListForUpdate[`${icdToSwitchSlot}_value`];
                            //shift the values
                            icdListForUpdate = {
                                ...icdListForUpdate,
                                [availableSlotInICDList1]: tempStoreICDToShift,
                                [availableSlotInICDList1 + '_desc']: tempStoreICDDescription,
                                [availableSlotInICDList1 + '_value']: tempStoreICDValue
                            }

                            // remove the shifted value
                            icdListForUpdate = {
                                ...icdListForUpdate,
                                [icdToSwitchSlot]: '',
                                [`${icdToSwitchSlot}_desc`]: "",
                                [`${icdToSwitchSlot}_value`]: "",
                            }
                        }
                    })
                }
                state.serviceInfos[exist].serviceLineData = icdListForUpdate;
                state.serviceLineData = state.serviceInfos[exist].serviceLineData
            }
        },

        /**
         * updating common details of additional details tab
         * @param {*} state 
         * @param {*} actions 
         */
        updateAdditionalDetails: (state, actions) => {
            const { additionclaimInfos } = current(state);
            const { name, value, pk } = actions.payload;
            const index = additionclaimInfos.findIndex(item => item.additionalPK === pk);
            if (index >= 0) {
                let addionalData = {
                    ...state.additionclaimInfos[index].additionalclaimData,
                    [name]: value
                }


                if (name === "ambulance_claim" && value === 2) {
                    addionalData = {
                        ...addionalData,
                        "patient_admitted_hospital": false,
                        "patient_moved_by_stretcher": false,
                        "patient_unconscious_shock": false,
                        "patient_transported_emergency": false,
                        "patient_physically_restrained": false,
                        "patient_visible_haemorrhaging": false,
                        "ambulance_service_medical_necessary": false,
                        "patient_confined_bed_chair": false,
                        "weight": "",
                        "pickup_location_data": {},
                        "dropoff_location_data": {},
                        "distance": "",
                        "transport_reason": "",
                        "transport_code": "",
                        "certification": "",
                        "stretcher_reason": "",
                        "pickup_location": "",
                        "dropoff_location": ""
                    }
                }
                state.additionclaimInfos[index].additionalclaimData = addionalData;
                state.additionalclaimData = state.additionclaimInfos[index].additionalclaimData;
            }

        },

        updateAuthIdentifierData: (state, actions) => {
            const { claimPK } = actions.payload;
            const { claimInfos } = current(state);
            const data = claimInfos.findIndex((item) => item.claimPK === claimPK);
            //getting common data from claimInfo array and updating new value
            if (data >= 0) {
                let values = {
                    ...state.claimInfos[data].commonData,
                    "identifier": "",
                    "authorization_referral": ""
                }
                state.claimInfos[data].commonData = values;
                state.commonData = state.claimInfos[data].commonData
            }
        },

        /**
         * dx pointer added or removed for existing claim
         * @param {*} state 
         */
        updateDXpointerFlagUpdate: (state) => {
            state.DXPointerFlagUpdate = true;  
        },
        /**
         * resetting all data to initalState
         */
        resetClaimtoInitalState: () => initialState
    },

    //api call status checking 
    extraReducers: (builder) => {
        builder.addCase(getClaimData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getClaimData.fulfilled, (state, actions) => {
            //checking claim already active inside the array
            const exist = state.claimInfos.findIndex(item => item.claimPK === actions.payload.claimPK);
            if (exist == -1) {
                //pushing claim to claim info array
                state.claimInfos.push(actions.payload);
                state.BillingInfoData = actions.payload.BillingInfoData;
                state.commonData = actions.payload.commonData;
                state.claimInitiState = 'Edit'
                //setting formwaring on first data fetch 
                if (actions.payload.commonData.patient_name) {
                    state.formWarnData[0]["Patient"] = actions.payload.commonData.patient_name;
                    state.formWarningStatus = true;
                }
                if (actions.payload.commonData.claim_status) {
                    state.claimStatusFound = true;
                }
                let warningsArray = state.formWarnData;
                warningsArray = warningsArray.filter(function (obj) {
                    return obj.type !== 'validation';
                });
                if (actions.payload.commonData.claim_validations && actions.payload.commonData.claim_validations?.length > 0) {
                    let claimValidations = actions.payload.commonData.claim_validations;
                    claimValidations.forEach((item) => {
                        let warningExist = warningsArray.filter((warningObj) => warningObj.tabName === item);
                        let warningCode = '';
                        let warningMessages = '';
                        if (Array.isArray(item)) {
                            warningCode = item[0];
                            let optionString = i18n.t('warning.claim.' + warningCode);
                            warningMessages = optionString.replace(/{(\w+)}/g, function (_, k) {
                                return item[1][k];
                            });
                        } else {
                            warningCode = item;
                            warningMessages = i18n.t('warning.claim.' + warningCode);
                        }

                        let warnings = { "tabName": warningCode, "type": "validation" }
                        warnings[warningMessages] = "";
                        if (warningExist) {
                            let warningIndexItem = '';
                            if (Array.isArray(item))
                                warningIndexItem = item[0];
                            else
                                warningIndexItem = item;
                            let warningIndex = warningsArray.findIndex(items => items.tabName === warningIndexItem);
                            if (warningIndex < 0) {
                                warningsArray.push(warnings);
                            } else {
                                warningsArray[warningIndex] = warnings;
                            }
                        } else {
                            warningsArray.push(warnings);
                        }
                    })
                }
                state.formWarnData = warningsArray
                if (state.formWarningStatus) {
                    state.formWarningStatus = false;
                    state.formWarningStatus = true;
                }
                else {
                    state.formWarningStatus = true;
                    state.formWarningStatus = false;
                }
            }
            state.loading = false;
        });
        //claim data fetch have any error
        builder.addCase(getClaimData.rejected, (state) => {
            state.loading = false;
        });

        //claim service line data pending case
        builder.addCase(getServiceLineData.pending, (state) => {
            state.loading = true;
        })
        //claim service line data full filled if data is fetched from api 
        builder.addCase(getServiceLineData.fulfilled, (state, actions) => {
            state.loading = false;
            const { serviceInfos } = current(state);
            const exist = serviceInfos.findIndex(item => item.claimPK === actions.payload.claimPK);
            if (exist == -1) {
                state.serviceInfos.push(actions.payload);
                state.serviceLineData = actions.payload.serviceLineData;
            }
            else {
                state.serviceInfos[exist] = actions.payload;
                state.serviceLineData = actions.payload.serviceLineData;
            }

        })
        //claim service line data fetch have any error
        builder.addCase(getServiceLineData.rejected, (state) => {
            state.loading = false;
        })

        //claim additional data pending case
        builder.addCase(getadditionalData.pending, (state) => {
            state.loading = true;
        })
        //claim service line data full filled if data is fetched from api 
        builder.addCase(getadditionalData.fulfilled, (state, actions) => {
            state.loading = false;
            const { additionclaimInfos } = current(state);
            const exist = additionclaimInfos.findIndex(item => item.additionalPK === actions.payload.additionalPK);
            if (exist == -1) {
                state.additionclaimInfos.push(actions.payload);
                state.additionalclaimData = actions.payload.additionalclaimData;
            }

        })
        //claim service line data fetch have any error
        builder.addCase(getadditionalData.rejected, (state) => {
            state.loading = false;
        })
        //get form wanings pending state
        builder.addCase(getFormWarings.pending, (state) => {
            state.loading = true
        })
        //get form warnings api result is success
        builder.addCase(getFormWarings.fulfilled, (state, actions) => {
            state.loading = false
            if (actions.payload.patient_name) {
                state.formWarnData[0]["Patient"] = actions.payload.patient_name;
                state.formWarningStatus = true;
            }

            let warningsArray = state.formWarnData;
            warningsArray = warningsArray.filter(function (obj) {
                return obj.type !== 'validation';
            });

            if (actions.payload.claim_validations && actions.payload.claim_validations?.length > 0) {
                let claimValidations = actions.payload.claim_validations;
                claimValidations.forEach((item) => {
                    let warningExist = warningsArray.filter((warningObj) => warningObj.tabName === item);
                    let warningCode = '';
                    let warningMessages = '';
                    if (Array.isArray(item)) {
                        warningCode = item[0];
                        let optionString = i18n.t('warning.claim.' + warningCode);
                        warningMessages = optionString.replace(/{(\w+)}/g, function (_, k) {
                            return item[1][k];
                        });
                    } else {
                        warningCode = item;
                        warningMessages = i18n.t('warning.claim.' + warningCode);
                    }

                    let warnings = { "tabName": warningCode, "type": "validation" }
                    warnings[warningMessages] = "";
                    if (warningExist) {
                        let warningIndexItem = '';
                        if (Array.isArray(item))
                            warningIndexItem = item[0];
                        else
                            warningIndexItem = item;
                        let warningIndex = warningsArray.findIndex(items => items.tabName === warningIndexItem);
                        if (warningIndex < 0) {
                            warningsArray.push(warnings);
                        } else {
                            warningsArray[warningIndex] = warnings;
                        }
                    } else {
                        warningsArray.push(warnings);
                    }
                })
            }
            state.formWarnData = warningsArray
            if (state.formWarningStatus) {
                state.formWarningStatus = false;
                state.formWarningStatus = true;
            }
            else {
                state.formWarningStatus = true;
                state.formWarningStatus = false;
            }
        })
        //form warning api rejected state 
        builder.addCase(getFormWarings.rejected, (state) => {
            state.loading = false;
        })
    }
})


export const {
    updateBasicData,
    updateBillingInfoDatas,
    getAllInformation,
    updatePayerDetails,
    updateDignosisVariables,
    updateAdditionalDetails,
    updateProcedureData,
    udpateICD,
    updateDiagnosisDataOnRemove,
    removeClaimInformations,
    resetClaimtoInitalState,
    updateAuthIdentifierData,
    updateDXpointerFlagUpdate
} = claimManagementSlice.actions

export default claimManagementSlice.reducer;


