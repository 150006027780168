import React,{useState, useEffect, useContext} from 'react';
import {Form} from 'react-bootstrap';
import './style.css';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import {ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE} from '../../../utilities/staticConfigs';
import {ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS} from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input'; 
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ServiceTypeTableData } from './ServiceTypeTable';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from '../../commons/Buttons';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const ServiceTypesList = (props) => {
    const [header, setHeader] = useState('');
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [serviceTypesList, setServiceTypesList] = useState([]);
    const [editForm, setEditForm] = useState(false);
    const [form_error, setFormError] = useState({'serviceType':''});
    const [serviceType, setServiceType] = useState('');
    const [deleteServiceName, setDeleteServiceName] = useState('');
    const [deleteServiceId, setDeleteServiceId] = useState('');
    const [editServiceID, setEditServiceID] = useState('');
    const [permission, setPermission] = useState(false);


    //start ==== Alert message properties
    const [showNotify,setShowNotify] = useState('hide');
    const [notifyDescription,setNotifyDescription] = useState('');
    const [notifyType,setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action,type,desc,age=3000){
        if(action === 'show'){
            setTimeout(()=> {
                setShowNotify('hide');
            },age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

     //Pagination start
     const [totalPage, setTotalPage] = useState(1);
     const [activePage, setActivePage] = useState(1);
     const [startIndex, setStartIndex] = useState(0);
     const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
 
    function onPagePrevious() {
         let previousPage = startIndex + 1 - PAGING_END_INDEX;
         setActivePage(previousPage);
 
         if (startIndex !== 0) {
             setStartIndex(startIndex - PAGING_END_INDEX);
             setEndIndex(endIndex - PAGING_END_INDEX);
         }
         getServiceTypeList(DEFAULT_PAGING_SIZE, previousPage);
     }
 
     function onPageUp(e) {
         let page = Number(e.target.id)
         setActivePage(page);
         getServiceTypeList(DEFAULT_PAGING_SIZE, page);
     }
 
    function onPageNext() {
         let nextPage = startIndex + 1 + PAGING_END_INDEX;
         if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
             setActivePage(nextPage);
             setStartIndex(startIndex);
             setStartIndex(endIndex);
         } else {
             setActivePage(nextPage);
             setStartIndex(startIndex + PAGING_END_INDEX);
             setEndIndex(endIndex + PAGING_END_INDEX);
         }
         getServiceTypeList(DEFAULT_PAGING_SIZE, nextPage);
     }
     //Pagination ends


    useEffect(() => {
        getServiceTypeList(DEFAULT_PAGING_SIZE, activePage);
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
    }, []);

    function getServiceTypeList(pageSize, page) {
        setShowLoadingBar(true);
        const result = service.ListServiceType(pageSize, page);
        result.then(response =>{
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
			const rowArray = commonTableBody(response.data.results, ServiceTypeTableData.tableBodyData[0]);
			ServiceTypeTableData.tableBodyData = rowArray;
            setServiceTypesList(response.data.results);
        })
    }

    function addNew(){
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.typeofService.addHeader"));
        setShowModalWindow(true);
    }

    function onEditServiceType(serviceID) {
        setHeader(i18n.t("dictionariesPages.typeofService.editHeader"));
        setShowLoadingBar(true);
        setEditServiceID(serviceID);
        let result = service.GetServiceType(serviceID);
        result.then(response =>{
            setShowLoadingBar(false);
            setServiceType(response.data.name);
            setEditForm(true);
            setEditServiceID(serviceID);
        })
        setShowModalWindow(true);
    }

    function onSaveFormData(e) {
        e.preventDefault();
        setFormError({
            ...form_error,
            ['serviceType']:!serviceType.trim() ?  'error' : ''
          });
       if(serviceType){
            setShowLoadingBar(true);
            let result = null;
            let data = {name: serviceType};
            result=editForm ? service.UpdateServiceType(editServiceID, data):service.AddServiceType(data);
            result.then(response => {
                setShowLoadingBar(false);
                if(response.status === 201 || response.status === 200){
                    if(response.status === 201)
                        showNotifyWindow('show','success',ADD_SUCCESS);
                    else
                        showNotifyWindow('show','success',UPDATE_SUCCESS);
                    getServiceTypeList(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                }else if(response.status === 400 && response.data.record_already_exists !== undefined){
                    showNotifyWindow('show','error',i18n.t('errorMessages.record_exists'));
                }else{
                    showNotifyWindow('show','error',ADD_ERROR);
                }
            })
        }
    }

    function onDeleteServiceType(serviceID){
        serviceTypesList.map((item) => {
            if(item.id === parseInt(serviceID)){
                setDeleteServiceName(item.name);
                setDeleteServiceId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction(){
        setShowLoadingBar(true);
        const data = service.DeleteServiceType(deleteServiceId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteServiceName('');
            setDeleteServiceId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show','success',DELETE_SUCCESS);
            getServiceTypeList(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function onDeleteHide(){
        setDeleteServiceName('');
        setDeleteServiceId('');
        setShowDeleteModalWindow(false);
    }

    function resetForm(){
        setServiceType('');
        setEditForm(false);
        setFormError({
              ...form_error,
              ['serviceType']: ''
         });

    }

    function onHide(){
        setEditForm(false);
    }

    function onHandleChange(e) {
        const {name}  = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if(e.target.type==="text"){
            trimmedValue = value; 
        }
        if(!trimmedValue){
            setFormError({
              ...form_error,
              [name]: 'error'
            });
        }else{
            setFormError({
              ...form_error,
              [name]: ''
            });
        }
        setServiceType(trimmedValue);
    }

    function backToDictionaryPage(){
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }

    function dropDownFunction(id, name) {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		if (name.toLowerCase() == 'edit') {
			onEditServiceType(Number(id));
		}
		if (name.toLowerCase() == 'delete') {
			onDeleteServiceType(Number(id));
		}
	}

    return(
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-8">
                <div className="box box-content-white">
                    <div className="box-head ">
                        <div className={'dataTables_filter'}>
                            <div className="link dictionaries-back" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
                        </div>
                        <div className="alignRight padding-right5 margin-bottom2">
                            <CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")} />
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={ServiceTypeTableData} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2 mb-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext}/>
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
            <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                <Form.Group>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group padding-top15">
                                <TextInput required={true} type="text" id="serviceType" name="serviceType" label={i18n.t('dictionariesPages.typeofService.lblTypeofService')} onValueChange={onHandleChange} className={form_error.serviceType ? 'input-error' : ""} value={serviceType} />
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </CustomizedDialogs>  
        <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
            {i18n.t("dictionariesPages.typeofService.deletConfirmation")+"'"+deleteServiceName+"'?"}
        </CustomizedSmallDialogs>  
        </React.Fragment>
    )
}
export default ServiceTypesList