import React from 'react'
import './style.css';
import TextField from '@material-ui/core/TextField';
import Label from '../Label';
import InputAdornment from '@material-ui/core/InputAdornment';

const style = { width: "95%", border: "1px solid black", borderRadius: "4px" } 

function CurrencyInputs(props) {
    const required = props.required;
    const isAdornment = props.isAdornment == false ? false : true;

    return (
        <div className={props.divClassName ? ("form-group "+props.divClassName) : "form-group"}>

            {/* Ref: When sending the Currency or Amount to the backend you may need to remove commas which is applied by
                     currency inputs to avoid Api error as Db doesn't take commas in bwt amount value. */}
            <Label label={props.label} />
            <TextField
                style={props.isBackgroundEnabled ? style : { width: "100%" }} id={props.id ? props.id : "standard-basic"} variant="outlined" 
                required={required ? required : ""}
                InputLabelProps={props.value ? { shrink: props.value ? true : false } : ""}
                type={props.type}
                // placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onValueChange}
                className={props.defaultClassName}
                InputProps={isAdornment ? {
                    startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                    ),
                } : {}}
            />
           
        </div>
    )
}

export default CurrencyInputs;