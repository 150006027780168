import api from '../../../service/api';
import { getStorage } from '../../../utilities/browserStorage';


const GetSelectedTabs = async (type) => {
    let path = `user/user-opened-tab/?type=${type ? type : 'claims'}` + '&practice_pk=' + getStorage('practice');
    return api.get(path);
}

const AddRemoveSelectedTab = async (data) => {
    let path = 'user/user-opened-tab/';
    return api.post(path, data);
}

const UpdateClaimNotes = async (id, data) => {
    let path = "claim/notes/" + id;
    return api.put(path, data);
}

const GetListPatientClaims = async (query, exportType) => {
    let path = "claim/claims/" + query;
    let result = null;
    if (exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const GetPaperClaimFormPDF = async (query) => {
    let path = 'claim/paper-claim-pdf/?' + query;
    return api.getXLS(path);
}


const ListReferringProviders = async (pageSize, page, practicePK, patientPK, claimPK) => {
    let path = "user/referring-provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk=' + patientPK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
};


const ListRenderingProviders = async (pageSize, page, practicePK, patientPK, claimPK) => {
    let path = "user/provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk=' + patientPK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
};

const GetListClaimFrequency = async (pageSize, page, claimPK) => {
    let path = 'super-admin/claim-frequency/?page_size=' + pageSize + '&page=' + page;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListRelations = async (pageSize, page, claimPK) => {
    let path = 'super-admin/relationship/?page_size=' + pageSize + '&page=' + page;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ServiceLocationLists = async (practice_pk, patient_pk, claim_pk, no_default) => {
    let path = 'claim/service-locations/?practice_pk=' + practice_pk;
    if (patient_pk) path += '&opened_patient_pk=' + patient_pk;
    if (claim_pk) path += '&claim_pk=' + claim_pk;
    if (no_default) path += '&no_default=' + no_default;
    return api.get(path);
}

const ListClaimStatus = async (pageSize, page, practicePK, listType, patientPK, claimPK) => {
    let path = null;
    path = 'practice/claim-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&list_type=' + listType;
    if (patientPK)
        path += '&patient_pk=' + patientPK
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListClaimSubStatus = async (pageSize, page, practicePK, claimPK) => {
    let path = null;
    path = 'practice/claim-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListClaimStatusSubStatus = async (pageSize, page, practicePK, claimPK, statusPK) => {
    let path = null;
    path = 'practice/claim-status-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&status_pk=' + statusPK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const GetResponsibilityType = async (patientPk, claimPk) => {
    let path = `claim/patient-responsibility-list/?patient_pk=${patientPk}&claim_pk=${claimPk ?? ""}`;
    return api.get(path);
};

const GetPatientDetails = async (patientPK) => {
    let path = "patient/patient-details/" + patientPK + "?type=add_claim";
    return api.get(path);
}

const GetPatientDXCPTDetails = async (patientPK) => {
    let path = 'patient/patient-dx-cpt-data/?patient_pk=' + patientPK;
    return api.get(path);
}

const GetPatientAccidentInfo = async (patientPK) => {
    let path = 'patient/patient-accident-info/?patient_pk=' + patientPK;
    return api.get(path);
}

const GetPracticeBasicData = async (practicePK) => {
    let path = 'practice/get-practice-basic-data/' + practicePK;
    return api.get(path);
}

const ListAccidentTypes = async (pageSize, page, claimPK) => {
    let path = 'super-admin/accident-type/?page_size=' + pageSize + '&page=' + page;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}


const GetListPayerInfo = async (pageSize, page, patient_pk, priority, claim_pk) => {
    let path = 'claim/payer-info-list/?list=true&page_size=' + pageSize + '&page=' + page + '&patient_pk=' + patient_pk + '&claim_pk=' + claim_pk;
    if (priority) path += '&priority=' + priority;
    return api.get(path);
}

const GetGeneralClaim = async (claimPK) => {
    let path = 'claim/claims/' + claimPK;
    return api.get(path);
}
const GetBillingInfoClaim = async (biilingPK) => {
    let path = 'claim/billing-info/' + biilingPK;
    return api.get(path);
}
const GetServiceLineClaim = async (servicelinesPk) => {
    let path = 'claim/professional-service-lines/' + servicelinesPk;
    return api.get(path);
}

const GetAdditionalClaim = async (claimPK) => {
    let path = 'claim/professional-additional-claim-info/' + claimPK;
    return api.get(path);
}


const ListOfUSStates = async (claimPK) => {
    let path = "super-admin/us-states/?page=0";
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
};


const ListDelayReasonCode = async (pageSize, page) => {
    let path = 'super-admin/delay-reason-code/?page_size=' + pageSize + '&page=' + page
    return api.get(path);
}

const ListTransportTypes = async (pageSize, page, claimPK) => {
    let path = 'super-admin/transport-reason/?page_size=' + pageSize + '&page=' + page;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const GetICD10List = async (pageSize, page, practicePK, type, search, claimPK) => {
    let path = "practice/icd10/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" + search;
    if (type) {
        path += "&type=" + type
    }
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const GetCPTCodeList = async (pageSize, page, practicePK, type, search, claimPK, dos) => {
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" + search;
    if (type) {
        path += "&type=" + type
    }
    if (dos) {
        path += '&dos=' + dos;
    }
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);

}

const GetFacilityPos = async (serviceLocationPk) => {
    let path = `claim/service-location-pos/?service_location_id=${serviceLocationPk}&facility_type=facility`
    return api.get(path);
}

const SearchPosCodes = async (query) => {
    let path = 'super-admin/service-codes/?' + 'column=name&search=' + query;
    return api.get(path);
}

const SearchTosCodes = async (query) => {
    let path = 'super-admin/service-type/?' + 'search=' + query;
    return api.get(path);
}

const GetModifier = async (query, claimPK) => {
    let path = 'super-admin/modifier/?search=' + query;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const GetIdentifiers = async (patientPK, searchType, provider) => {
    let path = "claim/get-identifiers/?patient_id=" + patientPK + "&type=" + searchType + '&provider_id=' + provider;
    return api.get(path);
}

const ImportClaim = async (data) => {
    let path = 'claim/import-claim/'
    return api.post(path, data);
}

const SaveNewClaimCopy = async (data) => {
    let path = "claim/claims/";
    return api.post(path, data);
}

const UpdateNewClaim = async (id, data) => {
    let path = '/claim/claims/' + id;
    return api.put(path, data);
}

const UpdateClaimActive = async (claimPK, data) => {
    let path = 'claim/claim-activate/' + claimPK;
    return api.put(path, data);

};

const CheckForAlert = async (data) => {
    let path = 'patient/check-for-alert/';
    return api.post(path, data);
}

const ListClaimNotes = async (claim_pk, pageSize, page) => {
    let path = "claim/notes/?category=claim&claim_pk=" + claim_pk + '&page_size=' + pageSize + '&page=' + page;
    return api.get(path);
}
const DeleteClaimNotes = async (id) => {
    let path = "claim/notes/" + id;
    return api.destroy(path);
}

const GetClaimNotes = async (id) => {
    let path = "/claim/notes/" + id;
    return api.get(path);
}
const SaveNewClaimNotes = async (data) => {
    let path = "claim/notes/";
    return api.post(path, data);
}

const GetPatientHistory = async (patientPK) => {
    let path = 'claim/patient-professional-service-lines/?patient=' + patientPK;
    return api.get(path);
}


export default {
    GetSelectedTabs, 
    AddRemoveSelectedTab,
    GetListPatientClaims,
    GetPaperClaimFormPDF,
    ListReferringProviders,
    ListRenderingProviders,
    GetListClaimFrequency,
    ListRelations,
    ServiceLocationLists,
    ListClaimStatus,
    ListClaimSubStatus,
    ListClaimStatusSubStatus,
    GetResponsibilityType,
    GetPatientDetails,
    GetPatientDXCPTDetails,
    GetPatientAccidentInfo,
    GetPracticeBasicData,
    ListAccidentTypes,
    GetListPayerInfo,
    GetGeneralClaim,
    GetBillingInfoClaim,
    GetServiceLineClaim,
    GetAdditionalClaim,
    ListOfUSStates,
    ListDelayReasonCode,
    ListTransportTypes,
    GetICD10List,
    GetCPTCodeList,
    GetFacilityPos,
    SearchPosCodes,
    SearchTosCodes,
    GetModifier,
    GetIdentifiers,
    ImportClaim,
    SaveNewClaimCopy,
    UpdateNewClaim,
    UpdateClaimActive,
    CheckForAlert,
    ListClaimNotes,
    GetClaimNotes,
    DeleteClaimNotes,
    SaveNewClaimNotes,
    GetPatientHistory,
    UpdateClaimNotes
}
