import api from '../../../service/api'

const ListFinancialClasses = async (pageSize, page, isAdminModule, practicePK) => {
    let path = null;
    if (isAdminModule === 'true')
        path = 'super-admin/financial-class/?page_size=' + pageSize + '&page=' + page;
    else
        path = 'practice/financial-class/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    return api.get(path);
}

const AddFinancialClass = async (data) => {
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, "practice"))
        path = 'practice/financial-class/';
    else
        path = 'super-admin/financial-class/';
    return api.post(path, data);
}
const GetFinancialClass = async (financialClassId, practicePK) => {
    let path = null;
    if (practicePK)
        path = 'practice/financial-class/' + financialClassId;
    else
        path = 'super-admin/financial-class/' + financialClassId;
    return api.get(path);
}

const UpdateFinancialClass = async (financialClassId, data) => {
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, 'practice'))
        path = 'practice/financial-class/' + financialClassId;
    else
        path = 'super-admin/financial-class/' + financialClassId;
    return api.put(path, data);
}

const DeleteFinancialClass = async (financialClassId, practicePK) => {
    let path = null;
    if (practicePK)
        path = 'practice/financial-class/' + financialClassId
    else
        path = 'super-admin/financial-class/' + financialClassId
    return api.destroy(path);
}
const exportFinancialClass = async (type, practicePK) => {
    const path = 'practice/financial-class/?practice_pk=' + practicePK + '&export=true&export_type=' + type;
    if (type == 'csv') {
        return api.get(path);
    }
    else {
        return api.getXLS(path);
    }
}

export default { 
    ListFinancialClasses, 
    AddFinancialClass, 
    GetFinancialClass, 
    UpdateFinancialClass, 
    DeleteFinancialClass,
    exportFinancialClass
}