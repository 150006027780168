import api from '../../../service/api'

const ListDocumentationMethodTypes = async(pageSize, page) =>{
	let path = 'super-admin/documentation-method/?page_size='+pageSize+'&page='+page
	return api.get(path);
}

const AddDocumentationMethodType = async(data) =>{
	let path = 'super-admin/documentation-method/'
	return api.post(path,data);
}
const GetDocumentationMethodType = async(documentationMethodId) =>{
	let path = 'super-admin/documentation-method/' + documentationMethodId
	return api.get(path);
}

const UpdateDocumentationMethodType = async(documentationMethodId, data) =>{
	let path = 'super-admin/documentation-method/' + documentationMethodId
	return api.put(path,data);
}

const DeleteDocumentationMethodType = async(documentationMethodId) =>{
	let path = 'super-admin/documentation-method/' + documentationMethodId
	return api.destroy(path);
}

export default {ListDocumentationMethodTypes, AddDocumentationMethodType, GetDocumentationMethodType, UpdateDocumentationMethodType, DeleteDocumentationMethodType}