import React, { useState, useEffect } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import i18n from '../../../utilities/i18n';
import CalendarInput from '../../commons/input/CalendarInput';
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import service from './service';
import { DRUG_UNITS, EmgList, MEASUREMENT_UNITS } from '../../../utilities/dictionaryConstants';
import { getStorage } from '../../../utilities/browserStorage';
import "./style.css"
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import CurrencyInputs from '../../commons/input/CurrencyInput';
import { useSelector } from 'react-redux';
import Label from '../../commons/Label';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import moment from 'moment';
import { DRUD_CODE_LENGTH} from '../../../utilities/staticConfigs';
import ProcedureActivityView from './ProcedureActivityView';

const Procedures = (props) => {
    const procedure = props.procedure;
    const propindex = props.index;
    const responsibility = useSelector(state => state.claimSelectInputData.responsibilityType);
    const claimStatusList = useSelector(state => state.claimSelectInputData.claimStatusList);
    const claimSubStatusList = useSelector(state => state.claimSelectInputData.claimSubStatusList);
    const { saveMode, commonData } = useSelector(state => state.claimSaveManagmentData);
    const claimInfoDetails = useSelector(state => state.claimData.commonData);
    const practicePK = getStorage('practice');
    const page = 0;
    const page_size = 0;
    const [modifierList1, setModifierList1] = useState([]);
    const [modifierList2, setModifierList2] = useState([]);
    const [modifierList3, setModifierList3] = useState([]);
    const [modifierList4, setModifierList4] = useState([]);
    const [cptList, setCptList] = useState([]);
    const [posCodeList, setPosCodeList] = useState([]);
    const [tosCodeList, setTOSCodeList] = useState([]);
    const [validDrugCode, setValidDrugCode] = useState(true);

    // increase drop-down list on click ('Load)
    const [maxResults, setMaxResults] = useState(5);
    const [expand, setExpand] = useState(false);

    // line not mndatory line variable
    const [line_note_mandatory_line, setLine_note_mandatory_line] = useState(false);
    // modifier validation
    const [inputM1Validated, setInputM1Validated] = useState(true);
    const [inputM2Validated, setInputM2Validated] = useState(true);
    const [inputM3Validated, setInputM3Validated] = useState(true);
    const [inputM4Validated, setInputM4Validated] = useState(true);
    const [paymentDone, setPaymentDone] = useState(false);

    const [procedureActivityAnchor, seProcedureActivityAnchor] = useState(null);
    const [isProcedureActivityViewOpen, setIsProcedureActivityViewOpen] = useState(false);

    useEffect(() => {
        if (responsibility.length > 0 && !props.claimPK && !props.addNewRowClicked) {
            let responsibility_id = "";
            if (responsibility?.filter((item) => item?.name === "Primary")?.length > 0)
            {
                let responsibilityType = responsibility?.filter((item) => item?.name === "Primary")
                responsibility_id = responsibilityType[0].id   
            }
            else if (responsibility?.filter((item) => item?.name === "Secondary")?.length > 0) {
                let responsibilityType = responsibility?.filter((item) => item?.name === "Secondary")
                responsibility_id = responsibilityType[0].id
            }
            else if (responsibility?.filter((item) => item?.name === "Tertiary").length > 0) {
                let responsibilityType = responsibility?.filter((item) => item?.name === "Tertiary")
                responsibility_id = responsibilityType[0].id
            }
            else if (responsibility?.filter((item) => item?.name === "Patient")?.length > 0) {
                let responsibilityType = responsibility?.filter((item) => item?.name === "Patient")
                responsibility_id = responsibilityType[0].id
            }
            // Dynamically set the initial responsibility of a procedure based on the patients insurance availability
            // When patient has no insurance, then set the responsibility to 4 i.e patient else primary
            if (props.serviceLineData?.procedures?.length > 0) {
                let proceduresCopy = JSON.parse(JSON.stringify(props.serviceLineData?.procedures));
                proceduresCopy?.filter((item, index) => {
                    if (item.responsibility_type=="") {
                        proceduresCopy[index].responsibility_type = responsibility_id;
                    }
                })
                props.setProcedureRows(proceduresCopy);
            }
        }
    },[responsibility])

    useEffect(() => {
        // CHECK ORDER OF MODIFIERS DATA, 
        // e.g. IF M1 FILLED, M2 BLANK AND M3 OR M4 HAVING DATA, modifiersValidated SHOULD SET TO false 
        let item = props.procedure;
        let M1 = (Array.isArray(item.m1_id) && item.m1_id.length) ? true : (Array.isArray(item.m1_id) && !item.m1_id.length) ? false : item.m1_id ? true : false;
        let M2 = (Array.isArray(item.m2_id) && item.m2_id.length) ? true : (Array.isArray(item.m2_id) && !item.m2_id.length) ? false : item.m2_id ? true : false;
        let M3 = (Array.isArray(item.m3_id) && item.m3_id.length) ? true : (Array.isArray(item.m3_id) && !item.m3_id.length) ? false : item.m3_id ? true : false;
        let M4 = (Array.isArray(item.m4_id) && item.m4_id.length) ? true : (Array.isArray(item.m4_id) && !item.m4_id.length) ? false : item.m4_id ? true : false;

        if (M4) {
            setInputM1Validated(M1 ? true : false);
            setInputM2Validated(M2 ? true : false);
            setInputM3Validated(M3 ? true : false);
            setInputM4Validated(true);
            if (!M1 || !M2 || !M3) {
                props.setModifiersValidated(false);
            }
        }
        else if (M3) {
            setInputM1Validated(M1 ? true : false);
            setInputM2Validated(M2 ? true : false);
            setInputM3Validated(true);
            setInputM4Validated(true);
            if (!M1 || !M2) {
                props.setModifiersValidated(false);
            }
        }
        else if (M2) {
            setInputM1Validated(M1 ? true : false);
            setInputM2Validated(true);
            setInputM3Validated(true);
            setInputM4Validated(true);
            if (!M1) {
                props.setModifiersValidated(false);
            }
        }
        else {
            setInputM1Validated(true);
            setInputM3Validated(true);
            setInputM2Validated(true);
            setInputM4Validated(true);
        }
        if (procedure.payment_doneList) {
            let payment_done = procedure.payment_doneList[0];
            if (payment_done) setPaymentDone(true);
        }

    }, [props.procedure]);

    const onSearchModifiers1 = (query) => {
        if (typeof (query) == 'string') {
            let result = service.GetModifier(query);
            result.then((response) => {
                setModifierList1(response.data.results)
            })
        } else if (typeof (query) === 'object' && query.length) {
            let result = service.GetModifier(query[0].name);
            result.then((response) => {
                setModifierList1(response.data.results)
            })
        }
    }

    const onSearchModifiers2 = (query) => {
        if (typeof (query) == 'string') {
            let result = service.GetModifier(query);
            result.then((response) => {
                setModifierList2(response.data.results)
            })
        } else if (typeof (query) === 'object' && query.length) {
            let result = service.GetModifier(query[0].name);
            result.then((response) => {
                setModifierList2(response.data.results)
            })
        }
    }

    const onSearchModifiers3 = (query) => {
        if (typeof (query) == 'string') {
            let result = service.GetModifier(query);
            result.then((response) => {
                setModifierList3(response.data.results)
            })
        } else if (typeof (query) === 'object' && query.length) {
            let result = service.GetModifier(query[0].name);
            result.then((response) => {
                setModifierList3(response.data.results)
            })
        }
    }

    const onSearchModifiers4 = (query) => {
        if (typeof (query) == 'string') {
            let result = service.GetModifier(query);
            result.then((response) => {
                setModifierList4(response.data.results)
            })
        } else if (typeof (query) === 'object' && query.length) {
            let result = service.GetModifier(query[0].name);
            result.then((response) => {
                setModifierList4(response.data.results)
            })
        }
    }

    const onSearchCPTCodes = (query) => {
        let type = 'dropdown'
        let dos = "";
        if (procedure.dos_from) {
            dos = format(new Date(procedure.dos_from), 'yyyy/MM/dd', { locale: enUS });
        }
        else {
            dos = null;
        }
        let result = service.GetCPTCodeList(page_size, page, practicePK, type, query, props.claimPK, dos);
        result.then((response) => {
            if (response?.data?.length) {
                setCptList(response.data)
            } else {
                throw new Error("Error while fetching Cpt");
            }
        }).catch(error => {
            setCptList([]);
            console.error(error);
        })
    }
    function validatingM1(e) {
        if (e) {
            setInputM1Validated(false);
            onSearchModifiers1(e);
        }
        else {
            setInputM1Validated(true);
        }
    }
    function validatingM2(e) {
        if (e) {
            setInputM2Validated(false);
            onSearchModifiers2(e);
        }
        else setInputM2Validated(true);
    }
    function validatingM3(e) {
        if (e) {
            setInputM3Validated(false);
            onSearchModifiers3(e);
        }
        else setInputM3Validated(true);
    }
    function validatingM4(e) {
        if (e) {
            setInputM4Validated(false);
            onSearchModifiers4(e);
        }
        else setInputM4Validated(true);
    }


    useEffect(() => {      
        if (props.procedure.line_note) {
            setLine_note_mandatory_line(() => {
                return props.procedure.line_note.length < 2;
            });
        } else {
            setLine_note_mandatory_line(false);
        }
    }, [props.procedure.line_note]);

    function selectOnTabPOS(e, propIndex) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        }
        else {
            props.posHandleChange(e, propIndex)
        }
    }

    function selectOnTabTos(e, propIndex) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        }
        else {
            props.tosHandleChange(e, propIndex)
        }
    }


    function selectOnTab(e) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        }
    }

    const handleDrugCodeValidation = (value) => {
        const trimmedValue = String(value.trim()).replace(/[^\d]/g, '').substr(0, DRUD_CODE_LENGTH);
        const formattedValue = formatDrugCode(trimmedValue);
        const isValid = formattedValue.length === 13 || !formattedValue;
        setValidDrugCode(isValid);
        return formattedValue;
    };

    const formatDrugCode = (rawValue) => {
        let formattedValue = '';
        for (let i = 0; i < rawValue?.length; i++) {
            if (i === 5 || i === 9) {
                formattedValue += '-';
            }
            formattedValue += rawValue.charAt(i);
        }
        return formattedValue;
    };

    function getPosCodes(query) {
        let result = service.SearchPosCodes(query);
        result.then((response) => {
            let anArray = [];
            response.data.results.map((item) => {
                let anObject = {
                    'id': item.id,
                    'name': item.drop_down_name,
                    'label': item.drop_down_name
                }
                anArray.push(anObject);
            });
            setPosCodeList(anArray);
        });
    }

    async function getTosCodes(query) {
        try {
            let response = await service.SearchTosCodes(query);
            let anArray = [];

            response?.data?.results?.forEach((item) => {
                let anObject = {
                    'id': item.id,
                    'name': item.drop_down_name,
                    'label': item.drop_down_name
                }
                anArray.push(anObject);
            });
            setTOSCodeList(anArray);

        } catch (error) {
            console.error("Error fetching  TOS codes:", error);
        }
    }

    function onProcedureDateHandleChange(value, name, index) {
        props.setShowLoadingBar(true);
        let type = 'dropdown'
        const formattedDateForRedux = value instanceof Date ? moment(value).format("yyyy-MM-dd") : null;
        let query = procedure?.cpt_hcpcsList[0]?.name;
        if (query && formattedDateForRedux != 'Invalid date') {
            let dos = format(new Date(value), 'yyyy/MM/dd', { locale: enUS });
            let result = service.GetCPTCodeList(page_size, page, practicePK, type, query, props.claimPK, dos);
            result.then((response) => {
                if (response.data) {
                    props.setShowLoadingBar(false)
                    let filterData = response?.data?.filter((item) => item.name == query);
                    let data = {
                        "units": filterData[0].units ? filterData[0].units : "0",
                        "fee": filterData[0].fee ? filterData[0].fee : "0.00"
                    }
                    props.onProcedureDateHandleChange(value, name, index,data)
                }
                else {
                    let data = {
                        "units": "0",
                        "fee":"0"
                    }
                    props.setShowLoadingBar(false)
                    props.onProcedureDateHandleChange(value, name, index, data)
                }
             
            })
        }
        else {
            props.setShowLoadingBar(false)
            props.onProcedureDateHandleChange(value, name, index)
        }
    }
    const totalDiagnosisLength = Object.keys(props.diagnosisVariable)
    .filter(key => key.endsWith('List'))
    .reduce((total, key) => total + props.diagnosisVariable[key].length, 0);


    const handleProcedureActivityViewIcon = (e) => {
        seProcedureActivityAnchor(e.currentTarget);
        setIsProcedureActivityViewOpen(true);
    }

    const handleCloseProcedureActivityView = () => {
        seProcedureActivityAnchor(null);
        setIsProcedureActivityViewOpen(false);
    }

    return (
        <div className="procedure_box">
            {propindex > 0 && !paymentDone && (
                <IconButton className='float-right' style={{ marginTop: 8, marginRight: 8 }} onClick={() => props.removeProcedure(propindex)}><DeleteIcon /></IconButton>
            )}
            <Form >
                <div className="" style={propindex > 0 ? { marginLeft: 15, paddingRight: 20, paddingTop: 30 } : { marginLeft: 15, paddingRight: 20, paddingTop: 20 }} >
                    <div className="justify-space-between">
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CalendarInput name={"dos_from"} label={i18n.t("searchClaims.professionalClaims.serviceLine.dateOfService_Start")}
                                    selected={procedure.dos_from} required={true} 
                                    maxDate={props.patientDeathDate ??  new Date()} 
                                    id="dos_from"
                                    minDate={saveMode !== 'Save' ? claimInfoDetails?.patient_dob : commonData?.patient_dob}
                                    onValueChange={(value) => onProcedureDateHandleChange(value, "dos_from", propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CalendarInput name={"dos_from"} label={i18n.t("searchClaims.professionalClaims.serviceLine.dateOfService_End")}
                                    selected={procedure.dos_to} required={true}
                                    maxDate={props.patientDeathDate ?? new Date()} 
                                    id="dos_to"
                                    minDate={saveMode !== 'Save' ? claimInfoDetails?.patient_dob : commonData?.patient_dob}
                                    onValueChange={(value) => onProcedureDateHandleChange(value, "dos_to", propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={procedure.cpt_hcpcs ? "" : "input-error"}
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => props.onProcedureCPTHandleChange(e, "cpt_hcpcs", propindex)}
                                    options={cptList}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")}
                                    onSearch={onSearchCPTCodes}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    selected={procedure.cpt_hcpcsList}
                                    onMenuToggle={() => {
                                        if (procedure.cpt_hcpcsList) {
                                            onSearchCPTCodes()
                                        }
                                    }}
                                    clearButton={false}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div onClick={() => props.onProcedureCPTHandleChange([{ 'id': option.id, 'name': option.name, 'description': option.short_description }], 'cpt_hcpcs', propindex)}>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div onClick={() => props.onProcedureCPTHandleChange([{ 'id': option.id, 'name': option.name, 'description': option.short_description }], 'cpt_hcpcs', propindex)}>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }}

                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.pos")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    id={"procedure_pos_code" + propindex}
                                    name={"procedure_pos_code" + propindex}
                                    className={procedure?.pos_id_List[0]?.id ? "" : "input-error"}
                                    labelKey={"name"}
                                    minLength={1}
                                    required={true}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onSearch={getPosCodes}
                                    options={posCodeList}
                                    onBlur={(e) => props.onBlur(e, propindex, procedure.pos_id_List)}
                                    onChange={(e) => props.posHandleChange(e, propindex)}
                                    selected={procedure.pos_id_List}
                                    onKeyDown={(e) => selectOnTabPOS(e, propindex)}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.pos")}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    onMenuToggle={() => {
                                        if (!procedure.pos_id_List.length) return;
                                        const isItemAlreadyInList = posCodeList.some(item => item.id === procedure.pos_id_List[0].id);
                                        if (!isItemAlreadyInList) {
                                            getPosCodes(procedure.pos_id_List[0].id);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.tos")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    id={"procedure_tos_code" + propindex}
                                    name={"procedure_tos_code" + propindex}
                                    labelKey={"name"}
                                    minLength={1}
                                    onSearch={getTosCodes}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    options={tosCodeList}
                                    onChange={(e) => props.tosHandleChange(e, propindex)}
                                    selected={procedure.tos_id_List}
                                    onKeyDown={(e) => selectOnTabTos(e, propindex)}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.tos")}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    onMenuToggle={() => {
                                        if (!procedure.tos_id_List.length) return;
                                        const isItemAlreadyInList = tosCodeList.some(item => item.id === procedure.tos_id_List[0].id);
                                        if (!isItemAlreadyInList) {
                                            getTosCodes(procedure.tos_id_List[0].id);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <div className="form-group" >
                                <SelectInput
                                    data={EmgList}
                                    name={"emg"} value={procedure.emg} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.emg")} selectOptionRemove={true} />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.modifiers")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={!inputM1Validated ? "input-error" : ""}
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => props.onProcedureModifierHandleChange(e, "m1_id", propindex)}
                                    options={modifierList1}
                                    placeholder="M1"
                                    onInputChange={validatingM1}
                                    onSearch={onSearchModifiers1}
                                    onMenuToggle={() => {
                                        if (!procedure?.m1List?.length) return;
                                        const isItemAlreadyInList = modifierList1.some(item => item.name === procedure.m1List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers1(procedure.m1List)
                                        }
                                    }}
                                    selected={procedure.m1List}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM2Validated ? "" : "input-error"}
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => props.onProcedureModifierHandleChange(e, "m2_id", propindex)}
                                    options={modifierList2}
                                    placeholder="M2"
                                    onInputChange={validatingM2}
                                    onSearch={onSearchModifiers2}
                                    selected={procedure.m2List}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    onMenuToggle={() => {
                                        if (!procedure?.m2List?.length) return;
                                        const isItemAlreadyInList = modifierList2.some(item => item.name === procedure.m2List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers2(procedure.m2List)
                                        }
                                    }}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM3Validated ? "" : "input-error"}
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => props.onProcedureModifierHandleChange(e, "m3_id", propindex)}
                                    options={modifierList3}
                                    placeholder="M3"
                                    onInputChange={validatingM3}
                                    onSearch={onSearchModifiers3}
                                    selected={procedure.m3List}
                                    onMenuToggle={() => {
                                        if (!procedure?.m3List?.length) return;
                                        const isItemAlreadyInList = modifierList3.some(item => item.name === procedure.m3List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers3(procedure.m3List)
                                        }
                                    }}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM4Validated ? "" : "input-error"}
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => props.onProcedureModifierHandleChange(e, "m4_id", propindex)}
                                    options={modifierList4}
                                    placeholder="M4"
                                    onInputChange={validatingM4}
                                    onSearch={onSearchModifiers4}
                                    selected={procedure.m4List}
                                    onMenuToggle={() => {
                                        if (!procedure?.m4List?.length) return;
                                        const isItemAlreadyInList = modifierList4.some(item => item.name === procedure.m4List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers4(procedure.m4List)
                                        }
                                    }}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="justify-space-between">
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <TextInput 
                                    type="text" 
                                    name={"dx_pointers"} 
                                    required={true} 
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.DXPointers")}
                                    value={procedure.dx_pointers ? procedure.dx_pointers : ''}
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                    maxLength={totalDiagnosisLength}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <TextInput 
                                    type="text" 
                                    name={"fee"} 
                                    required={true}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.fee")}
                                    disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.fee ? procedure.fee : ''} 
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group" >
                                <TextInput 
                                    type="text" 
                                    name={"fee_units"} 
                                    required={true}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.units")} 
                                    disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.fee_units ? procedure.fee_units : ''} 
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CurrencyInputs 
                                    type="text" 
                                    name={"charges"} 
                                    required={true} 
                                    isAdornment={false}
                                    defaultClassName={procedure.cpt_hcpcs && procedure.charges == 0 ? "input-error" : ""}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.charges")} 
                                    disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.charges ? procedure.charges : ''} 
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <div className="form-group">
                                <SelectInput 
                                    data={EmgList} 
                                    name={"cpt_epsdt"}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.espdt")}
                                    value={procedure.cpt_epsdt} 
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group" >
                                <SelectInput 
                                    data={responsibility} 
                                    name={"responsibility_type"} 
                                    selectOptionRemove={true}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.responsibility")}
                                    value={props.procedure.responsibility_type} 
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <SelectInput 
                                    className={!procedure?.claim_status  ? "input-error" : ''}
                                    data={claimStatusList?.filter(item => item.priorities?.includes(props.procedure?.responsibility_type))} 
                                    name="claim_status"
                                    label={i18n.t("searchClaims.professionalClaims.claimStatus")}
                                    value={procedure.claim_status}
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <SelectInput
                                    className={claimSubStatusList.filter(item => item.parent_claim_status_ids?.includes(procedure?.claim_status)).length && !procedure.claim_sub_status ? "input-error": ""}
                                    data={claimSubStatusList?.filter(item => item.parent_claim_status_ids?.includes(procedure?.claim_status))} 
                                    name="claim_sub_status"
                                    label={i18n.t("searchClaims.professionalClaims.claimSubStatus")}                           
                                    value={procedure.claim_sub_status}
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="procedure-insurance-message-icon">
                            {
                                (props.claimPK && procedure.insurance_message) &&
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip style={{ opacity: 0.5 }}>
                                        {procedure.insurance_message}
                                    </Tooltip>}
                                >
                                    <IconButton sx={{ background: 'transparent', borderRadius: '8px' }} className='float-right' ><InfoOutlinedIcon fontSize='large' /></IconButton>
                                </OverlayTrigger>
                            }
                        </div>
                        <div className="procedure-insurance-message-icon">
                                {
                                    props.claimPK &&
                                    <>
                                        <OverlayTrigger
                                            placement={'top'}
                                            delay={{ show: 250 }}
                                            overlay={<Tooltip id={`tooltip-right-activity-${props?.procedure?.id}`} style={{ opacity: 0.5 }}>Procedure Activity Timeline</Tooltip>}
                                        >
                                            <IconButton sx={{ background: 'transparent', borderRadius: '8px' }} className='float-right' onClick={(e) => handleProcedureActivityViewIcon(e)}><HistoryIcon fontSize='large' /></IconButton>
                                        </OverlayTrigger>
                                        <ProcedureActivityView
                                            procedurePk={props?.procedure?.id}
                                            openActivityView={isProcedureActivityViewOpen}
                                            anchorEl={procedureActivityAnchor}
                                            onClose={handleCloseProcedureActivityView}
                                        />
                                    </>
                                }
                        </div>
                    </div>
                    {expand ?
                        <div className="justify-space-between margin-top20">
                            <div className="input-procedure-text">
                                    <TextInput type="text" name={"proced_description"}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.procedDesc")}
                                        value={procedure.proced_description} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                    <TextInput type="text" name={"line_note"}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.lineNote")}
                                        defaultClassName={line_note_mandatory_line ? "input-error" : ""}
                                        value={procedure.line_note} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                <div className="form-group" >
                                    <TextInput
                                        type="text"
                                        name={"drug_code"}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.drugCode")}
                                        value={formatDrugCode(procedure?.drug_code)} 
                                        onValueChange={(e) => {
                                            const formattedValue = handleDrugCodeValidation(e.target.value); 
                                            props.onProcedureHandleChange({ target: { name: 'drug_code', value: formattedValue } }, propindex); 
                                        }}
                                        defaultClassName={validDrugCode ? '' : "input-error" }
                                        helperText={!validDrugCode ? i18n.t("validations.errors.drugCode") : ''}
                                    />
                                </div>
                            </div>
                            <div className="input-procedure-text">
                                <SelectInput data={DRUG_UNITS}
                                    className={(procedure.drug_code && !procedure.drug_unit_type) ? "input-error" : ""}
                                    name={"drug_unit_type"} label={i18n.t("searchClaims.professionalClaims.serviceLine.drugUnits")}
                                    value={procedure.drug_unit_type} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)} />
                            </div>
                            <div className="input-procedure-text60">
                                <TextInput type={"text"} name={"drug_unit_value"}
                                    defaultClassName={(procedure.drug_code && !procedure.drug_unit_value) ? "input-error" : ""}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                    value={procedure.drug_unit_value} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                <SelectInput data={MEASUREMENT_UNITS} label={i18n.t("searchClaims.professionalClaims.serviceLine.measurements")}
                                    name={"measurement_unit_type"} value={procedure.measurement_unit_type}
                                    onValueChange={(e) => props.onProcedureHandleChange(e, propindex)} />
                            </div>
                                    <div className="input-procedure-text60">
                                        <div className="form-group" >
                                            <TextInput type="text" name={"measurement_unit_value"}
                                                defaultClassName={(procedure.measurement_unit_type && !procedure.measurement_unit_value) ? "input-error" : ""}
                                                label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                                value={procedure.measurement_unit_value} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                    />
                                </div>
                            </div>
                            <div className="input-procedure-text_anthesia">
                                <div className="form-group" >
                                    <TextInput type="text" name={"anesthesia"}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.anesthesia")}
                                        value={procedure.anesthesia} onValueChange={(e) => props.onProcedureHandleChange(e, propindex)}
                                    />
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
            </Form>
            <div className="expan_box" onClick={() => setExpand(!expand)}>
                {expand ? <IconButton><ExpandLessIcon style={{ fontSize: 30 }} /></IconButton> : <IconButton onClick={() => setExpand(!expand)}> <ExpandMoreIcon style={{ fontSize: 30 }} /></IconButton>}
            </div>
        </div>
    )
    // })
}



export default Procedures;