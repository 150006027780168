import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';

import LoadingContext from '../../../container/loadingContext';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import i18n from '../../../utilities/i18n';
import { getStorage} from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_CLAIM_STATUSES } from '../../../utilities/staticConfigs';

import service from './service';
import { ClaimStatusTableObject, ClaimStatusTableBodyOnly } from './ClaimStatusTable';
import CommonButton from '../../commons/Buttons';

const ClaimStatusList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [ClaimStatusType, setClaimStatusType] = useState('');
    const [deleteClaimStatusName, setDeleteClaimStatusName] = useState('');
    const [deleteClaimStatusId, setDeleteClaimStatusId] = useState('');
    const [editClaimStatusId, setEditClaimStatusId] = useState('');
    const [ClaimStatusTypeList, setClaimStatusTypeList] = useState([]);
    const [form_error, setFormError] = useState({ 'ClaimStatusType': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
   
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getClaimStatusData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getClaimStatusData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getClaimStatusData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.dictClaimStatus.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeleteClaimStatusName('');
        setDeleteClaimStatusId('');
        setShowDeleteModalWindow(false);
    }

    function getClaimStatusData(pageSize, page, searchString) {
        setShowLoadingBar(true);

        let result = service.ListClaimStatusTypes(pageSize, page, isAdminModule, isAdminModule === 'true' ? '' : practicePK, searchString);
        result.then(async response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
			if(response.data?.results) {
				response.data.results = response.data.results?.map(item => {
					if (item.not_delete === true) {
						item.isDefault = "Yes";
					} else {
						item.isDefault = "No";
					}
					return item;
				});
			}
			const rowArray = commonTableBody(response.data.results, ClaimStatusTableObject.tableBodyData[0]);
                    if(rowArray.length > 0) {
                ClaimStatusTableObject.tableBodyData = rowArray;
            }
            else {
                ClaimStatusTableObject.tableBodyData = ClaimStatusTableBodyOnly;
            }

            setClaimStatusTypeList(response.data.results);
        });
    }

/**
 * Function to display or map claimstatus 
 * @param {*} results 
 * @returns 
 */

    useEffect(() => {
        getClaimStatusData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_CLAIM_STATUSES){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege,))
        }
    }, []);
    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
		// default claimStatus are not allowed to edit or delete from the database;
		const isDefaultClaim = ClaimStatusTypeList.some((item) => {
			if (item.id === id && item.not_delete) {
				return true;
			} else {
				return false;
			}
		});
		if (isDefaultClaim) {
			showNotifyWindow('show', 'error', i18n.t('dictionariesPages.dictClaimStatus.editRestrictionMessage'));
			return;
		}
		// if the claimStatus is not self then proceed;
		if (name.toLowerCase() == 'edit') {
            onEditClaimStatusType(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteClaimStatusType(Number(id));
        }
    }
   

    function onEditClaimStatusType(ClaimStatusId) {
        setHeader(i18n.t("dictionariesPages.dictClaimStatus.editHeader"));
        setShowLoadingBar(true);
        /**checks whether isAdminModule is true or not */
       const data = service.GetClaimStatusType(ClaimStatusId,isAdminModule === 'true' ? '' : practicePK);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setClaimStatusType(response.data.name);
            setEditForm(true);
            setEditClaimStatusId(ClaimStatusId);
        });
    }

    function onDeleteClaimStatusType(ClaimStatusId) {
        ClaimStatusTypeList.map((item) => {
            if (item.id == parseInt(ClaimStatusId)) {
                setDeleteClaimStatusName(item.name);
                setDeleteClaimStatusId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        /**
         checks whether isAdminModule is true or false and then removes it 
         * 
         */
       const data = service.DeleteClaimStatusType(deleteClaimStatusId, isAdminModule === 'true' ? '' : practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteClaimStatusName('');
            setDeleteClaimStatusId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getClaimStatusData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setClaimStatusType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['ClaimStatusType']: ''
        });

    }

    function onSaveFormData(e) {
        e.preventDefault();
        if (!ClaimStatusType.trim()) {
            setFormError({
                ...form_error,
                ['ClaimStatusType']: 'error'
            });
        } else {
            setShowLoadingBar(true);
            let data = isAdminModule === 'true' ? { name: ClaimStatusType } : { practice: practicePK, name: ClaimStatusType }
            const result = UpdateClaimStatusType(editClaimStatusId, data);
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    getClaimStatusData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
        }
    }

/**
 * created a fun for claimUpdate and Add
 * @param {*} editClaimStatusId 
 * @param {*} data 
 * @returns 
 */
    function UpdateClaimStatusType (editClaimStatusId, data) {
        if (editForm) {
            return service.UpdateClaimStatusType(editClaimStatusId, data);
        } else {
            return service.AddClaimStatusType(data);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            onSearch(e, searchValue);
        }
    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue)
            return;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setClaimStatusType(trimmedValue);
    }

    function onSearch(e,searchString){
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        getClaimStatusData(DEFAULT_PAGING_SIZE, 1, searchString);
    }
    
    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES)
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }
    const onClose = () => {
        setSearchValue('');
        setActivePage(1)
        setStartIndex(0);
        getClaimStatusData(DEFAULT_PAGING_SIZE, activePage);
    }
    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                {isAdminModule === "true" ? (
                    <div className="box box-content-white mb-3">
                        <div className="row mr-0 mb-2">
                            <div className="col pl-4 mb-1">
                                <div className={"dataTables_filter"}>
                                    <div
                                        className="link dictionaries-back"
                                        onClick={backToDictionaryPage}>
                                        <BackArrowWithLabel
                                            label={i18n.t(
                                                "dictionariesPages.gotoDictionaries"
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-content">
                            <div className="common-search-wrapper-style">
                                <div className="input-content-box">
                                    <div className="form-group padding-top15 relative">
                                        <TextInput
                                            type="text"
                                            id="searchValue"
                                            name="searchValue"
                                            value={searchValue}
                                            onValueChange={onHandleChange}
                                            label={i18n.t(
                                                "dictionariesPages.dictClaimStatus.lblClaimStatus"
                                            )}
                                            onKeyDown={handleKeyDown}
                                            labelClassName={"margin-bottom0"}
                                            clearButton={
                                                typeof searchValue ==
                                                    "string" &&
                                                searchValue.length !== 0
                                            }
                                            clearInput={onClose}
                                        />
                                    </div>
                                </div>
                                <div className="input-content-box padding-top15">
                                    <div className="margin-top18">
                                        <CommonButton
                                            variant="contained"
                                            label="Search"
                                            onClick={(e) =>
                                                onSearch(e, searchValue)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="box box-content-white">
                        <div className="box-head pl-0 pr-0">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back pl-0"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ClaimStatusTableObject}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="ClaimStatusType"
                                        name="ClaimStatusType"
                                        label={i18n.t(
                                            "dictionariesPages.dictClaimStatus.lblClaimStatus"
                                        )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.ClaimStatusType
                                                ? "input-error"
                                                : ""
                                        }
                                        value={ClaimStatusType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictClaimStatus.deletConfirmation") +
                    "'" +
                    deleteClaimStatusName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}
export default ClaimStatusList