import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'; // Accordion,Card,
import { Redirect, Link } from 'react-router-dom';

import './style.css';
import service from './service';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { US_STATES } from '../../../utilities/usStates';
import { getStorage } from '../../../utilities/browserStorage';
import { permission_key_values_accountsetup } from '../../../utilities/permissions';
import { ValidateEmail, checkPermission } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import { PROVIDER_TYPES, PROVIDER_TYPE_INDIVIDUAL, PROVIDER_TYPE_ORGANIZATION } from "../../../utilities/dictionaryConstants";
import { ROUTE_PROVIDER_MANAGEMENT, SSN_VALID_LENGTH, SSN_VALID_LENGTH_SMALL, DEFAULT_COUNTRY } from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch';
import EmailInput from '../../commons/EmailInput/EmailInput';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import NPISearch from '../../commons/NPISearch/NPISearch';
import Multiselect from '../../../multiselect/src/index'; // NEW COMPONENT FOR SEARCHABLE SINGLE/MULTI SELECT https://github.com/rowyio/multiselect
import { CityInput } from '../../commons/input/CityInput';

const ProviderAdd = (props) => {
    const [editForm, setEditForm] = useState(false);
    const [providerId, setProviderId] = useState('');
    const [customProviderId, setCustomProviderId] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleInitial, setMiddleInitial] = useState('');
    const [credentials, setCredentials] = useState('');
    const [mSelTaxonomyList, setMSelTaxonomyList] = useState([]);
    const [taxonomy, setTaxonomy] = useState('');
    const [providerType, setProviderType] = useState(PROVIDER_TYPE_INDIVIDUAL);
    const [providerTypeLabel, setProviderTypeLabel] = useState(i18n.t("customerSetup.provider.labelLastName"));
    const [ssn, setSsn] = useState('');
    const [npi, setNpi] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [phone1, setPhone1] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [stateLicense, setStateLicense] = useState('');
    const [medicarePTAN, setMedicarePTAN] = useState('');
    const [medicaidNumber, setMedicaidNumber] = useState('');
    const [billUnder, setBillUnder] = useState('');
    const [bcbsProviderID, setBcbsProviderID] = useState('');
    const [tricareProviderID, setTricareProviderID] = useState('');
    const [uhcProviderID, setUhcProviderID] = useState('');
    const [commercialProviderID, setCommercialProviderID] = useState('');
    const providerTypeList = PROVIDER_TYPES;
    const [editProviderID, setEditProviderID] = useState('');
    const [sameAsPractice, setSameAsPractice] = useState(false);
    const [gotoList, setGotoList] = useState(false);
    const practicePK = getStorage("practice");
    const [inactive, setInactive] = useState(false);
    const [inactiveProviderModal, setInactiveProviderModal] = useState(false);
    const [validSSN, setValidSSN] = useState(true);
    const [providerSetAsDefault, setProviderSetAsDefault] = useState(false);

    //START ==> MODAL WINDOW PROPERTIES 
    const [showModalWindow, setShowModalWindow] = useState(false);

    const [form_error, setFormError] = useState({
        'lastName': '', 'firstName': '', 'credentials': '', 'npi': '', 'referringProviderGroup': '',
        'street1': '', 'city': '', 'state': '', 'zip': '', 'stateLicense': '', 'medicarePTAN': '',
        'medicaidNumber': '', 'billUnder': '', 'liveMode': '', 'address1': ''
    })

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }

    }
    //End ====


    const handleMultiselect = (value) => {
        setTaxonomy(value ?? '');
    };
    

    function onHandleChangeSSN(e) {
        const value = String(e.target.value.trim()).replace(/[^\d]/g, '').substr(0, 9);
        setSsn(value);
        setValidSSN(value.length === SSN_VALID_LENGTH || value.length === SSN_VALID_LENGTH_SMALL || !value);
    }


    function onHandleChange(e) {
        const { name, checked } = e.target;
        let value =
                e.target.type === "checkbox" ? e.target.checked : e.target.value;
        
        const validNames = [
            'firstName', 'middleInitial', 'lastName','credentials',
            'city', 'stateLicense', 'medicarePTAN', 'medicaidNumber',
             'bcbsProviderID','tricareProviderID', 'uhcProviderID', 'commercialProviderID'
        ];

        if (validNames.includes(name)) {
            value = value.replace(/[^A-Za-z0-9.\s]/g, '');
        }

            let trimmedValue = value;

        switch (name) {
            case 'firstName':
                setFirstName(trimmedValue);
                break;
            case 'middleInitial':
                setMiddleInitial(trimmedValue);
                break;
            case 'lastName':
                setLastName(trimmedValue);
                if(trimmedValue=="" || trimmedValue ==null)
                {
                setFirstName("");
                setMiddleInitial("");
                }
                if (!trimmedValue) {
                    setNpi('');
                }
                break;
                
            case 'credentials':
                setCredentials(trimmedValue);
                break;
            case 'npi':
                setNpi(trimmedValue);
                break;
            case 'providerType':
                setProviderType(trimmedValue);
                setProviderTypeLabel(
                    trimmedValue === PROVIDER_TYPE_ORGANIZATION
                        ? i18n.t('customerSetup.provider.labelProviderTypeOrg')
                        : i18n.t('customerSetup.provider.labelLastName')
                );
                break;
            case 'address1':
                setAddress1(trimmedValue);
                break;
            case 'address2':
                setAddress2(trimmedValue);
                break;
            case 'city':
                setCity(trimmedValue);
                break;
            case 'state':
                setState(trimmedValue);
                break;
            case 'zip':
                setZip(trimmedValue);
                break;
            case 'country':
                setCountry(trimmedValue);
                break;
            case 'phone1':
                setPhone1(trimmedValue);
                break;
            case 'fax':
                setFax(trimmedValue);
                break;
            case 'email':
                setEmail(trimmedValue);
                break;
            case 'stateLicense':
                setStateLicense(trimmedValue);
                break;
            case 'medicarePTAN':
                setMedicarePTAN(trimmedValue);
                break;
            case 'medicaidNumber':
                setMedicaidNumber(trimmedValue);
                break;
            case 'billUnder':
                setBillUnder(trimmedValue);
                break;
            case 'ssn':
                setSsn(trimmedValue);
                break;
            case 'bcbsProviderID':
                setBcbsProviderID(trimmedValue);
                break;
            case 'tricareProviderID':
                setTricareProviderID(trimmedValue);
                break;
            case 'uhcProviderID':
                setUhcProviderID(trimmedValue);
                break;
            case 'commercialProviderID':
                setCommercialProviderID(trimmedValue);
                break;
            case 'chkSameAsPractice':
                setSameAsPractice(checked);
                if (checked) {
                    getPracticeDefaultLocation();
            }
                break;
            default:
                break;
        }
    }


    function getPracticeDefaultLocation() {
        setShowLoadingBar(true);
        const result = service.PracticeLocation(practicePK);
        result.then(response => {
            if (response.data.practice_location) {
                setAddress1(response.data.practice_location.street1);
                setAddress2(response.data.practice_location.street2);
                setCity(response.data.practice_location.city);
                setState(response.data.practice_location.state);
                setZip(response.data.practice_location.zip_code);
                setCountry(response.data.practice_location.country);
                setPhone1(response.data.phone);
                setFax(response.data.fax);
                setEmail(response.data.email);
            }
            setShowLoadingBar(false);
        });
    }

    const getTaxonomy = (pageSize, pageNum) => {
        const result = service.Taxonomy(pageSize, pageNum);
        result.then(response => {
            let tmpObjArr = [];
            if (Array.isArray(response.data) && response.data.length) {
                response.data.forEach((item) => {
                    tmpObjArr.push({ "value": item.id, "label": item.full_name });
                })
                setMSelTaxonomyList(tmpObjArr);
            }
            else {
                setMSelTaxonomyList(tmpObjArr);
            }
        });
    }

    useEffect(() => {
        getTaxonomy(20, 0);
    }, []);

    useEffect(() => {
        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            setProviderId(props.location.state.selectedID);
            setEditProviderID(props.location.state.selectedID);
            getEditProvidersData(props.location.state.selectedID);
        }
    }, []);

    useEffect(() => {
        if (zip) {
            setFormError({
                ...form_error,
                ['zip']: ''
            });
        } 
        if (country) {
            setFormError({
                ...form_error,
                ['country']: ''
            });
        } 
        
        if (state) {
            setFormError({
                ...form_error,
                ['state']: ''
            });
        }

    }, [zip, country, state])

    async function getEditProvidersData(providerId) {
        setShowLoadingBar(true);
        try {
            const response = await service.getEditProvidersData(providerId);
            setShowLoadingBar(false);
            const data = response.data;
            const locationData = data.location_data;
            setCustomProviderId(data.custom_provider_id);
            setProviderId(data.id);
            setFirstName(data.first_name);
            setMiddleInitial(data.middle_name);
            setLastName(data.last_name);
            setCredentials(data.credentials);
            setNpi(data.npi);
            setTaxonomy(data.taxonomy);
            setProviderType(data.provider_type);
            setProviderTypeLabel(getProviderTypeLabel(data.provider_type));
            setSsn(data.ssn);
            setAddress1(locationData?.street1);
            setAddress2(locationData?.street2);
            setCity(locationData?.city);
            setState(locationData?.state);
            setZip(locationData?.zip_code);
            setCountry(locationData?.country);
            setPhone1(data.phone);
            setFax(data.fax);
            setEmail(data.email);
            setStateLicense(data.state_license);
            setMedicarePTAN(data.medicare_ptan);
            setMedicaidNumber(data.medicaid_number);
            setBcbsProviderID(data.bcbs_provider_id);
            setTricareProviderID(data.tricare_provider_id);
            setUhcProviderID(data.uhc_provider_id);
            setCommercialProviderID(data.commercial_provider_id);
            setBillUnder(data.bill_under);
            setSameAsPractice(data.address_same_as_practice);
            setInactive(!data.active);
            setProviderSetAsDefault(data.default_provider);
            setEditForm(true);
        } catch (error) {
            setShowLoadingBar(false);
            showNotifyWindow('show', 'error', 'An unexpected error occurred');
        }
    }

    function getProviderTypeLabel(providerType) {
        return providerType === PROVIDER_TYPE_ORGANIZATION
            ? i18n.t("customerSetup.provider.labelProviderTypeOrg")
            : i18n.t("customerSetup.provider.labelLastName");
    }


    function onSaveFormData(e) {
        e.preventDefault();

        if (ssn && ssn.length !== SSN_VALID_LENGTH && ssn.length !== SSN_VALID_LENGTH_SMALL) {
            return;
        }
       if(taxonomy==''){
        return;
       }
        if (!phone1) {
            return;
        }

        const email_validation = ValidateEmail(email);
        if (!email_validation) {
            return;
        }

        setShowLoadingBar(true);
        let data = {
            custom_provider_id: providerId,
            practice: practicePK,
            first_name: firstName,
            middle_name: middleInitial,
            last_name: lastName,
            credentials: credentials,
            npi: npi,
            taxonomy: taxonomy,
            provider_type: providerType,
            ssn: ssn,
            location: {
                street1: address1,
                street2: address2,
                city: city,
                zip_code: zip,
                state: state,
                country: country
            },
            phone: phone1,
            fax: fax,
            email: email,
            state_license: stateLicense,
            medicare_ptan: medicarePTAN,
            medicaid_number: medicaidNumber,
            bcbs_provider_id: bcbsProviderID,
            tricare_provider_id: tricareProviderID,
            uhc_provider_id: uhcProviderID,
            commercial_provider_id: commercialProviderID,
            bill_under: billUnder,
            address_same_as_practice: sameAsPractice,
            inactive: inactive,
            default_provider: providerSetAsDefault
        };

        let result = editForm
            ? service.UpdateProvider(data, editProviderID)
            : service.AddProvider(data);

        result
            .then((response) => {
               
                let message, type;
                if (response.status === 201) {
                    message = ADD_SUCCESS;
                    type = 'success';
                } else if (response.status === 200) {
                    message = UPDATE_SUCCESS;
                    type = 'success';
                } else if (response.status === 400 && response.data.message !== undefined) {
                    message = i18n.t('errorMessages.record_exists');
                    type = 'error';
                    showNotifyWindow('show', type, message);
                    setTimeout(() => {
                        setShowLoadingBar(false);
                    }, 1000);
                    return;
                } else {
                    message = ADD_ERROR;
                    type = 'error';
                }
                
                showNotifyWindow('show', type, message);
                setTimeout(() => {
                    setShowLoadingBar(false);
                    setGotoList(true);
                }, 1000);
            })
            .catch(() => {
                showNotifyWindow('show', 'error', ADD_ERROR);
                setGotoList(false);
            });
    }


    function showSearchNPI() {
        setShowModalWindow(true);
    }

    function redirectToList() {
        setGotoList(true);
    }
    function onHide() {
        setShowModalWindow(false);
    }

    function setSelectedNPI(npiData) {

        const {
            NPINum,
            NPIMiddleName,
            NPILastName,
            NPIFirstName, 
            NPIOrgName,
        } = npiData;


        setNpi(NPINum);

        if (providerType == PROVIDER_TYPE_INDIVIDUAL) {
            if (NPIFirstName && NPIFirstName.length > 0) setFirstName(NPIFirstName);
            if (NPIMiddleName && NPIMiddleName.length > 0) setMiddleInitial(NPIMiddleName);
            if (NPILastName && NPILastName.length > 0) setLastName(NPILastName);
        }
        else if (providerType == PROVIDER_TYPE_ORGANIZATION) {
            if (NPIOrgName && NPIOrgName.length > 0) setLastName(NPIOrgName);
        }

        setShowModalWindow(false);
    }

    function changeProviderActive(e) {
        if (e.target.checked){
            if(providerSetAsDefault){
                showNotifyWindow('show','error',  i18n.t('errorMessages.set-as-default-provider'));
                return;
            }
            else {
         setInactive(true);
        }
        }
        else setInactive(false);
    }

    function changeProviderSetAsDefault(e) {
        if (e.target.checked) setProviderSetAsDefault(true);
        else setProviderSetAsDefault(false);
    }

    function onInactiveProvider(e) {
        e.preventDefault();
        setInactiveProviderModal(true);
    }


    if (gotoList) {
        return <Redirect to={{ pathname: ROUTE_PROVIDER_MANAGEMENT }} />;
    }

    const billUnderList = [{ id: 1, name: "Individual" }, { id: 2, name: "Group" }];

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box box-content-white margin-right15">
                    <Form id="form_dataEntry" autoComplete="off" onSubmit={inactive ? (e) => onInactiveProvider(e) : (e) => onSaveFormData(e)} encType="multipart/form-data">
                        <Form.Group>
                            <div className="col-md-12">
                                <div className="box padding-left5">
                                    <div className="box-head">
                                        <div className="padding-top15">
                                            <div className="">
                                                <div className="basic-info-padding">
                                                    <div className="padding-right5">
                                                        <div className="row ">

                                                            <div className="col-2">
                                                                <div className="row"></div>
                                                                <div className="" >
                                                                    <SelectInput data={providerTypeList} label={i18n.t('customerSetup.provider.labelProviderType')} name="providerType" id="providerType" value={providerType} setValue={setProviderType} onValueChange={onHandleChange} required={true} selectOptionRemove={true} />
                                                                </div>
                                                            </div>
                                                            <div className={providerId ? "col-3" : "col-4"}>
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="lastName" name="lastName" label={providerTypeLabel} onValueChange={onHandleChange} required={true} value={lastName} />
                                                                </div>
                                                            </div>
                                                            {providerId &&
                                                            <div className="col-3">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="customProviderId" name="customProviderId" label={i18n.t('customerSetup.provider.labelProviderID')} onValueChange={onHandleChange} disabled={true} value={customProviderId} />
                                                                </div>
                                                            </div>}

                                                            {providerType == PROVIDER_TYPE_INDIVIDUAL && <div className={providerId ? "col-2" : "col-3"}>
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="firstName" name="firstName" label={i18n.t("customerSetup.provider.labelFirstName")} onValueChange={onHandleChange} required={true} value={firstName} />
                                                                </div>
                                                            </div>}

                                                            {providerType == PROVIDER_TYPE_INDIVIDUAL && <div className={providerId ? "col-2" : "col-3"}>
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="middleInitial" name="middleInitial" label={i18n.t("customerSetup.provider.labelMiddleInitial")} onValueChange={onHandleChange} value={middleInitial} />
                                                                </div>
                                                            </div>}

                                                        </div>

                                                        <div className="row">

                                                            <div className="col-2">
                                                                    <div className="div-float-right searchNpi" style={{ position: "absolute", right: "4px" }} onClick={showSearchNPI} >
                                                                        <Link to="#">{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                                                    </div>
                                                                {(lastName.trim().length === 0 || !providerType) &&
                                                                    <div className="div-float-right disabledLabel" style={{ position: "absolute", right: "4px" }}>
                                                                        <Link to="#" className="disabledLabel" disabled={true} >{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                                                    </div>
                                                                }

                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="npi" name="npi" label={i18n.t("userPages.practices.labelNPI")} onValueChange={onHandleChange} required={true} value={npi} rightLink={i18n.t("userPages.practices.labelSearchNPI")} disabled={true} />
                                                                </div>
                                                            </div>

                                                            <div className="col-3 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="credentials" name="credentials" label={i18n.t("customerSetup.provider.labelCredentials")} onValueChange={onHandleChange} value={credentials} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3 ">
                                                                <div className="row"></div>
                                                           
                                                               
                                                                <div className={"form-group " + (!taxonomy? "input-error" : "")}>
                                                                    <Multiselect value={taxonomy}
                                                                        onChange={handleMultiselect}
                                                                        multiple={false}
                                                                        id={"TaxonomySelect"}
                                                                        options={mSelTaxonomyList}
                                                                        label={i18n.t('customerSetup.provider.labelTaxonomy')}
                                                                        labelPlural={i18n.t('customerSetup.provider.labelTaxonomy')}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-2 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput helperText={!validSSN ? i18n.t("validations.errors.ssn") : ''} type="text" id="ssn" name="ssn" label={i18n.t("customerSetup.provider.labelSSN")} onValueChange={onHandleChangeSSN} className={form_error.ssn ? 'input-error' : ""} value={ssn} defaultClassName={validSSN ? '' : 'input-error'} />
                                                                    {/* {validSSN ? '' : <div className='text-red small margin-top-m7'>{i18n.t("validations.errors.ssn")}</div>} */}
                                                                </div>
                                                            </div>


                                                            <div className="col-2  padding-top15">
                                                                <div className="form-group">
                                                                    <div className="custom-checkbox ">
                                                                        <input type="checkbox" id="chkProviderSetAsDefault" name="chkProviderSetAsDefault" checked={providerSetAsDefault} onChange={(e) => changeProviderSetAsDefault(e)} />
                                                                        <label className="checkbox cursor-pointer" htmlFor="chkProviderSetAsDefault"></label>
                                                                        <label htmlFor="chkProviderSetAsDefault" className="chk-label cursor-pointer">{i18n.t('userPages.practices.labelSetAsDefault')}</label>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div className="row">
                                                            <div className="div-inline padding-top10">
                                                                <label className="margin-top-3px padding-left12 padding-right5">{i18n.t('customerSetup.provider.labelAddress')}</label>
                                                            </div>
                                                            <div className="max-width-3">
                                                                <div className="form-group">
                                                                    <div className="custom-checkbox lh-0">
                                                                        <input type="checkbox" id="chkSameAsPractice" name="chkSameAsPractice" checked={sameAsPractice} onChange={onHandleChange} />
                                                                        <label className="checkbox top-14 cursor-pointer " htmlFor={'chkSameAsPractice'} ></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="padding-top5 padding-left20">
                                                            <label className="padding-top5 margin-top-3px cursor-pointer" htmlFor={'chkSameAsPractice'}>{i18n.t('customerSetup.provider.labelSameAsPractice')}</label>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group ">
                                                                    <TextInput disabled={sameAsPractice} className={form_error.address1 ? 'input-error' : ""} type="text" id="address1" name="address1" label={i18n.t("customerSetup.provider.labelAddress1")} onValueChange={onHandleChange} required={true} value={address1} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" disabled={sameAsPractice} className={form_error.address2 ? 'input-error' : ""} id="address2" name="address2" label={i18n.t("customerSetup.provider.labelAddress2")} onValueChange={onHandleChange} value={address2} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <CityInput type="text" disabled={sameAsPractice} id="city" name="city" className={form_error.city ? 'input-error' : ""} label={i18n.t("customerSetup.provider.labelCity")} onValueChange={onHandleChange} required={true}
                                                                        value={city} setValue={setCity} zip={zip} />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <SelectInput disabled={sameAsPractice} listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')} className={form_error.state ? 'input-error' : ""}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""} required={true}
                                                                        label={i18n.t("customerSetup.provider.labelZip")} onValueChange={onHandleChange}
                                                                        setlocState={setState} setLocCity={setCity}
                                                                        setLocCountry={setCountry} setLocZipCode={setZip} defaultValue={zip} disabled={sameAsPractice} />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput disabled={sameAsPractice} type="text" id="country" name="country" className={form_error.country ? 'input-error' : ""} label={i18n.t("customerSetup.provider.labelCountry")} onValueChange={onHandleChange} required={true} value={country} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <PhoneInput disabled={sameAsPractice} id="phone1" name="phone1" className={form_error.phone1 ? 'input-error' : ""} label={i18n.t("customerSetup.provider.labelPhone1")} onValueChange={setPhone1} required={true} value={phone1} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4 ">
                                                                <div className="row"></div>
                                                                <div className="form-group">
                                                                    <PhoneInput disabled={sameAsPractice} id="fax" name="fax" className={form_error.fax ? 'input-error' : ""} label={i18n.t("customerSetup.provider.labelFax")} onValueChange={setFax} value={fax} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row"></div>
                                                                <div className="form-group padding-top3">
                                                                <EmailInput disabled={sameAsPractice} id="email" placeholder="email" name="email" required={true} label={i18n.t("customerSetup.provider.labelEmail")} onValueChange={onHandleChange} value={email} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className='row'>
                                                            <div className="col-4">
                                                                <div className="row"></div>
                                                                <div className="form-group padding-top3">
                                                                <EmailInput disabled={sameAsPractice} id="email" placeholder="email" name="email" required={true} label={i18n.t("customerSetup.provider.labelEmail")} onValueChange={onHandleChange} value={email} />
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        <div className="row">
                                                            <div className="div-inline padding-top10">
                                                                <label className="margin-top-3px padding-left12">{i18n.t('customerSetup.provider.labelProviderIDs')}</label>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="stateLicense" className={form_error.stateLicense ? 'input-error' : ""} name="stateLicense" label={i18n.t("customerSetup.provider.labelStateLicense")} onValueChange={onHandleChange} value={stateLicense} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="medicarePTAN" className={form_error.medicarePTAN ? 'input-error' : ""} name="medicarePTAN" label={i18n.t("customerSetup.provider.labelMedicarePTAN")} onValueChange={onHandleChange} value={medicarePTAN} />
                                                                </div>
                                                            </div>
                                                            <div className="col-5 ">
                                                                <div className="row"></div>
                                                                <div className="form-group ">
                                                                    <TextInput type="text" id="medicaidNumber" className={form_error.medicaidNumber ? 'input-error' : ""} name="medicaidNumber" label={i18n.t("customerSetup.provider.labelMedicaidNumber")} onValueChange={onHandleChange} value={medicaidNumber} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="bcbsProviderID" name="bcbsProviderID" label={i18n.t("customerSetup.provider.labelBcbsProviderID")} onValueChange={onHandleChange} value={bcbsProviderID} />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="tricareProviderID" name="tricareProviderID" label={i18n.t("customerSetup.provider.labelTricareProvideID")} onValueChange={onHandleChange} value={tricareProviderID} />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="uhcProviderID" name="uhcProviderID" label={i18n.t("customerSetup.provider.labelUhcProviderID")} onValueChange={onHandleChange} value={uhcProviderID} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3 ">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="commercialProviderID" name="commercialProviderID" label={i18n.t("customerSetup.provider.labelCOmmercialProviderID")} onValueChange={onHandleChange} value={commercialProviderID} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="div-inline padding-top10">
                                                                <label className="margin-top-3px padding-left12">{i18n.t('customerSetup.provider.labelBilling')}</label>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="row"></div>
                                                                <div className="">
                                                                    <SelectInput data={billUnderList} label={i18n.t('customerSetup.provider.labelBillUnder')} name="billUnder" id="billUnder" onValueChange={onHandleChange} value={billUnder} setValue={setBillUnder} required={true} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <CustomizedDialogs showModal={inactiveProviderModal} setShowModalWindow={setInactiveProviderModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes" onHide={onHide}
                                                            inactveItem={onSaveFormData} resetForm={onHide} buttonStyle={"danger"}>
                                                            {i18n.t("commons.confrimInactive") + " provider?"}
                                                        </CustomizedDialogs>
                                                        <div className="row">
                                                            {
                                                                editForm ?
                                                                    <>
                                                                        <div className="col-2">
                                                                            <div className="form-group">
                                                                                <div className="custom-checkbox">
                                                                                    <input type="checkbox" id="chkProviderInactive" name="chkProviderInactive" checked={inactive} onChange={(e) => changeProviderActive(e)} />
                                                                                    <label className="checkbox cursor-pointer" htmlFor="chkProviderInactive"></label>
                                                                                <label htmlFor="chkProviderInactive" className="chk-label cursor-pointer">{i18n.t('userPages.practices.labelInactive')}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-10">
                                                                            {checkPermission(permission_key_values_accountsetup.account_setup_provider_management_modify) &&
                                                                            <button form="form_dataEntry" className="btn btn-primary-blue float-right btn-common-height35 margin-bottom-30px width-75">{i18n.t('buttons.save')}</button>}
                                                                            <button onClick={redirectToList} className="btn btn-secondary float-right btn-common-height35 width-75 mr-2 margin-top2">{i18n.t('buttons.cancel')}</button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="col-12">
                                                                    {checkPermission(permission_key_values_accountsetup.account_setup_provider_management_add) &&  
                                                                        <button form="form_dataEntry" id="saveButton" className="btn btn-primary-blue float-right btn-common-height35 margin-bottom-30px width-75">{i18n.t('buttons.save')}</button>}
                                                                        <button onClick={redirectToList} className="btn btn-secondary float-right btn-common-height35 width-75 mr-2 margin-top2">{i18n.t('buttons.cancel')}</button>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={i18n.t("userPages.practices.labelSearchNPI")} setShowModalWindow={setShowModalWindow} onHide={onHide} resetForm={onHide} selectNPIBtn={false} hideSave={true} >
                <NPISearch setSelectedNPI={setSelectedNPI} searchNPIName={lastName} searchNPIFirstName={firstName} providerType={providerType} npi={npi} />
            </CustomizedDialogs>
        </React.Fragment>
    )

}
export default ProviderAdd