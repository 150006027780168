import i18n from "../../../utilities/i18n";

const CustomTableHeadData = [
  {
    name: i18n.t(
      "customerSetup.feeSchedule.customSchedule.tableColumn.ScheduleName"
    ),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t(
      "customerSetup.feeSchedule.customSchedule.tableColumn.effectiveDate"
    ),
    type: "date",
    width: "medium",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.action"),
    type: "button",
    width: "action_column",
    sort: false,
  },
];

const CustomTableBodyData = [
  [
    {
      id: "",
      name: "fee_schedule_name",
      value: "",
      type: "string",
      align: "left",
      width: "large",
    },
    {
      id: "",
      name: "effective_date",
      value: "",
      "type": "Date",
      "needDateFormat": "yes",
      align: "center",
      width: "medium",
    },
    {
      id: "",
      name: "action",
      type: "button",
      align: "center",
      width: "action_column",
      dropdown: [
        {
          name: "Edit",
        },
        {
          name: "Delete",
        },
      ],
    },
  ],
];
const StandardTableHeadData = [
  {
    name: i18n.t(
      "customerSetup.feeSchedule.standardSchedule.tableColumn.state"
    ),
    type: "string",
    width: "medium",
    sort: false,
  },
  {
    name: i18n.t(
      "customerSetup.feeSchedule.standardSchedule.tableColumn.locality"
    ),
    type: "string",
    width: "medium",
    sort: false,
  },
  {
    name: i18n.t(
      "customerSetup.feeSchedule.standardSchedule.tableColumn.procedureCode"
    ),
    type: "string",
    width: "medium",
    sort: false,
  },
  {
    name: i18n.t(
      "customerSetup.feeSchedule.standardSchedule.tableColumn.procedureRate"
    ),
    type: "amount",
    width: "medium",
    sort: false,
  },
  // {
  //   name: i18n.t(
  //     "customerSetup.feeSchedule.standardSchedule.tableColumn.facilityRate"
  //   ),
  //   type: "amount",
  //   width: "medium",
  //   sort: false,
  // },
];

export const StandardTableBodyData = [
  [
    {
      id: "",
      name: "state_code",
      value: "",
      type: "string",
      align: "left",
      width: "medium",
    },
    {
      id: "",
      name: "locality_name",
      value: "",
      type: "string",
      align: "left",
      width: "medium",
    },
    {
      id: "",
      name: "procedure",
      value: "",
      type: "string",
      align: "center",
      width: "medium",
    },
    {
      id: "",
      name: "procedure_rate",
      value: "",
      type: "currency",
      align: "right",
      width: "medium",
    },
    // {
    //   id: "",
    //   name: "facility_amount",
    //   value: "",
    //   type: "amount",
    //   align: "right",
    //   width: "medium",
    // },
  ],
];

export const ListCustomFeeTableData = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  tableHeadings: CustomTableHeadData,
  tableBodyData: CustomTableBodyData,
};

export const ListStandardFeeTableData = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  tableHeadings: StandardTableHeadData,
  tableBodyData: StandardTableBodyData,
};
