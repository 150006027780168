import api from '../../../service/api'

const ListTrackingStatusTypes = async(pageSize, page) =>{
	let path = 'super-admin/tracking-status-type/?page_size='+pageSize+'&page='+page
	return api.get(path);
}

const AddTrackingStatusType = async(data) =>{
	let path = 'super-admin/tracking-status-type/'
	return api.post(path,data);
}
const GetTrackingStatusType = async(trackingStatusId) =>{
	let path = 'super-admin/tracking-status-type/' + trackingStatusId
	return api.get(path);
}

const UpdateTrackingStatusType = async(trackingStatusId, data) =>{
	let path = 'super-admin/tracking-status-type/' + trackingStatusId
	return api.put(path,data);
}

const DeleteTrackingStatusType = async(trackingStatusId) =>{
	let path = 'super-admin/tracking-status-type/' + trackingStatusId
	return api.destroy(path);
}

export default {ListTrackingStatusTypes, AddTrackingStatusType, GetTrackingStatusType, UpdateTrackingStatusType, DeleteTrackingStatusType}