import React, { useEffect, useState, useContext, useMemo } from "react";
import { Form } from "react-bootstrap";
import AsyncTypeInput from '../../commons/input/AsyncTypeHead/AsyncTypeInput';
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import Table from "../../commons/Table/Table";
import Notify from "../../commons/notify";
import SelectInput from "../../commons/input/SelectInput";
import Procedures from "./Procedures";
import LoadingContext from '../../../container/loadingContext';
import i18n from "../../../utilities/i18n";
import { DataForICD, RESPONSIBILITY_TYPES, defaultIdForICD } from "../../../utilities/dictionaryConstants";
import service from "./service";
import format from 'date-fns/format';
import { getStorage } from '../../../utilities/browserStorage';
import useKeyboardShortcut from "use-keyboard-shortcut";
import { PatientHistoryTableData, PatientHistoryTableDataCPT, TableBodyData, TableBodyDataCPT } from "./PatientHistoryTable";
import { checkPermission, commonTableBody, decimalConverter } from "../../../utilities/commonUtilities";
import "./style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getFormWarings, getServiceLineData, udpateICD, updateDXpointerFlagUpdate, updateDiagnosisDataOnRemove, updateDignosisVariables, updateProcedureData } from "../claimManagementSlice/claimManagementSlice";
import { GetResponsibilityType, getClaimStatusListData, getClaimSubStatusData } from "../claimManagementSlice/claimSelectInputSlice";
import { getPatientDXPDetails, udpateSaveICD, updateDXpointerFlag, updateDiagnosisDataOnRemoveSave, updateDignosisSaveVariables, updateProcedureSaveData } from "../claimManagementSlice/saveManagementSlice";
import { permission_key_values_claim } from "../../../utilities/permissions";
import { Box, Divider, Tab } from "@mui/material";
import CommonButton from "../../commons/Buttons";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TAB1, TAB2 } from "../../../utilities/staticConfigs";
let diagnosisDataFromDb = {};
function ServiceLines(props) {
    const patientDeathDate = props.patientDeathDate
    const serviceInfoData = useSelector(state => state.claimData.serviceInfos);
    const serviceLineDetails = useSelector(state => state.claimData.serviceLineData);
    const claimDetails = useSelector(state => state.claimData.commonData);
    const { claimStatusList, claimSubStatusList } =  useSelector(state => state.claimSelectInputData);
    const DXPointerFlagUpdate = useSelector(state => state.claimData.DXPointerFlagUpdate);
    const { serviceLoaded, saveMode, serviceLineData, DXPointerFlagSave } = useSelector(state => state.claimSaveManagmentData);
    const responsibility = useSelector(state => state.claimSelectInputData.responsibilityType);
    const dispatch = useDispatch();
    // state variables
    const [serviceRowsLoaded, setServiceRowsLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState(TAB1);
    const page = 0;
    const page_size = 0;
    const practicePK = getStorage('practice');
    const [icdList, setIcdList] = useState([]);
    const [patientHistoryModal, setPatientHistoryModal] = useState(false);
    const [diagnosisVariable, setDiagnosisVariable] = useState({
        "icd": defaultIdForICD,
        "diagnosis1": "", "diagnosis2": "", "diagnosis3": "", "diagnosis4": "", "diagnosis5": "", "diagnosis6": "",
        "diagnosis7": "", "diagnosis8": "", "diagnosis9": "", "diagnosis10": "", "diagnosis11": "", "diagnosis12": "",
        "diagnosis1List": [], "diagnosis2List": [], "diagnosis3List": [], "diagnosis4List": [], "diagnosis5List": [], "diagnosis6List": [],
        "diagnosis7List": [], "diagnosis8List": [], "diagnosis9List": [], "diagnosis10List": [], "diagnosis11List": [], "diagnosis12List": []
    })
    const [diagnosisValidation, setDiagnosisValidation] = useState({
        "diagnosis1": "", "diagnosis2": "", "diagnosis3": "", "diagnosis4": "", "diagnosis5": "", "diagnosis6": "",
        "diagnosis7": "", "diagnosis8": "", "diagnosis9": "", "diagnosis10": "", "diagnosis11": "", "diagnosis12": ""
    })

    const [patientHistoryCPT, setPatientHistoryCPT] = useState([]);

    const [arrDataLoadedClaimPks, setArrDataLoadedClaimPks] = useState([]);
    const [serviceLocationPosList, setServiceLocationPosList] = useState([]);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }


    /**
     * function to get patients default saved dignosis and procedures and then apply to the claim,
     * this is called from useEffect when user switches to service line tab in a new claim 
     * which has no claim id generated yet but a patient is associated or selected.
     */
    useEffect(() => {

        if (saveMode === 'Save') {
            const data = serviceLineData;
            const createDiagnosisList = (num) => {
                const diagnosis = `diagnosis${num}`;
                const diagnosisValue = `${diagnosis}_value`;
                return {
                    [diagnosis]: data[diagnosis] ? data[diagnosis].toString() : '',
                    [`${diagnosis}List`]: data[diagnosis] ? [{ 'label': data[diagnosis], 'name': data[diagnosisValue] }] : []
                };
            }
            setDiagnosisVariable(() => {
                const diagnosisList = {};
                for (let i = 1; i <= 12; i++) {
                    Object.assign(diagnosisList, createDiagnosisList(i));
                }
                return Object.assign({
                    "icd": data.icd ? data.icd : 2,
                }, diagnosisList);
            });

            if (serviceLineData?.procedures?.length > 0) {
                props.setProcedureRows(serviceLineData?.procedures);
            }
        }
    }, [serviceLineData])



    useEffect(() => {
        if (props.patientPK && !props.claimPK && !serviceLoaded)
            dispatch(getPatientDXPDetails(props.patientPK))
    }, [props.patientPK]);

    useEffect(() => {
        let dgnValid = diagnosisValidation;
        let tmpName = '';
        let foundTopOrderValue = false;
        let isValidted = true;
        let isDuplicate = true;
        let codeList = [];
        for (let dgCnt = 12; dgCnt > 0; dgCnt--) {
            tmpName = 'diagnosis' + String(dgCnt);
            if (diagnosisVariable[tmpName]) {
                foundTopOrderValue = true;
                dgnValid[tmpName] = "";
                codeList.push(parseInt(diagnosisVariable[tmpName]));
            }
            else if (foundTopOrderValue && !diagnosisVariable[tmpName]) {
                dgnValid[tmpName] = " input-error";
                isValidted = false;
            }
            else if (!foundTopOrderValue) {
                dgnValid[tmpName] = "";
            }
        }
        codeList = findDuplicates(codeList);
        if (isValidted && codeList.length > 0) {
            isDuplicate = false;
            isValidted = false;
        }
        setDiagnosisValidation(dgnValid);
        props.setCheckForDuplicate(isDuplicate)
        props.setDiagnosisesValidated(isValidted);
    }, [JSON.stringify(diagnosisVariable)]);

    //checking for duplicate code
    function findDuplicates(arr) {
        return arr.filter((item, index) => arr.indexOf(item) !== index);
    }

    const [isDiagnosis, setIsDiagnosis] = useState(false)
    const setShowLoadingBar = useContext(LoadingContext);

    const onHandleICDChange = (e, name) => {
        let label = ''
        if (Array.isArray(e) && e.length > 0) {
            label = e[0].label;
        }
        setIsDiagnosis(true)
        setDiagnosisVariable({
            ...diagnosisVariable, [name]: label, [name + "List"]: e
        });
        if (saveMode !== 'Save') {
            dispatch(updateDignosisVariables({ name: name, valueList: e, pk: props.diagnosises_pk }))
            dispatch(updateDXpointerFlagUpdate());
        }
        else {
            dispatch(updateDignosisSaveVariables({ name: name, valueList: e }))
            dispatch(updateDXpointerFlag())
        }
    }

    const onHandleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (saveMode !== 'Save') {
            dispatch(udpateICD({ name: name, value: value, newArray: props.procedureRows, pk: props.diagnosises_pk }))
        }
        else {
            dispatch(udpateSaveICD({ name: name, value: value, newArray: props.procedureRows }))
        }
    };
    //serviceLine Procedure

    useEffect(() => {
        if (DXPointerFlagSave || DXPointerFlagUpdate) {
            setDXPointers();
        }
    }, [DXPointerFlagSave,DXPointerFlagUpdate]);
    // FUNCTION TO SORT THE 'ABC..JKL' VALUES OF DX POINTERS IN PROCEDURE, WHILE CHANGING THE DIAGNOSIS CODES - 16SEP21
    function setDXPointers(returnDXPs) {
        let dxpvalues = '';
        if (diagnosisVariable.diagnosis1 && diagnosisVariable.diagnosis1.trim().length) dxpvalues += 'A';
        if (diagnosisVariable.diagnosis2 && diagnosisVariable.diagnosis2.trim().length) dxpvalues += 'B';
        if (diagnosisVariable.diagnosis3 && diagnosisVariable.diagnosis3.trim().length) dxpvalues += 'C';
        if (diagnosisVariable.diagnosis4 && diagnosisVariable.diagnosis4.trim().length) dxpvalues += 'D';
        if (diagnosisVariable.diagnosis5 && diagnosisVariable.diagnosis5.trim().length) dxpvalues += 'E';
        if (diagnosisVariable.diagnosis6 && diagnosisVariable.diagnosis6.trim().length) dxpvalues += 'F';
        if (diagnosisVariable.diagnosis7 && diagnosisVariable.diagnosis7.trim().length) dxpvalues += 'G';
        if (diagnosisVariable.diagnosis8 && diagnosisVariable.diagnosis8.trim().length) dxpvalues += 'H';
        if (diagnosisVariable.diagnosis9 && diagnosisVariable.diagnosis9.trim().length) dxpvalues += 'I';
        if (diagnosisVariable.diagnosis10 && diagnosisVariable.diagnosis10.trim().length) dxpvalues += 'J';
        if (diagnosisVariable.diagnosis11 && diagnosisVariable.diagnosis11.trim().length) dxpvalues += 'K';
        if (diagnosisVariable.diagnosis12 && diagnosisVariable.diagnosis12.trim().length) dxpvalues += 'L';
        if (returnDXPs) {
            return dxpvalues;
        }
        else {
            props.setCommonDXPointers(dxpvalues, isDiagnosis);
        }
    }

    function onSearchICD10(query) {
        let type = 'dropdown'
        let result = service.GetICD10List(page_size, page, practicePK, type, query, props.claimPK);
        result.then((response) => {
            setIcdList(response.data)
        })

    }

    const onBlur = (e, name, searchType) => {
        let inputValue;
        if (searchType === "DIAGNOSISCODE") {
            inputValue = e?.target?.value?.toUpperCase() ?? e?.toUpperCase();
        } else {
            inputValue = e?.target?.value ?? e
        }

        let type = 'dropdown'
        let label = ''
        if (searchType == "DIAGNOSISCODE") {
            let result = service.GetICD10List(page_size, page, practicePK, type, inputValue, props.claimPK);
            result.then((response) => {
                let filterData = response.data.filter(item => item.name == inputValue)
                if (Array.isArray(filterData) && filterData.length == 1) {
                    label = filterData[0].label;
                } else {
                    label = '';
                }
                setIsDiagnosis(true)
                setDiagnosisVariable({
                    ...diagnosisVariable, [name]: label, [name + "List"]: filterData
                });
            })
        }
    }

    const updateResponsibility = () => {
        const priorityOrder = RESPONSIBILITY_TYPES.map((item) => item.name);
        for (const name of priorityOrder) {
            const responsibilityType = responsibility.find((item) => item?.name === name);
            if (responsibilityType) {
                return responsibilityType.id;
            }
        }
        return "";
    };

    const onAddProcedure = () => {
        let tmpProcedure = Object.entries(props.procedure) ? { ...props.procedure } : {};
        tmpProcedure.dos_from = "";
        tmpProcedure.dos_to = "";
        tmpProcedure.dx_pointers = setDXPointers(true);
        let responsibility_id = updateResponsibility();
        tmpProcedure.responsibility_type = responsibility_id;

        if (props.facilityPOS?.id) {
            tmpProcedure.pos_id = props.facilityPOS.id;
            tmpProcedure.pos_id_List = [{ id: props.facilityPOS.id, name: props.facilityPOS.name, label: props.facilityPOS.label }];
        } else {
            tmpProcedure.pos_id = '';
            tmpProcedure.pos_id_List = [];
        }
        props.setProcedureRows(() => [...props.procedureRows, tmpProcedure]);
        props.setAddNewRowClicked(true);
        props.setIsProcedureChangeFlag(true)
    };

    function onRemoveProcedure(id) {
        const newArr = [...props.procedureRows];
        newArr.splice(id, 1)
        props.setProcedureRows(newArr)
        props.setIsProcedureChangeFlag(true)
    }

    useEffect(() => {
        if (props.patientPK && responsibility.length == 0) {
            dispatch(GetResponsibilityType(props.patientPK))
        }
    }, [props.patientPK]);

    useEffect(() => {
        if (!claimStatusList.length) dispatch(getClaimStatusListData({ pageSize: 0, page: 0, practicePK: practicePK, patientPK: props?.patientPK, claimPK: props?.claimPK}));
        if (!claimSubStatusList.length) dispatch(getClaimSubStatusData({ pageSize: 0, page: 0, practicePK: practicePK }));
    }, [])


    function procedureHandleChange(e, rowId) {
        let name = "";
        let value = "";
        if (e.target) {
            name = e.target.name;
            if (name === "charges") {
                value = String(e.target.value.trim()).replace(/[^0-9.]/g, '').replace(/(\.\d{2})\d*([^0-9]|$)/, '$1').substring(0, 9);
            } else if (name === "drug_code") {
                value = String(e.target.value.trim()).replace(/[^\d]/g, '').substring(0, 11);
            }
             else if (name.endsWith("unit_value") || name === "anesthesia") {
                value = String(e.target.value.trim()).replace(/^0|[^\d]/g, '').substring(0, 9);
            } else if (name === "dx_pointers") {
                // dx pointers should accept only alphabets from A to L
                value = e.target.value.toUpperCase().replace(/[^A-L]/g, '');
            } else if (name === "claim_status") {
                value = e.target.value

                props.setProcedureRows((prev) => {
                    const newData = [...prev];
                    // Resetting the claim_sub_status to null whenever claim_status is updated.
                    newData[rowId] = { ...newData[rowId], "claim_sub_status": null };
                    return newData;
                });

            } else {
                value = e.target.value;
            }
            if (name === "responsibility_type") {
                if (value == 5 && (props.procedureRows[rowId].balance > 0 || props.procedureRows[rowId].balance === undefined)) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.paidErrorMessage'));
                    return
                }

                props.setProcedureRows((prev) => {
                    const newData = [...prev];
                    // Update the field specified by `name` with the new `value` and set claim_status and claim_sub_status to null.
                    newData[rowId] = { ...newData[rowId], [name]: value , "claim_status": null, "claim_sub_status": null};
                    return newData;
                });

            }
            if (name === "fee" || name === "fee_units") {
                let pRow = props.procedureRows[rowId];
                let feeValue = 0, feeUnitsValue = 0, chargeValue = 0;
                if (name === "fee") {
                    feeValue = value;
                    feeUnitsValue = pRow.fee_units;
                }
                else if (name === "fee_units") {
                    feeValue = pRow.fee;
                    feeUnitsValue = value;
                }
                chargeValue = (decimalConverter(feeValue, 2) * decimalConverter(feeUnitsValue, 2));
                chargeValue = isNaN(chargeValue) ? "0.00" : decimalConverter(chargeValue, 2);
                props.setProcedureRows((prev) => {
                    const newData = [...prev];
                    newData[rowId] = { ...newData[rowId], [name]: value, ["charges"]: chargeValue };
                    return newData;
                });
            }
            else {
                props.setProcedureRows((prev) => {
                    const newData = [...prev];
                    newData[rowId] = { ...newData[rowId], [name]: value };
                    return newData;
                });
            }
        }
        else if (!e.target) {
            name = e.name;
            value = e.value;
            props.setProcedureRows((prev) => {
                const newData = [...prev];
                newData[rowId] = { ...newData[rowId], [name]: value };
                return newData;
            });
        }

        props.setIsProcedureChangeFlag(true)
    }

    function posHandleChange(e, rowId) {
        props.setProcedureRows((prev) => {
            const newData = [...prev];
            if (e.length > 0) {
                newData[rowId] = { ...newData[rowId], 'pos_id': e[0].id };
                newData[rowId] = { ...newData[rowId], 'pos_id_List': e };
            } else {
                newData[rowId] = { ...newData[rowId], 'pos_id': '' };
                newData[rowId] = { ...newData[rowId], 'pos_id_List': [] };
            }
            return newData;
        });
        props.setIsProcedureChangeFlag(true)
    }

    function tosHandleChange(e, rowId) {
        props.setProcedureRows((prev) => {
            const newData = [...prev];
            if (e.length > 0) {
                newData[rowId] = { ...newData[rowId], 'tos_id': e[0].id };
                newData[rowId] = { ...newData[rowId], 'tos_id_List': e };
            } else {
                newData[rowId] = { ...newData[rowId], 'tos_id': '' };
                newData[rowId] = { ...newData[rowId], 'tos_id_List': [] };
            }
            return newData;
        });
        props.setIsProcedureChangeFlag(true)
    }

    const posOnPaste = (e, rowId, procedureList) => {
        if (procedureList?.length == 0) {
            let result = service.SearchPosCodes(e.target.value);
            result.then((response) => {
                props.setProcedureRows((prev) => {
                    const newData = [...prev];
                    if (response.data.results.length == 1) {
                        let data = [{
                            "id": response.data.results[0].id,
                            "label": response.data.results[0].drop_down_name,
                            "name": response.data.results[0].drop_down_name
                        }]
                        newData[rowId] = { ...newData[rowId], 'pos_id': response.data.results[0].id };
                        newData[rowId] = { ...newData[rowId], 'pos_id_List': data };
                    } else {
                        newData[rowId] = { ...newData[rowId], 'pos_id': '' };
                        newData[rowId] = { ...newData[rowId], 'pos_id_List': [] };
                    }
                    return newData;
                });
            })
            props.setIsProcedureChangeFlag(true)
        }
    }

    //Fetch POS from facility if there is no POS against the selected CPT
    useEffect(() => {
        const serviceData = props?.serviceLocationId;
        let firstIndex = serviceData?.indexOf("||");
        let serviceId = serviceData?.substring(0, firstIndex).trim()

        const result = service.GetFacilityPos(serviceId)
        result.then((response) => {

            if (response?.data?.pos?.length > 0) {
                setServiceLocationPosList(response?.data?.pos)
            } else {
                setServiceLocationPosList([])
            }
        })
    }, [props.serviceLocationId])

    const procedureCPTHandleChange = (e, name, rowId) => {
        props.setProcedureRows((prev) => {
            const newData = [...prev];
            if (e.length > 0) {
                newData[rowId] = { ...newData[rowId], [name]: e[0].id };
                newData[rowId] = { ...newData[rowId], ['proced_description']: e[0].description };
                if (e[0].pos) {
                    let posList = [{
                        id: e[0]?.pos?.id,
                        label: e[0]?.pos?.label,
                        name: e[0]?.pos?.name,
                    }]
                    newData[rowId] = { ...newData[rowId], pos_id: e[0].pos.id, pos_id_List: posList };
                } else {
                    if (serviceLocationPosList.length > 0) {
                        let pos_List = [{
                            id: serviceLocationPosList[0]?.id,
                            label: serviceLocationPosList[0]?.label,
                            name: serviceLocationPosList[0]?.name,
                        }]

                        newData[rowId] = { ...newData[rowId], pos_id: serviceLocationPosList[0].id, pos_id_List: pos_List };
                    }
                }
                if (e[0].units) {
                    newData[rowId] = { ...newData[rowId], fee_units: e[0].units }
                }

                if (e[0].tos) {
                    let tosList = [{
                        id: e[0]?.tos?.id,
                        label: e[0]?.tos?.name,
                        name: e[0]?.tos?.name,
                    }]
                    newData[rowId] = { ...newData[rowId], tos_id: e[0].tos.id, tos_id_List: tosList };
                }
                if (e[0].fee) {
                    newData[rowId] = { ...newData[rowId], fee: decimalConverter(e[0].fee) }
                }

                if (e[0].units && e[0].fee) {
                    newData[rowId] = { ...newData[rowId], charges: decimalConverter(e[0].fee) * parseInt(e[0].units) }
                }
                if (e[0].m1_id && e[0].m1_value.length > 0) {
                    newData[rowId] = { ...newData[rowId], "m1_id": e[0].m1_id, "m1List": e[0].m1_value };
                }
                else {
                    newData[rowId] = { ...newData[rowId], "m1_id": "", "m1List": [] }; 
                }
                if (e[0].m2_id && e[0].m2_value.length > 0) {
                    newData[rowId] = { ...newData[rowId], "m2_id": e[0].m2_id, "m2List": e[0].m2_value };
                }
                else {
                    newData[rowId] = { ...newData[rowId], "m2_id": "", "m2List": [] };
                }
                if (e[0].m3_id && e[0].m3_value.length > 0) {
                    newData[rowId] = { ...newData[rowId], "m3_id": e[0].m3_id, "m3List": e[0].m3_value };
                }
                else {
                    newData[rowId] = { ...newData[rowId], "m3_id": "", "m3List": [] };
                }
                if (e[0].m4_id && e[0].m4_value.length > 0) {
                    newData[rowId] = { ...newData[rowId], "m4_id": e[0].m4_id, "m4List": e[0].m4_value };
                }
                else {
                    newData[rowId] = { ...newData[rowId], "m4_id": "", "m4List": [] };
                }
            } else {
                newData[rowId] = { ...newData[rowId], [name]: '', pos_id: '', tos_id: '', charges: '0', fee: '0' };
                newData[rowId] = { ...newData[rowId], ['proced_description']: "", "pos_id_List": [], tos_id_List: [] };
            }
            newData[rowId] = { ...newData[rowId], [name + 'List']: e };
            const action = saveMode !== 'Save' ? updateProcedureData : updateProcedureSaveData;
            dispatch(action({ procedureArray: newData, pk: props.diagnosises_pk }));
            return newData;
        });
    };

    const procedureModifierHandleChange = (e, name, rowId) => {
        props.setProcedureRows((prev) => {
            const newData = [...prev];
            if (e.length > 0) {
                newData[rowId] = { ...newData[rowId], [name]: e[0].id };
            } else {
                newData[rowId] = { ...newData[rowId], [name]: e };
            }
            newData[rowId] = { ...newData[rowId], [name.split('_')[0] + 'List']: e };
            return newData;
        });

        props.setIsProcedureChangeFlag(true)
    };

    const procedureDateHandleChange = (value, name, rowId, data) => {
        props.setProcedureRows((prev) => {
            const newData = [...prev];
            if (value) {
                try {
                    if (name === "dos_from") {
                        newData[rowId] = { ...newData[rowId], [name]: format(value, 'yyyy-MM-dd') };
                        newData[rowId] = { ...newData[rowId], ["dos_to"]: format(value, 'yyyy-MM-dd') };
                    } else {
                        newData[rowId] = { ...newData[rowId], [name]: format(value, 'yyyy-MM-dd') };
                    }
                } catch (e) {
                    newData[rowId] = { ...newData[rowId], [name]: "" };
                }
            } else {
                newData[rowId] = { ...newData[rowId], [name]: value };
            }

            if (data) {
                newData[rowId] = { ...newData[rowId], ["fee_units"]: data?.units };
                newData[rowId] = { ...newData[rowId], ["fee"]: decimalConverter(data?.fee) };
                newData[rowId] = { ...newData[rowId], ["charges"]: decimalConverter(data?.fee) * parseInt(data?.units) };
            }

            return newData;
        });
        props.setIsProcedureChangeFlag(true)
    };


    /**
     * Function to get the saved data of existing claim and set set variable states.
     * @param(claimPK)
     */
    const getNewClaimData = (claimPK) => {
        let id = claimPK;
        if (serviceInfoData?.length === 0) {
            dispatch(getServiceLineData({ diagnosisesPK: props.diagnosises_pk, claimPK: id }))
        }
        else {
            const exist = serviceInfoData?.findIndex(item => item.diganosisPK === props.diagnosises_pk);
            if (exist >= 0) {
                //if data exist
            }
            else {
                dispatch(getServiceLineData({ diagnosisesPK: props.diagnosises_pk, claimPK: id }))
            }
        }
    };

    //if saved calling api to remove formwarnings
    useEffect(() => {
        if (props.isSaved) {
            if (claimDetails.id) {
                dispatch(getFormWarings(claimDetails.id))
                let id = props.diagnosises_pk
                if (!props.isClosed) {
                    dispatch(getServiceLineData({ diagnosisesPK: props.diagnosises_pk, claimPK: id }))
                }
            }
            props.setIsSaved(false)
        }
    }, [props.isSaved])

    useEffect(() => {
        let procedureData = {};
        if (serviceLineDetails && saveMode !== 'Save') {
            setShowLoadingBar(true);
            const data = serviceLineDetails;
            procedureData = serviceLineDetails.procedures ? serviceLineDetails.procedures : [];
            const createDiagnosisList = (num) => {
                const diagnosis = `diagnosis${num}`;
                const diagnosisValue = `${diagnosis}_value`;
                return {
                    [diagnosis]: data[diagnosis] ? serviceLineDetails[diagnosis].toString() : '',
                    [`${diagnosis}List`]: data[diagnosis] ? [{ 'label': serviceLineDetails[diagnosis], 'name': serviceLineDetails[diagnosisValue] }] : []
                };
            }
            setDiagnosisVariable(() => {
                const diagnosisList = {};
                for (let i = 1; i <= 12; i++) {
                    Object.assign(diagnosisList, createDiagnosisList(i));
                }
                return Object.assign({
                    "icd": data.icd ? data.icd : 2,
                }, diagnosisList);
            });
            props.setRefreshProcedure(false);
            // CHECK WHETHER ANY NEW PROCEDERE ADDED WITH "PROCEDURE ADD" BUTTON, AND IF FOUND, APPEND THEM TO THE PROCEDURE DATA FROM THE SERVER 
            if (props.addNewRowClicked && procedureData.length > 0) {
                props.setProcedureRows([...procedureData]);
            }
            else if (procedureData.length > 0) {
                props.setProcedureRows([...procedureData]);
            }
            else
                props.setProcedureRows(new Array(1).fill(JSON.parse(JSON.stringify(props.procedure))))
            setShowLoadingBar(false);
        }
    }, [serviceLineDetails])

    const getPatientHistoryData = () => {
        setShowLoadingBar(true);
        const result = service.GetPatientHistory(props.patientPK);
        result.then((response) => {
            if (response.data.icd_codes.length === 0) {
                PatientHistoryTableData.tableBodyData = TableBodyData;
                PatientHistoryTableDataCPT.tableBodyData = TableBodyDataCPT
            }
            else {
                const rowArray = commonTableBody(response.data.icd_codes, PatientHistoryTableData.tableBodyData[0])
                const rowArrayCpt = commonTableBody(response.data.cpt_codes, PatientHistoryTableDataCPT.tableBodyData[0]);
                PatientHistoryTableData.tableBodyData = rowArray;
                PatientHistoryTableDataCPT.tableBodyData = rowArrayCpt;
                setPatientHistoryCPT(response.data.cpt_codes);
            }
            setShowLoadingBar(false);
            setPatientHistoryModal(true)
        })
    };

    useEffect(() => {
        if (props.diagnosises_pk) {
            let diagnosises_pk = props.diagnosises_pk;
            if (!arrDataLoadedClaimPks.includes(diagnosises_pk)) {
                setArrDataLoadedClaimPks([...arrDataLoadedClaimPks, diagnosises_pk])
                getNewClaimData(diagnosises_pk);
            }
        }
    }, [props.diagnosises_pk])

    useEffect(() => {
        let filteredDiagnosisesDataVariables = {};
        let data = {};
        const { id,
            icd, diagnosis1, diagnosis2, diagnosis3, diagnosis4,
            diagnosis5, diagnosis6, diagnosis7, diagnosis8, diagnosis9,
            diagnosis10, diagnosis11, diagnosis12 } = diagnosisVariable;
        const diagnosisesDataVariables = {
            id,
            icd, diagnosis1, diagnosis2, diagnosis3, diagnosis4,
            diagnosis5, diagnosis6, diagnosis7, diagnosis8, diagnosis9,
            diagnosis10, diagnosis11, diagnosis12
        }
        for (const [key, value] of Object.entries(diagnosisesDataVariables)) {
            if (value) {
                filteredDiagnosisesDataVariables[key] = value;
            }
        }
        if (!props.claimPK) {
            props.setDiagnosises(filteredDiagnosisesDataVariables);
        }
        else if (props.claimPK) {
            let filteredDataFromDB = {};
            Object.keys(diagnosisDataFromDb).forEach((item) => {
                filteredDataFromDB[item] = diagnosisVariable[item];
            });
            data = {
                ...filteredDiagnosisesDataVariables, "pk": props.diagnosises_pk, ...filteredDataFromDB
            }
            props.setDiagnosisDbData(data);
        }
    }, [diagnosisVariable]);

    // shortcut (shift & "+") or ("plusKey")  key for adding a procedure
    const addProcureButton = document.getElementById('addProcedure');
    useKeyboardShortcut(
        ["+"],
        () => addProcureButton.click(), {
        overrideSystem: false,
        ignoreInputFields: false,
        repeatOnHold: false,
    },
        ["Shift", "+"],
        () => addProcureButton.click(),
        {
            overrideSystem: false,
            ignoreInputFields: false,
            repeatOnHold: false
        }
       
    );


    // To add diagnosis from patient history to claim
    function addDiagnosisFromPatientHistory(e, id, item) {
        // getting the available slot in the diagnosisVariable to set the diagnosis requested from PT history
        let availableSlotInICDList = null;
        for (let i = 1; i <= 12; i++) {
            const key = `diagnosis${i}`;
            if (diagnosisVariable[key] === "") {
                availableSlotInICDList = key;
                break;
            }
        }
        if (availableSlotInICDList) {
            // setting diagnosis recieved from patient history to claim diagnosisVariable
            let icdToAdd = { label: item[0].id, name: item[0].value }
            setDiagnosisVariable(prevState => {
                return {
                    ...prevState,
                    [availableSlotInICDList]: icdToAdd.label.toString(),
                    [availableSlotInICDList + 'List']: [icdToAdd]
                }
            });
            if(saveMode!=='Save'){
                dispatch(updateDignosisVariables({ name: availableSlotInICDList, valueList: [{ label: item[0].id, name: item[0].value }], pk: props.diagnosises_pk }))
                dispatch(updateProcedureData({ procedureArray: props.procedureRows, pk: props.diagnosises_pk }))
            }
            else{
                dispatch(updateDignosisSaveVariables({ name: availableSlotInICDList, valueList: [{ label: item[0].id, name: item[0].value }] }))

            }
            // setting the ICD/Dx pointer
         
            setDXPointersOnAdd();
        } else {
            showNotifyWindow("show", "error", i18n.t('errorMessages.max_ICD_On_Claim'))
        }
    }

    function setDXPointersOnAdd() {
        // const diagnosisVariables = ['diagnosis1', 'diagnosis2', 'diagnosis3', 'diagnosis4', 'diagnosis5', 'diagnosis6', 'diagnosis7', 'diagnosis8', 'diagnosis9', 'diagnosis10', 'diagnosis11', 'diagnosis12'];
        // let dxpvalues = '';
        // for (let i = 0; i < diagnosisVariables.length; i++) {
        //     if (diagnosisVariable[diagnosisVariables[i]] && diagnosisVariable[diagnosisVariables[i]].trim().length) {
        //         dxpvalues += String.fromCharCode(65 + i);
        //     }
        // }
        setIsDiagnosis(true) //isDiagnosis is validation i.e setting to true
        if (saveMode !== 'Save') {
            dispatch(updateDXpointerFlagUpdate());
        }
        else {
            dispatch(updateDXpointerFlag())
        }
        // return props.setCommonDXPointers(dxpvalues, isDiagnosis);
    }



    // To remove ICD/Dx from patient history to claim
    function removeDiagnosisFromPatientHistory(item) {
        let icdListForUpdate = { ...diagnosisVariable }
        // find the key of the item need to remove
        function findKeyByValue(obj, id) {
            for (let key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] == id && key !== 'icd') {
                    return key;
                }
            }
            return undefined;
        }
        // remove the item from temp variable
        let removedKey = findKeyByValue(icdListForUpdate, item[0].id);
        icdListForUpdate = { ...icdListForUpdate, [removedKey]: '', [removedKey + 'List']: [] }

        // check if any key has value that comes after the removed icd/dx index
        function findKeysAfterTheRemovedItem(obj, removedKey) {
            let keys = Object.keys(obj);
            let index = keys.indexOf(removedKey);
            let result = [];
            for (let i = index; i < keys.length; i++) {
                if (obj[keys[i]] !== null && obj[keys[i]] !== '' && (Array.isArray(obj[keys[i]]) ? obj[keys[i]].length : true)) {
                    result.push(keys[i]);
                }
            }
            return result;
        }
        const icdItemComesAfterRemovedSlot = findKeysAfterTheRemovedItem(icdListForUpdate, removedKey)

        // shift all the icds to the unoccupied slot in the list
        if (icdItemComesAfterRemovedSlot.length) {
            icdItemComesAfterRemovedSlot.forEach((icdToSwitchSlot) => {
                if (icdToSwitchSlot.startsWith('diagnosis') && icdToSwitchSlot.length <= 11) {
                    let availableSlotInICDList1 = null;
                    for (let i = 1; i <= 12; i++) {
                        const key = `diagnosis${i}`;
                        if (icdListForUpdate[key] === "") {
                            availableSlotInICDList1 = key;
                            break;
                        }
                    }
                    let tempStoreICDToShift = icdListForUpdate[icdToSwitchSlot]
                    let tempStoreICDListToShift = icdListForUpdate[`${icdToSwitchSlot}List`];
                    //shift the values
                    icdListForUpdate = {
                        ...icdListForUpdate,
                        [availableSlotInICDList1]: tempStoreICDToShift.toString(),
                        [availableSlotInICDList1 + 'List']: tempStoreICDListToShift
                    }

                    // remove the shifted value
                    icdListForUpdate = {
                        ...icdListForUpdate,
                        [icdToSwitchSlot]: '',
                        [`${icdToSwitchSlot}List`]: []
                    }
                }
            })
            setDiagnosisVariable({ ...icdListForUpdate })
            setDXPointersOnRemove();
            if (saveMode !== 'Save') {
                dispatch(updateDiagnosisDataOnRemove({ name: removedKey, value: "", pk: props.diagnosises_pk }))
            }
            else {
                dispatch(updateDiagnosisDataOnRemoveSave({ name: removedKey, value: "" }))
            }
        } else { // if there are no values after removing the requested dignosis
            setDiagnosisVariable({ ...icdListForUpdate })
            // setting the ICD/Dx pointer
            setDXPointersOnRemove();
            if (saveMode !== 'Save') {
                dispatch(updateDignosisVariables({ name: removedKey, valueList: [], pk: props.diagnosises_pk }))
            }
            else {
                dispatch(updateDignosisSaveVariables({ name: removedKey, valueList: [] })) 
            }
        }
        // function to set the DXPointers
        function setDXPointersOnRemove() {
            // const diagnosisVariables = ['diagnosis1', 'diagnosis2', 'diagnosis3', 'diagnosis4', 'diagnosis5', 'diagnosis6', 'diagnosis7', 'diagnosis8', 'diagnosis9', 'diagnosis10', 'diagnosis11', 'diagnosis12'];
            // let dxpvalues = '';
            // for (let i = 0; i < diagnosisVariables.length; i++) {
            //     if (diagnosisVariable[diagnosisVariables[i]] && diagnosisVariable[diagnosisVariables[i]].trim().length) {
            //         dxpvalues += String.fromCharCode(65 + i);
            //     }
            // }
            setIsDiagnosis(true) //isDiagnosis validation i.e setting to true
            if (saveMode !== 'Save') {
                dispatch(updateDXpointerFlagUpdate());
            }
            else {
                dispatch(updateDXpointerFlag())
            }
            // return props.setCommonDXPointers(dxpvalues, isDiagnosis);
        }
    }

    const onTabChange = (e, tab) => {
        setActiveTab(tab)
    }

    //adding cpt from patient history
    function addCPTFromPatientHistory(e, id, item) {
        const currentProcedureRows = [...props.procedureRows];
        let tmpProcedure = { ...props.procedure };
        tmpProcedure.dos_from = "";
        tmpProcedure.dos_to = "";
        tmpProcedure.dx_pointers = setDXPointers(true);
        let responsibility_id = updateResponsibility();
        tmpProcedure.responsibility_type = responsibility_id;
        const selectedCPT = patientHistoryCPT.filter(ele => ele.id == item[0].id);
        if (selectedCPT.length > 0) {
            if (selectedCPT[0].id) {
                tmpProcedure.cpt_hcpcs = selectedCPT[0]?.id;
                tmpProcedure.cpt_hcpcsList = [{ id: selectedCPT[0]?.id, name: selectedCPT[0]?.name }];
            } else {
                tmpProcedure.cpt_hcpcs = "";
                tmpProcedure.cpt_hcpcsList = [];
            }

            if (selectedCPT[0].pos) {
                tmpProcedure.pos_id = selectedCPT[0]?.pos?.id ? selectedCPT[0]?.pos?.id : "";
                tmpProcedure.pos_id_List = selectedCPT[0]?.pos?.id ? [selectedCPT[0]?.pos] : [];
            } else {
                tmpProcedure.pos_id = "";
                tmpProcedure.pos_id_List = [];
            }

            tmpProcedure.fee_units = selectedCPT[0]?.unit ? selectedCPT[0]?.unit : 0;
            tmpProcedure.fee = selectedCPT[0]?.fee ? decimalConverter(selectedCPT[0]?.fee, 2) : "0.00";
            tmpProcedure.charges =
                decimalConverter(selectedCPT[0]?.fee ? selectedCPT[0]?.fee : 0, 2) *
                parseInt(selectedCPT[0]?.unit ? selectedCPT[0]?.unit : 0);
        }

        // Add the new procedure to the cloned state
        currentProcedureRows.push(tmpProcedure);
        if (saveMode !== 'Save') {
            dispatch(updateProcedureData({ procedureArray: currentProcedureRows, pk: props.diagnosises_pk }))
        }
        else {
            dispatch(updateProcedureSaveData({ procedureArray: currentProcedureRows}))
        }
        // Update the state with the new array
        props.setProcedureRows(currentProcedureRows);
        props.setIsProcedureChangeFlag(true)
    }


    return (
        <div className="col-md-12 margin-top20">
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <Form>
                <div className="row pb-2">
                    <div className="col-2 padding-left20">
                        <SelectInput data={DataForICD} name="icd" id="icd-select" value={diagnosisVariable.icd}
                            onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.serviceLine.icd")} />
                    </div>
                    <div className="col-2 padding-top25 pl-0">
                        {props.patientPK &&
                            <CommonButton onClick={getPatientHistoryData}
                                variant="outlined"
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.patientHistory")}
                            />
                        }

                    </div>
                </div>
                <div className="row padding-left17">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.serviceLine.diagnosisCodes")}</label>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row padding-left7 margin-bottom-12">
                    <div className="col-2">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis1 ? diagnosisValidation.diagnosis1 : ''}
                                labelKey="name"
                                name="diagnosis1"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis1")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis1', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.a")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis1List}
                                id={"diagnosis1"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.a")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis2 ? diagnosisValidation.diagnosis2 : ''}
                                labelKey="name"
                                name="diagnosis2"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis2")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis2', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.b")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis2List}
                                id={"diagnosis2"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.b")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                claimPK={props.claimPK}
                                class_name={diagnosisValidation.diagnosis3 ? diagnosisValidation.diagnosis3 : ''}
                                labelKey="name"
                                name="diagnosis3"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis3")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis3', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.c")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis3List}
                                id={"diagnosis3"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.c")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis4 ? diagnosisValidation.diagnosis4 : ''}
                                labelKey="name"
                                name="diagnosis4"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis4")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis4', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.d")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis4List}
                                id={"diagnosis4"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.d")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis5 ? diagnosisValidation.diagnosis5 : ''}
                                labelKey="name"
                                name="diagnosis5"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis5")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis5', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.e")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis5List}
                                id={"diagnosis5"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.e")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis6 ? diagnosisValidation.diagnosis6 : ''}
                                labelKey="name"
                                name="diagnosis6"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis6")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis6', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.f")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis6List}
                                id={"diagnosis6"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.f")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pb-2 padding-left7 margin-bottom-12">
                    <div className="col-2">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis7 ? diagnosisValidation.diagnosis7 : ''}
                                labelKey="name"
                                name="diagnosis7"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis7")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis7', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.g")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis7List}
                                id={"diagnosis7"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.g")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis8 ? diagnosisValidation.diagnosis8 : ''}
                                labelKey="name"
                                name="diagnosis8"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis8")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis8', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.h")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis8List}
                                id={"diagnosis8"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.h")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis9 ? diagnosisValidation.diagnosis9 : ''}
                                labelKey="name"
                                name="diagnosis9"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis9")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis9', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.i")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis9List}
                                id={"diagnosis9"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.i")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis10 ? diagnosisValidation.diagnosis10 : ''}
                                labelKey="name"
                                name="diagnosis10"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis10")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis10', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.j")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis10List}
                                id={"diagnosis10"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.j")}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={diagnosisValidation.diagnosis11 ? diagnosisValidation.diagnosis11 : ''}
                                labelKey="name"
                                name="diagnosis11"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis11")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis11', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.k")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis11List}
                                id={"diagnosis11"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.k")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                labelKey="name"
                                name="diagnosis12"
                                minLength={1}
                                onValueChange={(e) => onHandleICDChange(e, "diagnosis12")}
                                options={icdList}
                                functionInvoke={true}
                                onBlur={(e) => onBlur(e, 'diagnosis12', 'DIAGNOSISCODE')}
                                // placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.l")}
                                onSearch={onSearchICD10}
                                selected={diagnosisVariable.diagnosis12List}
                                id={"diagnosis12"}
                                label={i18n.t("searchClaims.professionalClaims.serviceLine.l")}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                </div>


                <div className="row padding-left17">
                    <div className="div-inline">
                        <label className="margin-top-3px padding-left2">{i18n.t("searchClaims.professionalClaims.serviceLine.procedures")}</label>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <Form>
                    <div>

                        {/* the procedure rows are rendered using useMemo hook to cache the value so it will not be re-rendered unnecessaryly.
                            so it only re-renders if the props.procedureRows or props.modifiersValidated values change which is passed as dependence to useMemo.  */}
                        {useMemo(() =>
                            props.procedureRows.map((item, index) => {
                                if (props.procedureRows.length === (index + 1)) setServiceRowsLoaded(true);
                                return (
                                    <div key={index}>
                                        <Procedures
                                            posHandleChange={posHandleChange}
                                            tosHandleChange={tosHandleChange}
                                            index={index}
                                            procedure={item}
                                            onProcedureHandleChange={procedureHandleChange}
                                            patient={props.patientPK}
                                            pKs={props.pks}
                                            claimPK={props.claimPK}
                                            onProcedureDateHandleChange={procedureDateHandleChange}
                                            onProcedureCPTHandleChange={procedureCPTHandleChange}
                                            onProcedureModifierHandleChange={procedureModifierHandleChange}
                                            // newClaimDateOfService={props.dateOfService}
                                            onBlur={posOnPaste}
                                            removeProcedure={onRemoveProcedure}
                                            setModifiersValidated={props.setModifiersValidated}
                                            modifiersValidated={props.modifiersValidated}
                                            lastRow={props.procedureRows.length === (index + 1) ? true : false}
                                            patient_dob={props.patient_dob}
                                            serviceLocationId={props.serviceLocationId}
                                            setProcedureRows={props.setProcedureRows}
                                            serviceLineData={serviceLineData}
                                            addNewRowClicked={props.addNewRowClicked}
                                            diagnosisVariable={diagnosisVariable}
                                            setShowLoadingBar={setShowLoadingBar}
                                            patientDeathDate={(item.patient_death_date && item.patient_death_date.length > 0) ? item.patient_death_date : patientDeathDate}
                                        />
                                    </div>
                                );
                            })
                            , [JSON.stringify(props.procedureRows), props.modifiersValidated])
                        }
                    </div>
                    {props.claimEditable && props.procedureRows.length && serviceRowsLoaded && checkPermission(permission_key_values_claim.search_claims_sub_module_modify, permission_key_values_claim.claims_search_claims_add) &&
                        <div className="justify-right" style={{ position: 'relative', top: '10px' }} >
                            <CommonButton onClick={onAddProcedure} variant="contained"
                                icon="add"
                                label={i18n.t("searchClaims.addProcedure")}
                            />
                        </div>
                    }

                    {checkPermission(permission_key_values_claim.search_claims_sub_module_modify, permission_key_values_claim.claims_search_claims_add) &&
                        props.claimEditable && props.procedureRows.length && <div className="row alignRight margin-right0" style={{ marginTop: 30, marginBottom: 15 }}>
                            <div className="justify-right">
                                <button type="button" className="btn btn-primary-blue mr-2 btn-common-height35 width-75"
                                    onClick={props.claimInactive && !props.claimDbActive ? (e) =>
                                        props.onInactiveClaim(e) : (e) => props.onSaveFormData(e)}
                                >
                                    {i18n.t("buttons.save")}
                                </button>
                                <button type="button" className="btn btn-primary-blue btn-common-height35 width-75"
                                    onClick={props.claimInactive && !props.claimDbActive ? (e) =>
                                        props.onInactiveClaim(e) : (e) => props.onSaveFormData(e, true)}
                                >
                                    {i18n.t("buttons.saveClose")}
                                </button>
                            </div>
                        </div>
                    }
                </Form>
            </Form>
            <CustomizedDialogs type="patienthistory" header={i18n.t("searchClaims.professionalClaims.serviceLine.patientHistory")} showModal={patientHistoryModal} setShowModalWindow={setPatientHistoryModal}>
                <TabContext value={activeTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={onTabChange} aria-label="lab API tabs example" style={{ borderBottom: '1px solid #E0E0E0', marginTop: 20 }}>
                            <Tab label="ICD Patient History" value={TAB1} className='tab' />
                            <Tab label="CPT Patient History" value={TAB2} className='tab' />
                        </TabList>
                    </Box>
                    <TabPanel value={TAB1} className='searchBoxTab'>
                        <div style={{ marginTop: 20 }}>
                            <Table
                                tableObject={PatientHistoryTableData}
                                addDiagnosisFromPatientHistory={addDiagnosisFromPatientHistory}
                                removeDiagnosisFromPatientHistory={removeDiagnosisFromPatientHistory}
                                diagnosisVariable={diagnosisVariable}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={TAB2} className='searchBoxTab'>
                        <div style={{ marginTop: 20 }}>
                            <Table
                                tableObject={PatientHistoryTableDataCPT}
                                procedureRows={props.procedureRows}
                                addCPTFromPatientHistory={addCPTFromPatientHistory}
                                cptHistoryEnable={true}
                            />
                        </div>
                    </TabPanel>
                </TabContext>
            </CustomizedDialogs>
        </div>
    );
}

export default ServiceLines;